import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from '../Dashboard';
import PersonalDetails from '../PersonalDetails';
import Profile from '../Profile';
import Email from '../Email';
import PhoneNumber from '../PhoneNumber';
import Bookings from '../Bookings';
import Billing from '../Billing';
import Security from '../Security';
import Help from '../Help';
import { AccountContentContainer } from './style';
import { ProfileContainer } from '../Profile/style';

const AccountContent: FC = () => {

  return (
    <AccountContentContainer>
      <Switch>
        <Route path={'/account/dashboard'} render={() => <Dashboard />} />
        <Route path={'/account/personalDetails'} render={() => <PersonalDetails />} />
        <Route path={'/account/profile'} render={() => <Profile />} />
        <Route path={'/account/email'} render={() => <Email />} />
        <Route path={'/account/phoneNumber'} render={() => <PhoneNumber />} />
        <Route path={'/account/bookings'} render={() => <Bookings />} />
        <Route path={'/account/billing'} render={() => <Billing />} />
        <Route path={'/account/security'} render={() => <Security />} />
        <Route path={'/account/help'} render={() => <Help />} />
        <Redirect to={'/account/dashboard'} />
      </Switch>
    </AccountContentContainer>
  );
};

export default AccountContent;
