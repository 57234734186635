import { FC } from 'react'
import { CancellationPolicyScreenContainer } from './style';

const CancellationPolicySection: FC = () => {

  return (
    <CancellationPolicyScreenContainer>
      <div className="background"></div>
      <div className="innerContainer">
        <div className="content">
          <h1 className="title">Rook Hotel Cancellation Policy</h1>
          <p>At Rook Hotel, we understand that plans can change. To provide flexibility, we offer two payment plans with different cancellation policies: </p>
          <h3 className="secondarySubtitle">1. Prepayment Plan (10% Discount)</h3>
          <ul>
            <li><span className="highLight">Prepayment Requirement: </span> Guests who opt for this plan will receive a 10% discount on their room rate by prepaying for one night of their stay. For example, if you are 
              booking for 4 nights at $100 per night, your discounted rate will be $90 per night, and you will prepay $90 as a non-refundable deposit.</li>
            <li><span className="highLight">Cancellation Policy: </span> You can cancel your booking at any time up to 24 hours before your scheduled check-in. If you cancel within this period, you will not incur any 
              further charges. However, the prepaid deposit of $90 is non-refundable.</li>
            <li><span className="highLight">Late Cancellation Penalty: </span> If you fail to cancel at least 24 hours before check-in, a penalty of one room night ($90) will be charged to your credit card on file.</li>
            <li><span className="highLight">LNo Show Penalty: </span> If you do not arrive within 24 hours of your check-in time and fail to inform us, your booking will be cancelled, and a no show penalty of $100 will 
              be applied to your credit card.</li>
          </ul>
          <h3 className="secondarySubtitle">2. Standard Plan (No Prepayment)</h3>
          <ul>
            <li><span className="highLight">No Prepayment Required: </span> Guests who choose this plan do not need to prepay any deposit and will pay the regular full room rate of $100 per night.</li>
            <li><span className="highLight">Cancellation Policy: </span> You can cancel your booking at any time up to 24 hours before your scheduled check-in without any cancellation fees.</li>
            <li><span className="highLight">No Show Penalty: </span> If you do not arrive within 24 hours of your check-in time and fail to inform us, your booking will be cancelled, and a no show penalty of $100 will 
              be applied to your credit card.</li>
          </ul>
          <p className='ending'>
            <div>We hope these options provide the flexibility you need. Should you have any questions or need further assistance, please do not hesitate to contact our 
              reservations team.</div>
            <div>Thank you for choosing Rook Hotel. We look forward to welcoming you.</div>
          </p>
        </div>
      </div>
    </CancellationPolicyScreenContainer>
  )
}

export default CancellationPolicySection;