import styled from 'styled-components'
import { colors } from 'constants/colors';

export const CardContainer = styled.div<{ selected?: boolean, padding?: string, pointer?: boolean }>`
  width: 100%;
  padding: ${({ padding }) => padding? padding:'10px 15px'};
  box-shadow: ${({ selected }) => selected? `0 0 5px 0 ${colors.optionBackground}`: `0 0 5px 0 ${colors.borderGrey2}`};
  margin-bottom: 20px;

  ${({ selected }) => selected? `border: 2px solid ${colors.optionBackground};` : null}
  ${({ pointer }) => pointer? 'cursor: pointer' : null}
`;