/* eslint-disable @typescript-eslint/ban-types */
import moment from 'moment';
import { SelectedRooms, Coupon } from 'types/commonTypes';
import { debounce } from 'throttle-debounce';
import { ADVANCED } from 'constants/ratePlanId';
import {
  DEFAULT_CHECK_IN_HOUR,
  DEFAULT_CHECK_IN_MINUTE,
  DEFAULT_CHECK_OUT_HOUR,
  DEFAULT_CHECK_OUT_MINUTE,
  DEFAULT_CUT_OFF_HOUR,
  DEFAULT_CUT_OFF_MINUTE,
} from 'constants/constant';

export const getPersisDateRange = (): string[] => {
  const result = JSON.parse(localStorage.getItem('dateRange') || 'null')

  let dateRange = ['','']

  if(Array.isArray(result) &&
    result.every((item) => typeof item === "string")) {
    dateRange = result
  }

  if(moment(dateRange[0]) < moment() || result === null) {
    let start = moment()
                  .subtract(1, "days")
                  .set('hour', DEFAULT_CHECK_IN_HOUR)
                  .set('minute', DEFAULT_CHECK_IN_MINUTE)
                  .set('second', 0)
                  .toDate()
    let end = moment()
                .set('hour', DEFAULT_CHECK_OUT_HOUR)
                .set('minute', DEFAULT_CHECK_OUT_MINUTE)
                .set('second', 0)
                .toDate()
    if(moment() > moment()
                    .set('hour', DEFAULT_CUT_OFF_HOUR)
                    .set('minute', DEFAULT_CUT_OFF_MINUTE)
                    .set('second', 0)) {
      start = moment()
                .set('hour', DEFAULT_CHECK_IN_HOUR)
                .set('minute', DEFAULT_CHECK_IN_MINUTE)
                .set('second', 0)
                .toDate()
      end = moment()
              .add(1, "days")
              .set('hour', DEFAULT_CHECK_OUT_HOUR)
              .set('minute', DEFAULT_CHECK_OUT_MINUTE)
              .set('second', 0)
              .toDate()
    } 
    localStorage.setItem('dateRange', JSON.stringify([start, end]))
    dateRange = JSON.parse(JSON.stringify([start, end]))   
  }

  return dateRange
}

export const getPersistBoolean = (key: string): boolean => {
  const value = JSON.parse(localStorage.getItem(key) || 'false')
  if(!value) {
    localStorage.setItem(key, 'false')
    return false
  }
  
  return !!value
}

export const getPersistNumber = (key: string): number => {
  const value = localStorage.getItem(key)
  if(value) {
    return JSON.parse(value)
  } else {
    localStorage.setItem(key, '1')
    return 1
  }
}

export const getPersistRatePlanId = (): string => {
  let value = localStorage.getItem("ratePlanId")
  if(!value) {
    localStorage.setItem("ratePlanId", ADVANCED)
    value = ADVANCED
  }
  return value
}

export const getPersistSelectedRooms = (): SelectedRooms => {
  const persistSelectedRooms: SelectedRooms = JSON.parse(
    localStorage.getItem('selectedRooms') || '{}',
  )
  return persistSelectedRooms;
}

export const getPersistCoupon = (): Coupon => {
  let coupon: Coupon = {}
  const persistedCoupon: Coupon = JSON.parse(
    localStorage.getItem('coupon') || '{}',
  )

  if(moment(persistedCoupon.validFrom) < moment() 
    && moment(persistedCoupon.validTo) > moment()
  ) {
    coupon = persistedCoupon
  } else {
    localStorage.removeItem('coupon')
  }
  
  return coupon;
}

export const getPersistAddOnOptions = (): string[] => {
  const persistAddOnOptions: string[] = JSON.parse(
    localStorage.getItem('addOnOptions') || '[]',
  )
  return persistAddOnOptions;
}

export const dateDiff = (startDay: Date, endDay: Date): number => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24

  const start = new Date(startDay)
  const end = new Date(endDay)

  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export const getMessageTimeOut = (content: string): number => {
  return Math.max(content.length * 50, 7000)
}

export const parseAddress = (place: any[]) => {
  let address = '',
    city = '',
    province = '',
    country = '',
    zip = '';
  place.forEach((component) => {
    const componentType = component.types[0];
    switch (componentType) {
      case 'street_number': {
        address = component.long_name + ' ';
        break;
      }
      case 'route': {
        address += component.short_name;
        break;
      }

      case 'postal_code': {
        zip = component.long_name;
        break;
      }

      case 'postal_code_suffix': {
        zip = component.long_name;
        break;
      }

      case 'sublocality_level_1': {
        city = component.long_name;
        break;
      }

      case 'locality': {
        city = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        province = component.short_name;
        break;
      }

      case 'country':
        country = component.long_name;
        break;
    }
  });

  return {
    address,
    city,
    province,
    country,
    zip,
  };
};

export const parseStringAddress = (address: string) => {
  const parts = address.split(', ');
  const streetAddress = parts[0]
  const city = parts[1]
  const province = parts[2].split(' ')[0]
  const postalCode = parts[2].split(' ')[1] + ' ' + parts[2].split(' ')[2]
  const country = parts[3]

  return {
    streetAddress,
    city,
    province,
    postalCode,
    country,
  };
}

export const detectCardType = (cardNumber: string) => {
  const Regexp: {[key: string]: RegExp} = {
    'electron': /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    'maestro': /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    'dankort': /^(5019)\d+$/,
    'interpayment': /^(636)\d+$/,
    'unionpay': /^(62|88)\d+$/,
    'visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
    'mastercard': /^5[1-5][0-9]{14}$/,
    'amex': /^3[47][0-9]{13}$/,
    'diners': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    'discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    'jcb': /^(?:2131|1800|35\d{3})\d{11}$/
  }

  for(const key in Regexp) {
      if(Regexp[key].test(cardNumber)) {
          return key
      }
  }
  return ''
}

// dynamically load JavaScript files in our html with callback when finished
export const loadScript = (url: string, callback: Function) => {
  const script: any = document.createElement('script'); // create script tag
  script.type = 'text/javascript';

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url; // load by url
  document.getElementsByTagName('head')[0].appendChild(script); // append to head
};


const handlePlaceSelect = async (updateQuery: Function, autoComplete: any) => {
  const addressObject = autoComplete.getPlace(); // get place from google api
  const query = addressObject.formatted_address;
  const place = addressObject.address_components;
  updateQuery(query, place);
}

// handle when the script is loaded we will assign autoCompleteRef with google maps place autocomplete
const handleScriptLoad = (updateQuery: Function, autoCompleteRef: any) => {
  // assign autoComplete with Google maps place one time
  const autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ['address'], componentRestrictions: { country: ['ca', 'us'] } },
  );
  autoComplete.setFields(['address_components', 'formatted_address']); // specify what properties we will get from API
  // add a listener to handle when the place is selected
  autoComplete.addListener('place_changed', () =>
    handlePlaceSelect(updateQuery, autoComplete),
  );
}

// prevent extra api cost when reload or first time load api
export const debounceHandleScriptLoad = debounce(2000, handleScriptLoad);
