import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../style';
import optionBackground from 'assets/images/optionBackground.svg';
import noRoomsPicture from 'assets/images/noRoomsAvailable.png';

export const SearchResultOptionsContentContainer = styled(BasicSectionContainer)`

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .background {
    width: 100%;
    height: 69px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${optionBackground});
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
  }
  @media only screen and (min-width: 767px) and (max-width: 900px) {
    .content {
      column-gap: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    
    .background {
      display: none;
    }
    
    .content {
      flex-direction: column;
      margin-bottom: 92px;
    }
  }
`;

export const RoomOptionsContainer = styled(BasicSectionContainer)`
  flex-basis: 60%;
  max-width: 800px;
  min-width: 315px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 45px;

  .noRoomsTitle {
    font-size: 33px;
    color: ${colors.red};
    margin: 20px 0 0 0;
  }

  .noRoomsPicture {
    width: 100%;
    height: 447px;
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url(${noRoomsPicture});
    margin: 20px 0 50px 0;

    @media only screen and (max-width: 767px) {
      height: auto;
      background-position: center;
    }
  }

  .roomOptionsTitle {
    font-size: 33px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .roomOptionsFooter {
    font-size: 15px;
    font-style: italic;
    color: ${colors.textInputLabel};
    margin: 0 0 50px 0;
  }

  .loading {
    font-size: 33px;
    font-weight: 600;
    margin: 20px 0;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;

    .noRoomsTitle {
      text-align: center;
    }

    .noRoomsDescription {
      width: 100%;
      text-align: center;
    }

    .noRoomsPicture {
      width: 100%;
      height: 300px;
      margin: 0;
    }

  }
`;

export const BookingSummaryContainer = styled.div`
  flex-basis: 40%;
  min-width: 400px;
  margin: 45px 0 0 20px;

  .title {
    font-size: 26px;
    margin: 10px 0;
  }

  .booking {
    font-size: 26px;
    margin: 38px 0 10px 0;
  }

  .guestOption, .roomOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.checkOutTableTextColor};

    .text {
      .optionTitle {
        font-size: 14px;
        font-weight: 600;
      }

      .optionDescription {
        font-size: 16px;
        font-style: italic;
        color: ${colors.grey};
      }
    }

    .optionButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .quantity {
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 21px;
        font-weight: 600;
      }
    }


    .disabled {
      g, line {
        stroke: ${colors.signInBorderColor};
      }
    }
  }

  .priceDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.checkOutTableTextColor};

    .text {
      font-size: 14px;
      font-weight: 600;
    }

    .price {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 767px) {
    /* width: 100%;
    max-width: 100%;
    margin: 0 0 50px 0;

    .title {
      text-align: center;
    } */
    
    display: none;
  }
`;