import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import TermsAndConditionsSection from './components/TermsAndConditionsSection';

const TermsAndConditionsScreen: React.FC = () => {

  return (
    <ScreenLayout disableModal>
      <TermsAndConditionsSection />
    </ScreenLayout>
  )
}

export default TermsAndConditionsScreen
