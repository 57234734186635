import React from 'react';
import moment from 'moment';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ratePlanChoice } from 'states/CheckOutStates/atoms';
import { dateRangeState } from 'states/SearchBarStates/atoms';
import { RESERVATION, ADVANCED } from 'constants/ratePlanId';
import { PlanSectionContainer, SectionContainerItem } from './style';

const PlanSection: React.FC<{position?: string}> = ({position}) => {

  const [ratePlanId, setRatePlanId] = useRecoilState(ratePlanChoice)
  const dateRange = useRecoilValue(dateRangeState);

  const cancelReserveDate = moment(dateRange[0]).format("MM/DD/YY h:mm A")
  const cancelPrepayDate = moment(dateRange[0]).subtract(2, "days").format("MM/DD/YY h:mm A")

  const handleSelection = (selectedRatePlanId: string) => {
    setRatePlanId(selectedRatePlanId)
    localStorage.setItem("ratePlanId", selectedRatePlanId)
  }

  return (
    <PlanSectionContainer>
      <div className="selectionContainer">
        <SectionContainerItem
          selected={ratePlanId===ADVANCED}
          onClick={() => handleSelection(ADVANCED)}
        >
          <div className="inputContainer">
            <div className="customizedRadio"></div>
            <div className="title">
              Advanced Purchase (10% off) 
            </div>
          </div>
          <p>
            Prepay for your room and receive a 10% discount. Cancel anytime up to 48 hours before your stay ({cancelPrepayDate}).
          </p>
        </SectionContainerItem>
        <SectionContainerItem
          selected={ratePlanId===RESERVATION}
          onClick={() => handleSelection(RESERVATION)}
        >
          <div className="inputContainer">
            <div className="customizedRadio"></div>
            <div className="title">
              Pay at Check-in
            </div>
          </div>
          <p>
            Stay flexible— reserve now and cancel for free anytime before {cancelReserveDate}. Your credit card will not be charged until you check in.
          </p>
        </SectionContainerItem>
      </div>
    </PlanSectionContainer>
  )
}

export default PlanSection;
