import React, { FC, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import FormInputField from 'components/FormInputField'
import Checkbox from 'components/Checkbox'
import StyledButton from 'components/Button';
import PaymentToolTip from './PaymentToolTip';
import { Icon } from 'components/Icon';
import SummaryDatePicker from 'components/SummaryDatePicker';
import AdapterMoment from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import { useRecoilValue, useRecoilState } from 'recoil'
import { dateRangeState, roomQuantityState, guestQuantityState } from 'states/SearchBarStates/atoms';
import {
  DecreaseIcon,
  RedCloseIcon,
  EditPencilIcon,
  IncreaseIcon
} from "assets/icons";
import { ADVANCED, RESERVATION, ADVANCED_RATE } from 'constants/ratePlanId';
import { dateDiff } from 'utils/util';
import { SelectedRooms } from 'types/commonTypes';
import { 
  selectedRooms,
  couponState,
  couponSuccessState,
  ratePlanChoice,
  termsOfServiceState,
  termsOfServiceErrorState,
} from 'states/CheckOutStates/atoms';
import { addOnOptionsState } from 'states/OptionStates/atoms';
import { useGetBookingAddOns } from 'customHooks/useGetBookingAddOns';
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE, APPLY_COUPON } from 'graphql/service';
import { MobileBookingSummaryContainer, RatePlanContainer } from './style';
import moment from 'moment';
import { HST_RATE, MAT_RATE } from 'constants/constant';
import {
  DEFAULT_CHECK_IN_HOUR,
  DEFAULT_CHECK_IN_MINUTE,
} from 'constants/constant';
import { PriceQuestionIcon } from 'assets/icons';
import { colors } from 'constants/colors'

const MobileBookingSummary: FC<{handleCheckOut: ()=> void}> = ({handleCheckOut}) => {

  const history = useHistory()
  
  const [persistSelectedRooms, setPersistSelectedRooms] = useRecoilState(selectedRooms);
  const [guestQuantity, setGuestQuantity] = useRecoilState(guestQuantityState);
  const [roomQuantity, setRoomQuantity] = useRecoilState(roomQuantityState);
  const dateRange = useRecoilValue(dateRangeState)
  const [availableRooms, setAvailableRooms] = useState(0)

  const [addOnOptions, setAddOnOptions] = useRecoilState(addOnOptionsState);
  const bookingAddOns = useGetBookingAddOns()

  const [termsOfServiceError, setTermsOfServiceError] = useRecoilState(termsOfServiceErrorState)
  const [termsOfServiceChecked, setTermsOfServiceChecked] = useRecoilState(termsOfServiceState)


  const [getAvailableRoomsByRoomTypeIdAndDateRange, { data: roomsData }] = useLazyQuery(
    GET_AVAILABLE_ROOMS_BY_ROOM_TYPE_ID_AND_DATE_RANGE, 
    {
      onError: (error) => {
        const content = error.message
        console.log(content)
      }  
    }
  )

  useEffect(() => {
    if(dateRange[0] && dateRange[1] && persistSelectedRooms.roomTypeId) {
      const updatedRooms: SelectedRooms = {...persistSelectedRooms}
      if(moment(dateRange[0]) >= moment()
                                  .subtract(1, "days")
                                  .set('hour', DEFAULT_CHECK_IN_HOUR)
                                  .set('minute', DEFAULT_CHECK_IN_MINUTE)
                                  .set('second', 0)
      ) {
        getAvailableRoomsByRoomTypeIdAndDateRange({
          variables: {
            input: {
              roomTypeId: updatedRooms.roomTypeId,
              checkInDate: dateRange[0],
              checkOutDate: dateRange[1]
            }
          },
          onCompleted: (roomsData) => {
            if(roomsData.roomsAvailability?.[0]?.roomType?.pricePerNight) {
              updatedRooms.pricePerNight = roomsData.roomsAvailability?.[0]?.roomType?.pricePerNight
              setAvailableRooms(roomsData.roomsAvailability.length)
              setPersistSelectedRooms(updatedRooms)
              localStorage.setItem('selectedRooms', JSON.stringify(updatedRooms))
            } 
          },
          onError: (error) => {
            const content = error.message
            console.log(content)
          }
        })
      }
    }
  }, [roomQuantity, guestQuantity, dateRange[0], dateRange[1], getAvailableRoomsByRoomTypeIdAndDateRange])

  const [ratePlanId, setRatePlanId] = useRecoilState(ratePlanChoice)


  const [coupon, setCoupon] = useRecoilState(couponState)
  const [couponInput, setCouponInput] = useState(coupon.couponCode || '')
  const [couponError, setCouponError] = useState(false)
  const [couponSuccess, setCouponSuccess] = useRecoilState(couponSuccessState)

  const successMessage = 'Success! Coupon code applied.'
  const errorMessage = 'Coupon code is invalid'

  const [applyCoupon] = useLazyQuery(APPLY_COUPON, {
    onCompleted: (couponQueryData) => {
      if(couponQueryData.couponByCode?.isActive) {
        setCouponError(false)
        setCouponSuccess(true)
        setCoupon(couponQueryData.couponByCode)
        localStorage.setItem("coupon", JSON.stringify(couponQueryData.couponByCode))
      } else {
        setCouponError(true)
        setCouponSuccess(false)
        setCouponInput('')
      }
    },
    onError: (error) => {
      setCouponError(true)
      setCouponSuccess(false)
      setCouponInput('')
    }
  })

  const nights = dateDiff(new Date(dateRange[0]), new Date(dateRange[1]))
  const roomFee = (persistSelectedRooms.pricePerNight || 0) * nights * roomQuantity
  const addOnFee = bookingAddOns.reduce((acc, addOn) => {
    if (addOnOptions.includes(addOn.addOnId)) {
      if(addOn.isOneTimePriced) {
        return acc + (addOn.pricePerNight || 0) * roomQuantity
      } else {
        return acc + (addOn.pricePerNight || 0) * nights * roomQuantity
      }
    }
    return acc
  }
  , 0)

  const couponDiscount = coupon.rateType==='FIXED_RATE'? (coupon.discount || 0): (coupon.discount || 0) / 100 * roomFee
  const ratePlanDiscount = ratePlanId === ADVANCED? coupon.rateType==='FIXED_RATE'? (roomFee - (coupon.discount || 0)) * (1 - ADVANCED_RATE): roomFee * (1 - (coupon.discount || 0) / 100) * (1 - ADVANCED_RATE): 0
  const discountedRoomFee = coupon.rateType==='FIXED_RATE'? (roomFee - (coupon.discount || 0)) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1): roomFee * (1 - (coupon.discount || 0) / 100) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1)
  const subtotal = discountedRoomFee + addOnFee
  const MAT = discountedRoomFee * MAT_RATE
  const HST = (subtotal + MAT) * HST_RATE
  const total = subtotal + MAT + HST

  const handleCouponApply = () => {
    if (couponInput.length) {
      applyCoupon({
        variables: {
          couponCode: couponInput.toUpperCase()
        }
      })
    }
  }

  const addOnOptionsArray = bookingAddOns.map((addOn) => {
    if(addOnOptions.includes(addOn.addOnId)) {
      return (
        <div className="addOnOption" key={addOn.addOnId}>
          <span className="text">{addOn.label?.toUpperCase()}</span>
          <span
            className="remove"
            onClick={() => {
              const index = addOnOptions.indexOf(addOn.addOnId)
              const newAddOnOptions = [...addOnOptions]
              newAddOnOptions.splice(index, 1)
              setAddOnOptions(newAddOnOptions)
              localStorage.setItem("addOnOptions", JSON.stringify(newAddOnOptions))
            }}
          >
            <RedCloseIcon className='removeIcon' />
          </span>
        </div>
      
      )
    }
    return null
  })

  const addOnPricesArray = bookingAddOns.map((addOn) => {
    if(addOnOptions.includes(addOn.addOnId)) {
      return (
        <div className="breakDownRow" key={addOn.addOnId}>
          <div className="breakDownLabel">
            {addOn.label}
          </div>
          <div className="breakDownValue">
            ${(addOn.isOneTimePriced? addOn.pricePerNight * roomQuantity: addOn.pricePerNight * nights * roomQuantity).toFixed(2)}
          </div>
        </div>
      )
    }
    return null
  })

  return (
    <MobileBookingSummaryContainer>
      <div className="title">
        Booking Summary
      </div>
      <div className="bookingDates">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SummaryDatePicker label="DATES" dateRange={dateRange}/>
        </LocalizationProvider>
      </div>
      <div className="guestOption">
        <div className="text">
          <div className="optionTitle">GUESTS</div>
          <div className="optionDescription">How many people are travelling?</div>
        </div>
        <div className='optionButtons'>
          <Icon width={30} height={30}>
            <DecreaseIcon
              className={guestQuantity <= 1? "disabled": ""}
              onClick={() => {
                if(guestQuantity > 1) {
                  localStorage.setItem('guestQuantity', (guestQuantity - 1).toString());
                  setGuestQuantity(guestQuantity - 1);
                  if(roomQuantity > guestQuantity - 1) {
                    localStorage.setItem('roomQuantity', (guestQuantity - 1).toString());
                    setRoomQuantity(guestQuantity - 1);
                  }
                }
              }} 
            />
          </Icon>
          <div className='quantity'>{guestQuantity}</div>
          <Icon width={30} height={30}>
            <IncreaseIcon
              className={guestQuantity >= Math.min(2*availableRooms, 9)? 'disabled': ''}
              onClick={() => {
                if(guestQuantity < (availableRooms>0? 2 * Math.min(availableRooms, 9): 9)) {
                  localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                  setGuestQuantity(guestQuantity + 1);
                  if(roomQuantity < Math.ceil((guestQuantity+1)/2)) {
                    localStorage.setItem('roomQuantity', Math.ceil((guestQuantity+1)/2).toString());
                    setRoomQuantity(Math.ceil((guestQuantity+1)/2));
                  }
                }
              }} 
            />
          </Icon>
        </div>
      </div>
      <div className="roomOption">
        <div className="text">
          <div className="optionTitle">ROOMS</div>
          <div className="optionDescription">Max 2 guests per room</div>
        </div>
        <div className='optionButtons'>
          <Icon width={30} height={30}>
            <DecreaseIcon
              className={roomQuantity <= Math.ceil(guestQuantity/2)? "disabled": ""}
              onClick={() => {
                if(roomQuantity > Math.ceil(guestQuantity/2)) {
                  localStorage.setItem('roomQuantity', (roomQuantity - 1).toString());
                  setRoomQuantity(roomQuantity - 1);
                }
              }} 
            />
          </Icon>
          <div className='quantity'>{roomQuantity}</div>
          <Icon width={30} height={30}>
            <IncreaseIcon
              className={roomQuantity >= (availableRooms>0? Math.min(availableRooms, 9): 9)? "disabled": ""}
              onClick={() => {
                if(roomQuantity < (availableRooms>0? Math.min(availableRooms, 9): 9)) {
                  localStorage.setItem('roomQuantity', (roomQuantity + 1).toString());
                  setRoomQuantity(roomQuantity + 1);
                  if(roomQuantity===guestQuantity) {
                    localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                    setGuestQuantity(guestQuantity + 1);
                  }
                }
              }} 
            />
          </Icon>
        </div>
      </div>
      <RatePlanContainer>
        <div className="infoLabel">RATE</div>
        <div className="infoValue">
          <span>{ratePlanId === ADVANCED? 'Advanced Purchase (10% off)': 'Pay at Check-in'}</span>
          <EditPencilIcon onClick={()=>{
            if(ratePlanId === ADVANCED) {
              setRatePlanId(RESERVATION)
              localStorage.setItem("ratePlanId", RESERVATION)
            } else {
              setRatePlanId(ADVANCED)
              localStorage.setItem("ratePlanId", ADVANCED)
            }
          }}/>
        </div>
      </RatePlanContainer>
      {addOnOptionsArray}
      <div className="breakDown">
        <div className="breakDownTitle">
          BREAKDOWN
        </div>
        <div className="breakDownRow">
          <div className="breakDownLabel">
            {(roomQuantity>1? roomQuantity + ' Rooms': roomQuantity + ' Room')
              + ' - '  + (nights>1? nights + ' Nights ': nights + ' Night ')
            }
            <br />
            {'\u00a0\u00a0\u00a0\u00a0' + (persistSelectedRooms.bed || 'Queen bed') + ' w/'
              + (persistSelectedRooms.hasWindows? ' Windows': ' skylight')
            }
          </div>
          <div className="breakDownValue">
            ${roomFee.toFixed(2)}
          </div>
        </div>
        {couponSuccess && (
          <div className="breakDownRow">
            <div className="breakDownLabel">
              <span className="warning">{`Coupon (${coupon.rateType==='FIXED_RATE'? '$'+ coupon.discount + ' off': coupon.discount + '% off'})`}</span>
            </div>
            <div className="breakDownValue">
              <span className="warning">-${couponDiscount.toFixed(2)}</span>
            </div>
          </div>
        )}
        {ratePlanId===ADVANCED && (
          <div className="breakDownRow">
            <div className="breakDownLabel">
              <span className="warning">Advanced Purchase (10% off)</span>
            </div>
            <div className="breakDownValue">
              <span className="warning">-${ratePlanDiscount.toFixed(2)}</span>
            </div>
          </div>
        )}
        <div className="breakDownRow">
          <div className="breakDownLabel">
            Municipal Accommodation Tax
            <PaymentToolTip
              title={
                (
                  <div>
                    <PriceQuestionIcon />
                    <div
                      style={{
                        width: '300px',
                        height: '90px',
                      }}
                    >
                      <span
                        style={{
                          color: colors.tooltip,
                          fontSize: '14px',
                          lineHeight: '18px',
                          fontWeight: 600,
                          fontStyle: 'italic',
                          fontFamily: 'Titillium Web',
                        }}
                      >
                        {'What is this?\u00A0\u00A0'}
                      </span>
                      <span
                        style={{
                          color: colors.black,
                          fontSize: '14px',
                          lineHeight: '18px',
                          fontFamily: 'Titillium Web',
                        }}
                      >
                        The Municipal Accommodation Tax is applied by the city of Mississauga on all hotel room rentals. The tax is 6% of the lodging cost, and is not applied to any other amenities. All hotels in Mississauga are obligated to charge this tax.
                      </span>
                    </div>
                  </div>
                )
              }
            >
              <PriceQuestionIcon />
            </PaymentToolTip>  
          </div>
          <div className="breakDownValue">
            ${MAT.toFixed(2)}
          </div>
        </div>
        {addOnPricesArray}
        <div className="breakDownRow">
          <div className="breakDownLabel">
            HST
          </div>
          <div className="breakDownValue">
            ${HST.toFixed(2)}
          </div>
        </div>
      </div>
      <div className="totalRow">
        <div className="totalLabel">
          TOTAL
        </div>
        <div className="totalValue">
          ${total.toFixed(2)}
        </div>
      </div>
      <div className='termsOfService'>
        <Checkbox
          checked={termsOfServiceChecked}
          error={termsOfServiceError}
          borderColor={colors.labelGrey}
          onChange={() => {
            setTermsOfServiceChecked(!termsOfServiceChecked)
            localStorage.setItem('termsOfServiceChecked', (!termsOfServiceChecked).toString())
            setTermsOfServiceError(false)
          }}
          checkboxScale={1.5}
        />
        <div className='text'>
          <span className='plainText'>I agree to Rook Hotel’s </span>
          <span
            className='highLight'
            onClick={() => {
              history.push('/termsOfService')
            }}
          >Terms of Service</span></div>
      </div>
      <StyledButton
        className="checkOutButton"
        width="100%"
        height="59px"        
        fontSize={18}
        onClick={handleCheckOut}
      >
        Book now
      </StyledButton>
      <div className='couponContainer'>
        <FormInputField
          label="COUPON CODE"
          error={couponError}
          errorMessage={errorMessage}
          success={couponSuccess}
          successMessage={successMessage}
          borderColor={colors.signInBorderColor}
          onChange={(e) => {
            setCouponInput(e.target.value)
            if(couponError) {
              setCouponError(false)
            }
            if(couponSuccess) {
              setCouponSuccess(false)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleCouponApply()
            }
          }}
          value={couponInput}
          inputHeight="43px"
        />
        <StyledButton
          className="applyCouponButton"
          width="87px"
          height="43px"
          disabled={!couponInput.length}
          onClick={handleCouponApply}
        >
          Apply
        </StyledButton>
      </div>
    </MobileBookingSummaryContainer>
  )
}

export default MobileBookingSummary