import { useEffect, useRef } from 'react';
import { loadScript, debounceHandleScriptLoad } from 'utils/util';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useGoogleAutocomplete = (updateQuery: Function) => {
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_APIKEY}&libraries=places`,
      () => debounceHandleScriptLoad(updateQuery, autoCompleteRef),
    );
  }, []);

  return autoCompleteRef
}
