import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../style';

export const ContactInfoSectionContainer = styled(BasicSectionContainer)`
  display: flex;
  justify-content: center;
  align-items: center;

  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 3%;
    padding-bottom: 3%;

    @media only screen and (max-width: 1200px) {
      flex-direction: column-reverse;
    }
  }

`;

export const InfoSectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .info {
    width: 100%;
    max-width: 596px;
  }

  .infoTitle {
    font-size: 29px;
    font-weight: bold;
    margin: 1vw 0;
  }

  .infoDescription {
    width: 80%;
    font-size: 16px;
    color: ${colors.homeDescription};
    margin: 1vw 0;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }
`;

export const MapSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  iframe {
    width: 100%;
    max-width: 596px;
    height: 40vw;   
    max-height: 443px;

    @media only screen and (max-width: 1200px) {
      width: 100%;
      min-width: 332px;
    }
  }
`;
