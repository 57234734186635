import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'constants/colors';

const PaymentToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.white,
    maxWidth: '400px',
    maxHeight: '120px',
    boxShadow: `0 0 5px 0 ${colors.borderGrey2}`,
    borderRadius: '0px',
    padding: '16px',
    boxSizing: 'border-box'
  },
}))(Tooltip);

export default PaymentToolTip;
