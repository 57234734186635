import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import CompleteContent from './components/CompleteContent';

const SetupUserCompleteScreen: React.FC = () => {

  return (
    <ScreenLayout disableModal>
      <CompleteContent />
    </ScreenLayout>
  );
};

export default SetupUserCompleteScreen;
