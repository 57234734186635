import { atom } from 'recoil';
import { getPersistAddOnOptions } from 'utils/util';

export type BookingAddOn= {
  addOnId: string;
  title: string;
  label: string;
  description: string[];
  pricePerNight: number;
  isOneTimePriced: boolean;
}

export const bookingAddOnsState = atom<BookingAddOn[]>({
  key: 'bookingAddOns',
  default: [],
});

export const addOnOptionsState = atom({
  key: 'addOnOptions',
  default: getPersistAddOnOptions(),
});
