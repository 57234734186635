import React, { FC, useState } from 'react';
import { DropdownContainer, StyledItem } from './styles';
import { DropdownContainerProps } from './styles';

export type DropdownProps = DropdownContainerProps & {
  isActive: boolean,
  width: string,
  options: {label: string, function: ()=> void}[],
  selectorHeight?: string
}

const Dropdown: FC<DropdownProps> = ({
  isActive,
  width,
  options,
  selectorHeight,
}) => {

  return (
    <DropdownContainer
      className='dropdown'
      width={width}
      selectorHeight={selectorHeight}
      isActive={isActive}
    >
      <div className='options'> 
        {
          options?.map((option, index) => {
            return (
              <StyledItem
                className="option"
                key={option.label}
                onClick={option.function}
              >
                {option.label}
              </StyledItem>
            )
          })
        }
      </div>
    </DropdownContainer>
  )
}

export default Dropdown;