import React from 'react';
import { useHistory } from 'react-router';
import {
  BlogPostContainer,
  ContentContainer,
  ImageContainer,
} from './styles';

type BlogPostProps = {
  description: string;
  title: string;
  imageSource: string;
  imageWidth?: string;
  imageHeight?: string;
};

const BlogPost: React.FC<BlogPostProps> = ({
  description,
  title,
  imageSource,
  imageWidth,
  imageHeight,
}) => {
  const history = useHistory()

  return (
    <BlogPostContainer>
      <ImageContainer imageWidth={imageWidth} imageHeight={imageHeight}>
        <source media="(min-width:767px)" srcSet={imageSource} />
        <source media="(max-width:767px)" srcSet={imageSource} />
        <img src={imageSource} alt="homeImage" />
      </ImageContainer>
      <ContentContainer>
        <div className="contentTitleContainer">
          {title}
        </div>
        <div className="contentDescriptionContainer">
          <p>{description}</p>
        </div>
        <div className="link" onClick={() => history.push('/blog')}>
          Link text {'>'}
        </div>
      </ContentContainer>

    </BlogPostContainer>
  )
}
export default BlogPost;
