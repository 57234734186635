import React, { useEffect } from 'react';
import Modal from 'components/Modal';
import { TimeOutContainer, CloseButton } from './style';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  searchResultState,
  dateRangeState,
  guestQuantityState,
  roomQuantityState,
  isWheelchairAccessibleState
} from "states/SearchBarStates/atoms";
import { useTimeOut } from 'customHooks/useTimeOut';
import { RedCloseIcon } from 'assets/icons';

const TimeOut: React.FC = ({}) => {
  
  const history = useHistory()
  const { sessionTimeOut } = useTimeOut()
  const dateRange = useRecoilValue(dateRangeState);
  const roomQuantity = useRecoilValue(roomQuantityState);
  const guestQuantity = useRecoilValue(guestQuantityState);
  const checkInDate = dateRange[0];
  const checkOutDate = dateRange[1];

  return (
    <>
      <Modal open={sessionTimeOut} top="160px">
        <TimeOutContainer>
          <div
            className='timeOutImage'
            onClick={() => {
              const destination = `/search?checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&guestQuantity=${guestQuantity}&roomQuantity=${roomQuantity}`
              if(destination.includes(window.location.pathname)) {
                history.push(destination);
                window.location.reload()
              } else {
                history.push(destination);
              }
            }}
          >
            <CloseButton
              onClick={() => {
                const destination = `/search?checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&guestQuantity=${guestQuantity}&roomQuantity=${roomQuantity}`
                if(destination.includes(window.location.pathname)) {
                  history.push(destination);
                  window.location.reload()
                } else {
                  history.push(destination);
                }
              }}
            >
              <RedCloseIcon />
            </CloseButton>
          </div>
        </TimeOutContainer>
      </Modal>

    </>
  )
}

export default TimeOut;
