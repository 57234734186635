import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import checkEmail from 'assets/icons/checkEmail.svg';

export const CheckOutConfirmScreenContainer = styled(BasicSectionContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titleContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 64px 20px;

    .checkEmailImage {
      width: 311px;
      height: 208px;
      background-image: url(${checkEmail});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 20px;
    }

    .titleHighlight {
      text-align: center;
      font-size: 34px;
      font-weight: 600;
      color: ${colors.black};
    }

    .subtitle {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: ${colors.black};
    }
  }

  .changeEmailContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: ${colors.backgroundGreen};
    padding: 36px 20px 64px 20px;

    .title {
      width: 100%;
      text-align: center;
      font-size: 34px;
      font-weight: 600;
      color: ${colors.white};
    }

    .description {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: ${colors.white};
      margin: 5px 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .changeEmailContainer {
      .description {
        text-align: left;
      }
    }
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0 10px 0;

  .row {
    display: flex;
    width: 100%;
    max-width: 450px;
    height: 50px;
    min-width: 300px;
    column-gap: 10px;
    padding: 0 20px;
  }

  @media only screen and (max-width: 767px) {
    .row {
      width: 100%;
      padding: 0;
    }
  }   
`;