import React from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { colors } from 'constants/colors'
import spinner from 'assets/animations/spinner.svg';

type MaterialUiButton = React.ComponentProps<typeof Button>;
interface styleButtonProps extends MaterialUiButton {
  width?: string;
  height?: string;
  margin?: string;
  fontColor?: string;
  backgroundColor?: string;
  fontSize?: number;
  fontWeight?: number;
  loading?: boolean;
  disabledBackgroundColor?: string;
  disabledColor?: string;
}



//Overwrite material ui Button default style
const StyledButton = styled(
  ({
    width,
    height,
    fontSize,
    fontWeight,
    fontColor,
    margin,
    backgroundColor,
    loading,
    disabledBackgroundColor,
    disabledColor,
    ...rest
  }: styleButtonProps) => <Button {...rest} />,
)`
  width: ${(props) => (props.width?? null)};
  min-width: 32px;
  height: ${(props) => (props.height?? null)};
  margin: ${(props) => (props.margin?? null)};
  background-color: ${(props) => (props.backgroundColor?? colors.hoverBlue)};
  font-size: ${(props) => (props.fontSize? `${props.fontSize}px` : '16px')};
  font-weight: ${(props) => (props.fontWeight?? '700')};
  font-family: 'Titillium Web', sans-serif;
  color: ${(props) => props.fontColor?? colors.white};
  border-radius: 0;
  padding: 0;
  text-transform: none;
  box-shadow: none;
  &:hover {
    background-color: ${colors.buttonHoverDarkBlue};
  }

    &:disabled {
    background-color: ${(props) => (props.disabledBackgroundColor?? null)}; 
    color: ${(props) => (props.disabledColor?? null)}; 
  }

  ${(props) => props.loading? `
    background-image: url(${spinner});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
  `: null};

  .MuiButton-startIcon {
    margin-right: 5px;
  }

  .MuiButton-root {
    
  }
`

export default StyledButton
