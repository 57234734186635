import styled from 'styled-components';
import { colors } from 'constants/colors';

export const DatePickerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SearchBarDateInput = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  label {
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    font-weight: bold;
    color: ${colors.borderGrey};
  }

  input {
    width: 100%;
    min-width: 170px;
    height: 20px;
    line-height: 20px;
    border: none;
    position: relative;
    font-size: 14px;
    color: ${colors.userSelectionBlack};
    padding: 0;
    margin: 0;
    cursor: pointer;
    pointer-events: none;

    &:focus {
      outline: none;
    }
  }

  @media only screen and (max-width: 767px) {
    label {
      font-size: 12px;
    }

    input {
      font-size: 15px;
      line-height: 23px;
      height: 23px;
    }
  }
`;
