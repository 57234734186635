import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { AccountMenuBarContainer } from './style';
import {
  DashboardIcon,
  PersonalDetailsIcon,
  PersonalEmailIcon,
  PersonalPhoneIcon,
  BookingsIcon,
  BillingIcon,
  SecurityIcon,
  HelpIcon,
  ContactInformation
} from 'assets/icons';
import { Icon } from 'components/Icon';

const AccountMenuBar: FC = () => {

  return (
    <AccountMenuBarContainer>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/dashboard"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconDashboard">
          <DashboardIcon />
        </Icon>
        <span>Dashboard</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/profile"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconPersonalDetailsIcon">
          <PersonalDetailsIcon />
        </Icon>

        <span>Profile</span>
      </NavLink>

      <NavLink
        className={"accountMenuItem accountSubMenu"}
        to={"/account/personalDetails"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconContactInformation">
          <ContactInformation />
        </Icon>

        <span>Personal Details</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem accountSubMenu"}
        to={"/account/email"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconPersonalEmailIcon">
          <PersonalEmailIcon />
        </Icon>
        <span>Email</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem accountSubMenu"}
        to={"/account/phoneNumber"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconPersonalPhoneIcon">
          <PersonalPhoneIcon />
        </Icon>
        <span>Phone Number</span>
      </NavLink>

      <NavLink
        className={"accountMenuItem"}
        to={"/account/bookings"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconBookingsIcon">
          <BookingsIcon />
        </Icon>
        <span>Bookings</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/billing"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconBillingIcon">
          <BillingIcon />
        </Icon>
        <span>Billing</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem"}
        to={"/account/security"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconSecurityIcon">
          <SecurityIcon />
        </Icon>
        <span>Security</span>
      </NavLink>
      <NavLink
        className={"accountMenuItem buttonHelp"}
        to={"/help"}
        activeClassName="active"
      >
        <Icon className="accountMenuIcon iconHelpIcon">
          <HelpIcon />
        </Icon>
        <span>Help</span>
      </NavLink>
    </AccountMenuBarContainer>
  );
};

export default AccountMenuBar;
