import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StyledButton from 'components/Button';
import { Icon } from 'components/Icon';
import SummaryDatePicker from 'components/SummaryDatePicker';
import Card from 'components/Card';
import { BookingSummaryContainer } from './style';
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedRooms } from 'states/CheckOutStates/atoms';
import { dateRangeState, guestQuantityState, roomQuantityState } from 'states/SearchBarStates/atoms';
import AdapterMoment from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import { useGetAvailableRooms } from 'customHooks/useGetAvailableRooms';
import moment from 'moment';
import { DecreaseIcon, IncreaseIcon } from 'assets/icons';
import {
  DEFAULT_CHECK_IN_HOUR,
  DEFAULT_CHECK_IN_MINUTE,
  DEFAULT_CHECK_OUT_HOUR,
  DEFAULT_CHECK_OUT_MINUTE,
  DEFAULT_CUT_OFF_HOUR,
  DEFAULT_CUT_OFF_MINUTE,
  DEFAULT_MAX_DAYS
} from 'constants/constant';

const BookingSummary: FC<{ handleContinueBooking: () => void }> = ({ handleContinueBooking }) => {

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const checkInDateFromURL = params.get('checkInDate')
  const checkOutDateFromURL = params.get('checkOutDate')
  const guestQuantityFromURL = parseInt(params.get('guestQuantity') || '1')
  const roomQuantityFromURL = parseInt(params.get('roomQuantity') || '1')

  const persistSelectedRooms = useRecoilValue(selectedRooms);
  const [guestQuantity, setGuestQuantity] = useRecoilState(guestQuantityState);
  const [roomQuantity, setRoomQuantity] = useRecoilState(roomQuantityState);
  const [dateRange, setDateRange] = useRecoilState(dateRangeState);
  const { getAvailableRoomTypesByDateRange, searchData, searchResult } = useGetAvailableRooms()
  const availableRooms = searchResult.find((roomType) => roomType.roomTypeId === persistSelectedRooms.roomTypeId)?.availableRooms || 0

  useEffect(() => {
    if(checkInDateFromURL && checkOutDateFromURL) {
      const dateRange = [checkInDateFromURL, checkOutDateFromURL]
      if(moment() > moment()
                      .set('hour', DEFAULT_CUT_OFF_HOUR)
                      .set('minute', DEFAULT_CUT_OFF_MINUTE)
                      .set('second', 0)
      ) {
        if(moment(checkInDateFromURL) < moment()) {
          dateRange[0] = moment()
                          .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else if(moment(checkInDateFromURL) > moment()
                                                .add((DEFAULT_MAX_DAYS-1), "days")
                                                .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
        ) {
          dateRange[0] = moment()
                          .add((DEFAULT_MAX_DAYS-1), "days")
                          .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else {
          dateRange[0] = moment(checkInDateFromURL)
                          .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }
        if(moment(checkOutDateFromURL) < moment(checkInDateFromURL)
                                          .add(1, "days")
                                          .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
        ) {
          dateRange[1] = moment(checkInDateFromURL)
                          .add(1, "days")
                          .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else if(moment(checkOutDateFromURL) > moment()
                                                  .add(DEFAULT_MAX_DAYS, "days")
                                                  .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
        ) {
          dateRange[1] = moment()
                          .add(DEFAULT_MAX_DAYS, "days")
                          .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else {
          dateRange[1] = moment(checkOutDateFromURL)
                          .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }

      } else {
        if(moment(checkInDateFromURL) < moment()) {
          dateRange[0] = moment()
                          .subtract(1, "days")
                          .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else if(moment(checkInDateFromURL) > moment()
                                                .add((DEFAULT_MAX_DAYS-2), "days")
                                                .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
        ) {
          dateRange[0] = moment()
                          .add((DEFAULT_MAX_DAYS-2), "days")
                          .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else {
          dateRange[0] = moment(checkInDateFromURL)
                          .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }
        if(moment(checkOutDateFromURL) < moment(checkInDateFromURL)
                                          .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
        ) {
          dateRange[1] = moment(checkInDateFromURL)
            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else if(moment(checkOutDateFromURL) > moment()
                                                  .add((DEFAULT_MAX_DAYS-1), "days")
                                                  .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
        ) {
          dateRange[1] = moment()
                          .add((DEFAULT_MAX_DAYS-1), "days")
                          .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        } else {
          dateRange[1] = moment(checkOutDateFromURL)
                          .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }
      }
      localStorage.setItem('dateRange', JSON.stringify(dateRange));
      setDateRange(dateRange);
    }

    if(guestQuantityFromURL > 18) {
      localStorage.setItem('guestQuantity', '18');
      setGuestQuantity(18);
    } else if((guestQuantityFromURL >= 1) && (guestQuantityFromURL <= 18)) {
      localStorage.setItem('guestQuantity', guestQuantityFromURL + '');
      setGuestQuantity(guestQuantityFromURL);
    } else {
      localStorage.setItem('guestQuantity', '1');
      setGuestQuantity(1);
    }

    if(roomQuantityFromURL > Math.min(guestQuantityFromURL, 9)) {
      localStorage.setItem('roomQuantity', Math.min(guestQuantityFromURL, 9) + '');
      setRoomQuantity(Math.min(guestQuantityFromURL, 9));
    } else if((roomQuantityFromURL >= guestQuantityFromURL/2) && (roomQuantityFromURL <= Math.min(guestQuantityFromURL, 9))) {
      localStorage.setItem('roomQuantity', roomQuantityFromURL + '');
      setRoomQuantity(roomQuantityFromURL);
    } else {
      localStorage.setItem('roomQuantity', Math.ceil(guestQuantity/2) + '');
      setRoomQuantity(Math.ceil(guestQuantity/2));
    }
  }, [])

  useEffect(() => {
    if(dateRange[0] && dateRange[1]) {
      if(moment(dateRange[0]) >= moment()
                                  .subtract(1, "days")
                                  .set('hour', DEFAULT_CHECK_IN_HOUR)
                                  .set('minute', DEFAULT_CHECK_IN_MINUTE)
                                  .set('second', 0)
      ) {
        getAvailableRoomTypesByDateRange({
          variables: {
            input: searchData
          }
        }) 
      }
    }
  }, [
    guestQuantity,
    roomQuantity,
    dateRange,
    getAvailableRoomTypesByDateRange
  ])

  return (
    <BookingSummaryContainer>
      <Card padding='10px 20px'>
        <div className="title">Book your stay with us</div>
        <div className="bookingDates">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SummaryDatePicker label="DATES" dateRange={dateRange}/>
          </LocalizationProvider>
        </div>
        <div className="guestOption">
          <div className="text">
            <div className="optionTitle">GUESTS</div>
            <div className="optionDescription">How many people are travelling?</div>
          </div>
          <div className='optionButtons'>
            <Icon width={30} height={30}>
              <DecreaseIcon
                className={guestQuantity <= 1? "disabled": ""}
                onClick={() => {
                  if(guestQuantity > 1) {
                    localStorage.setItem('guestQuantity', (guestQuantity - 1).toString());
                    setGuestQuantity(guestQuantity - 1);
                    if(roomQuantity > guestQuantity - 1) {
                      localStorage.setItem('roomQuantity', (guestQuantity - 1).toString());
                      setRoomQuantity(guestQuantity - 1);
                    }
                  }
                }} 
              />
            </Icon>
            <div className='quantity'>{guestQuantity}</div>
            <Icon width={30} height={30}>
              <IncreaseIcon
                className={guestQuantity >= (availableRooms>0? 2 * Math.min(availableRooms, 9): 9) ? "disabled": ""}
                onClick={() => {
                  if(guestQuantity < (availableRooms>0? 2 * Math.min(availableRooms, 9): 9)) {
                    localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                    setGuestQuantity(guestQuantity + 1);
                    if(roomQuantity < Math.ceil((guestQuantity+1)/2)) {
                      localStorage.setItem('roomQuantity', Math.ceil((guestQuantity+1)/2).toString());
                      setRoomQuantity(Math.ceil((guestQuantity+1)/2));
                    }
                  }
                }} 
              />
            </Icon>
          </div>
        </div>
        <div className="roomOption">
          <div className="text">
            <div className="optionTitle">ROOMS</div>
            <div className="optionDescription">Max 2 guests per room</div>
          </div>
          <div className='optionButtons'>
            <Icon width={30} height={30}>
              <DecreaseIcon
                className={roomQuantity <= Math.ceil(guestQuantity/2)? "disabled": ""}
                onClick={() => {
                  if(roomQuantity > Math.ceil(guestQuantity/2)) {
                    localStorage.setItem('roomQuantity', (roomQuantity - 1).toString());
                    setRoomQuantity(roomQuantity - 1);
                  }
                }} 
              />
            </Icon>
            <div className='quantity'>{roomQuantity}</div>
            <Icon width={30} height={30}>
              <IncreaseIcon
                className={roomQuantity >= (availableRooms>0? Math.min(availableRooms, 9): 9)? "disabled": ""}
                onClick={() => {
                  if(roomQuantity < (availableRooms>0? Math.min(availableRooms, 9): 9)) {
                    localStorage.setItem('roomQuantity', (roomQuantity + 1).toString());
                    setRoomQuantity(roomQuantity + 1);
                    if(roomQuantity===guestQuantity) {
                      localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                      setGuestQuantity(guestQuantity + 1);
                    }
                  }
                }} 
              />
            </Icon>
          </div>
        </div>
        <div className="booking">Your Booking: </div>
        <div className="priceDetails">
          <span className='text'>{persistSelectedRooms.name?.toUpperCase()}</span>
          <span className='price'>{` CAD ${persistSelectedRooms.pricePerNight?.toFixed(2)} / night`}</span>
        </div>
        <StyledButton
          className="nextButton"
          width="100%"
          height="59px"
          margin='42px 0 10px 0'
          fontSize={18}
          onClick={handleContinueBooking}
          disabled={!persistSelectedRooms.roomTypeId || dateRange[0] === null || dateRange[1] === null}
        >
          Next Step {'>'}
        </StyledButton>
      </Card>
    </BookingSummaryContainer>
  )
}

export default BookingSummary