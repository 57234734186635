import React from 'react';
import { RoomOptionsContentContainer } from './style';
import RoomOptions from './RoomOptions';
import BookingSummary from './BookingSummary';
import TimeOut from 'components/TimeOut';

const RoomOptionsContent: React.FC = () => {

  return (
    <RoomOptionsContentContainer>
      <div className="background"></div>
      <div className="innerContainer">
        <div className="content">
          <RoomOptions />
          <BookingSummary />
        </div>
      </div>
      <TimeOut />
    </RoomOptionsContentContainer>
  )
}

export default RoomOptionsContent;