import React from 'react';
import { Input } from '@material-ui/core';
import { InfoCircle } from 'assets/icons'
import { FormInputFieldContainer, InputFieldContainerProps } from './style';

type MaterialUiInput = React.ComponentProps<typeof Input>;

type IconType = 'info' | 'warning'; // Icon types

type InputFieldProps = {
  className?: string,
  label?: string;
  highLightLabel?: string;
  labelWeight?: string;
  labelSize?: string;
  inputFontSize?: string;
  inputFontWeight?: string;
  inputBackgroundColor?: string;
  iconUrl?: string;
  errorMessage?: string;
  success?: boolean;
  successMessage?: string;
  isRequired?: boolean;
  maxLength?: number;
  infoIcon?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
} & InputFieldContainerProps &
  MaterialUiInput;

const FormInputField: React.FC<InputFieldProps> = ({
  className,
  highLightLabel,
  label,
  width,
  marginBottom,
  iconUrl,
  inputBackgroundColor,
  isRequired,
  maxLength,
  infoIcon,
  onMouseOver,
  onMouseOut,
  ...layout
}) => {

  return (
    <FormInputFieldContainer
      className={className}
      labelPosition={layout.labelPosition}
      labelColor={layout.labelColor}
      labelSize={layout.labelSize}
      labelWeight={layout.labelWeight}
      borderColor={layout.borderColor}
      inputHeight={layout.inputHeight}
      inputTextColor={layout.inputTextColor}
      inputFontSize={layout.inputFontSize}
      inputFontWeight={layout.inputFontWeight}
      inputBackgroundColor={inputBackgroundColor}
      error={layout.error}
      success={layout.success}
      width={width}
      marginBottom={marginBottom}
    >
      {label?
        <label>
          <span className="highLight">{highLightLabel}</span>
          <span>{label}</span>
          {isRequired? <span className="required"> *</span>: null}
          {infoIcon? <span onMouseOver={onMouseOver} onMouseOut={onMouseOut}><InfoCircle className="infoIcon"></InfoCircle></span>: null}
        </label>
      : null}
      {(iconUrl && iconUrl.length > 0)? <img src={iconUrl} className="icon" alt="check"/>: null}
      <Input
        disableUnderline
        fullWidth={true}
        value={layout.value || ''}
        inputProps={{
          maxLength: maxLength,
        }}
        type={layout.type}
        autoComplete={layout.autoComplete}
        onChange={layout.onChange}
        onBlur={layout.onBlur}
        onFocus={layout.onFocus}
        onKeyDown={layout.onKeyDown}
        inputComponent={layout.inputComponent}
        inputRef={layout.inputRef}
        placeholder={layout.placeholder}
        disabled={layout.disabled}
      />
      {layout.error? <div className="warning">{layout.errorMessage?.length? layout.errorMessage: 'This is a required field.'}</div>: null}
      {layout.success? <div className="success">{layout.successMessage?.length? layout.successMessage: 'Success!'}</div>: null}
    </FormInputFieldContainer>
  );
};

export default FormInputField;