import React, { FC } from 'react';
import ScreenLayout from 'components/ScreenLayout';
import SignInSection from './components/SignInSection';

const SignInScreen: FC = () => (
  <ScreenLayout disableModal>
    <SignInSection />
  </ScreenLayout>
);

export default SignInScreen;
