import React, { FC } from 'react';
import { DatePickersContainer } from './style';
import AdapterMoment from '@material-ui/pickers/adapter/moment';
import { LocalizationProvider } from '@material-ui/pickers';
import SearchBarDatePicker from 'components/SearchBarDatePicker';
import { dateRangeState } from 'states/SearchBarStates/atoms';
import { useRecoilValue } from 'recoil';

const DatePickers: FC = () => {
  
  const dateRange = useRecoilValue(dateRangeState);

  return (
    <DatePickersContainer>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SearchBarDatePicker label="DATES" dateRange={dateRange}/>
      </LocalizationProvider>
    </DatePickersContainer>
  );
};

export default DatePickers;
