import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import setPassword from 'assets/icons/setPassword.svg';

export const SetPasswordScreenContainer = styled(BasicSectionContainer)`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.setPasswordBackground};
    width: 100%;
    height: 346px;
    padding: 0 15px;

    .titleText {
      width: 100%;
      margin: 0 20px 0 0;

      .title {
        font-size: 48px;
        font-weight: bold;
        line-height: 72px;
        text-align: left;
        color: ${colors.white};

        .highLight {
          color: ${colors.checkOutCompleteScreenHighlight};
        }
      }
    }

    .setPasswordImage {
      width: 457px;
      min-width: 457px;
      height: 346px;
      background-image: url(${setPassword});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0 center;
    }
  }

  .inputRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 15px;

    .innerContainer {
      display: flex;
      flex-direction: column;
      width: 515px;
      min-width: 270px;
      padding: 20px 0;

      .buttonContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          position: absolute;
          top: -85px;
          left: 50px;
        }
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        color: ${colors.black};
        margin: 15px 0;

        .highLight {
          font-weight: 600;
        }
      }

      .passwordStrengthBar {
        width: 70%;
      }
    }
  }

  @media only screen and (max-width: 1200px) {

    .innerContainer {
      flex-direction: column-reverse;
      padding: 0;
      margin: 0;
    }

    .titleContainer {
      height: auto;

      .titleText {
        margin: 20px 0;

        .title {
          text-align: center;
          font-size: 36px;
        }

        .description {
          text-align: center;
        }
      }

      .setPasswordImage {
        max-width: 270px;
        min-width: 270px;
        height: 183px;
        margin-top: 20px;
      }
    }
  }
`;