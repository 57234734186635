import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import CheckOutConfirmForm from './components/CheckOutConfirmForm';
import CompleteAlert from 'components/CompleteAlert';
import { useLocation } from 'react-router-dom';

const CheckOutConfirmScreen: React.FC = () => {

  const location = useLocation<{resetToken: string, 
    originalEmail: string, reservationCode: string}>();
  const { state } = location;
  const { resetToken, originalEmail, reservationCode } = state;

  return (
    <ScreenLayout disableModal>
      <CompleteAlert receivedReservationCode={reservationCode} />
      <CheckOutConfirmForm
        resetToken={resetToken}
        originalEmail={originalEmail}
        reservationCode={reservationCode}
      />
    </ScreenLayout>
  )
}

export default CheckOutConfirmScreen
