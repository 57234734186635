import { useEffect } from 'react';

/**
 * A custom hook to smoothly back to top of page
 */
export const useBackToTop: () => void = () => {
  useEffect(() => {
    setTimeout(()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 500)
  }, []);
};
