import styled from "styled-components";
import { BasicSectionContainer } from "../../../style";
import { styleSizes } from "constants/styleSizes";
import { colors } from "constants/colors";

export const PersonalDetails = styled(BasicSectionContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: stretch;
  gap: 32px;
  overflow: visible;
  
  .cardContent {
    .topText {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 30px;
      line-height: 24px;
    }
    .fullName,
    .address {
      max-width: 500px;
    }
    .dateOfBirth {
      max-width: 240px;
      position: relative;
    }
    .inpuntCont {
      margin-bottom: 17px;
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        margin-bottom: 6px;
      }
      label {
        font-size: 14px;
        font-weight: 400;
        color: ${colors.secLabel};
      }
    }
    .saveButton {
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
      }
    }

    .border-arrow {
      position: relative; /* Required for positioning the arrow */
      padding: 20px; /* Space inside the div */
      background: #fff; /* Background color */
      border: 2px solid rgb(3, 141, 227); /* Border color */
      margin: 20px; /* Space around the div */
    }

  }
`;
