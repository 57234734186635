import React, { FC } from 'react';
import {
  CheckBoxContainer,
  CheckBoxesContainer,
} from './style';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import { Icon } from 'components/Icon';
import { isWheelchairAccessibleState } from 'states/SearchBarStates/atoms';
import { useRecoilState } from 'recoil';

const CheckBoxes: FC = () => {

  const [isWheelchairAccessible, setIsWheelchairAccessible] = useRecoilState(isWheelchairAccessibleState);

  const handleWheelchairClick = () => {
    if(isWheelchairAccessible) {
      localStorage.setItem('isWheelchairAccessible', JSON.stringify(false))
      setIsWheelchairAccessible(false)
    } else {
      localStorage.setItem('isWheelchairAccessible', JSON.stringify(true))
      setIsWheelchairAccessible(true)
    }
  }

  return (
    <CheckBoxesContainer>
      <CheckBoxContainer>
        <label>
          <input
            type="checkbox"
            id="isWheelchairAccessible"
            name="isWheelchairAccessible"
            checked={Boolean(isWheelchairAccessible)}
            onChange={handleWheelchairClick}
          />
          <span></span>
        </label>
        <Icon
          className='checkBoxIcon'
          onClick={handleWheelchairClick}
        >
          <AccessibleForwardIcon />
        </Icon>
        <div
          className="checkBoxLabel"
          onClick={handleWheelchairClick}
        >
          Accessible
        </div>
      </CheckBoxContainer>
    </CheckBoxesContainer>
  );
};

export default CheckBoxes;
