import { FC } from 'react'
import { TermsAndConditionsScreenContainer } from './style';

const TermsAndConditionsSection: FC = () => {

  return (
    <TermsAndConditionsScreenContainer>
      <div className="background"></div>
      <div className="innerContainer">
        <div className="content">
          <h1 className="title">Rook Hotel Terms and Conditions</h1>
          <h2 className="subtitle">Website and Check-In Agreement</h2>
          <p>By making a reservation at Rook Hotel and checking in, you agree to the following terms and conditions: </p>
          <h2 className="subtitle">General Terms</h2>
          <h3 className="secondarySubtitle">1. Check-In and Check-Out:</h3>
          <ul>
            <li><span className="highLight">Check-In Time: </span> 3:00 PM</li>
            <li><span className="highLight">Check-Out Time: </span> 11:00 AM</li>
            <li><span className="highLight">Early Check-In: </span> Available for an additional fee of $25, subject to availability.</li>
            <li><span className="highLight">Late Check-Out: </span> Available for an additional fee of $25, subject to availability.</li>
          </ul>
          <h3 className="secondarySubtitle">2. Age Requirement:</h3>
          <ul>
            <li>The primary guest must be at least 19 years old to check in.</li>
          </ul>
          <h3 className="secondarySubtitle">3. Deposit Policy:</h3>
          <ul>
            <li>Upon check-in, a refundable deposit of $200 will be placed as a hold on the guest’s credit card. This hold will be released upon check-out, provided that the room is 
              left in good condition. Any damages or excessive cleaning required will result in the forfeiture of part or all of the deposit.</li>
          </ul>
          <h3 className="secondarySubtitle">4. Pet Policy:</h3>
          <ul>
            <li>No pets are allowed on the premises.</li>
          </ul>
          <h3 className="secondarySubtitle">5. Parking:</h3>
          <ul>
            <li>Parking is available for an additional fee. Please inquire at the front desk for details.</li>    
          </ul>
          <h3 className="secondarySubtitle">6. Meals:</h3>
          <ul>
            <li>Meals are not included in the room rate and are available for an additional fee.</li>
          </ul>
          <h3 className="secondarySubtitle">7. No-Show Policy:</h3>
          <ul>
            <li>If a guest does not cancel their reservation and fails to check in within 24 hours of their scheduled arrival time, a no-show penalty of $100 will be charged to the 
              credit card on file.</li>
          </ul>
          <h2 className="subtitle">Reservation and Cancellation</h2>
          <h3 className="secondarySubtitle">1. Prepayment Plan (10% Discount):</h3>
          <ul>
            <li><span className="highLight">Prepayment Requirement: </span> A non-refundable prepayment of one room night at the discounted rate is required.</li>
            <li><span className="highLight">Cancellation Policy: </span> Cancellations are allowed up to 24 hours before check-in without any further charges. The prepayment is non-refundable.</li>
            <li><span className="highLight">Late Cancellation Penalty: </span> Cancellations within 24 hours of check-in will incur a penalty of one room night at the discounted rate.</li>
          </ul>
          <h3 className="secondarySubtitle">2. Standard Plan (No Prepayment):</h3>
          <ul>
            <li><span className="highLight">No Prepayment Required: </span> Guests pay the full room rate without any deposit.</li>
            <li><span className="highLight">Cancellation Policy: </span> Cancellations are allowed up to 24 hours before check-in without any charges.</li>
            <li><span className="highLight">Late Cancellation Penalty: </span> Cancellations within 24 hours of check-in will not incur a penalty, but the no-show penalty applies if the guest does not check in within 24 hours.</li>
          </ul>
          <h2 className="subtitle">Additional Policies</h2>
          <h3 className="secondarySubtitle">1. Room Condition:</h3>
          <ul>
            <li>Guests are expected to maintain the room in good condition. Any damages or excessive cleaning required will be deducted from the deposit.</li>
          </ul>
          <h3 className="secondarySubtitle">2. Guest Conduct:</h3>
          <ul>
            <li>Guests are expected to conduct themselves in a manner that respects the property and other guests. Any disruptive behavior may result in eviction from the hotel 
              without refund.</li>
          </ul>
          <h3 className="secondarySubtitle">3. Privacy Policy:</h3>
          <ul>
            <li>Rook Hotel respects your privacy. Personal information collected during the reservation process will be used solely for the purpose of managing your booking and 
              enhancing your stay.</li>
          </ul>
          <p className='ending'>By proceeding with your reservation and checking in, you acknowledge and agree to these terms and conditions. Thank you for choosing Rook Hotel. We look forward to 
            making your stay a pleasant one.</p>
        </div>
      </div>
    </TermsAndConditionsScreenContainer>
  )
}

export default TermsAndConditionsSection;