import React, { FC, useState } from "react";
import useWindowSize from "customHooks/useWindowSize";
import {
  guestQuantityState,
  roomQuantityState,
} from "states/SearchBarStates/atoms";
import { useRecoilState } from "recoil";
import { PeopleIcon, DecreaseIcon, IncreaseIcon } from "assets/icons";
import { Icon } from "components/Icon";
import {
  GuestsAndRoomsContainer,
  GuestsAndRoomsOptionsContainer,
} from "./style";
import MobileGuestsAndRoomsOptions from "./MobileGuestsAndRoomsOptions";

const GuestsAndRooms: FC = () => {

  const [guestQuantity, setGuestQuantity] = useRecoilState(guestQuantityState);
  const [roomQuantity, setRoomQuantity] = useRecoilState(roomQuantityState);
  const [isActive, setIsActive] = useState(false);

  return (
    <GuestsAndRoomsContainer>
      <div
        className="inputContainer"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <label>GUESTS & ROOMS</label>
        <input
          value={`${roomQuantity} ${
            roomQuantity > 1 ? "rooms" : "room"
          } - ${guestQuantity} ${guestQuantity > 1 ? "guests" : "guest"}`}
          readOnly
        />
      </div>
      <Icon width={30} height={30}>
        <PeopleIcon
          onClick={() => {
            setIsActive(!isActive);
          }}
        />
      </Icon>
      <GuestsAndRoomsOptionsContainer isActive={isActive}>
        <div className="content">
          <div className="guestsOption">
            <div className="text">
              <div className="optionTitle">GUESTS</div>
              <div className="optionDescription">
                How many people are travelling?
              </div>
            </div>
            <div className="optionButtons">
              <Icon width={30} height={30}>
                <DecreaseIcon
                  className={guestQuantity <= 1? "disabled" : ""}
                  onClick={() => {
                    if(guestQuantity > 1) {
                      localStorage.setItem('guestQuantity', (guestQuantity - 1).toString());
                      setGuestQuantity(guestQuantity - 1);
                      if(roomQuantity > guestQuantity - 1) {
                        localStorage.setItem('roomQuantity', (guestQuantity - 1).toString());
                        setRoomQuantity(guestQuantity - 1);
                      }
                    }
                  }}
                />
              </Icon>
              <div className="quantity">{guestQuantity}</div>
              <Icon width={30} height={30}>
                <IncreaseIcon
                  className={guestQuantity >= 18? "disabled" : ""}
                  onClick={() => {
                    if (guestQuantity < 18) {
                      localStorage.setItem("guestQuantity",(guestQuantity + 1).toString());
                      setGuestQuantity(guestQuantity + 1);
                      if (roomQuantity < Math.ceil((guestQuantity + 1) / 2)) {
                        localStorage.setItem("roomQuantity",Math.ceil((guestQuantity + 1) / 2).toString());
                        setRoomQuantity(Math.ceil((guestQuantity + 1) / 2));
                      }
                    }
                  }}
                />
              </Icon>
            </div>
          </div>
          <div className="roomsOption">
            <div className="text">
              <div className="optionTitle">ROOMS</div>
              <div className="optionDescription">Max 2 guests per room</div>
            </div>
            <div className="optionButtons">
              <Icon width={30} height={30}>
                <DecreaseIcon
                  className={roomQuantity <= Math.ceil(guestQuantity/2)? "disabled": ""}
                  onClick={() => {
                    if(roomQuantity > Math.ceil(guestQuantity/2)) {
                      localStorage.setItem('roomQuantity', (roomQuantity - 1).toString());
                      setRoomQuantity(roomQuantity - 1);
                    }
                  }}
                />
              </Icon>
              <div className="quantity">{roomQuantity}</div>
              <Icon width={30} height={30}>
                <IncreaseIcon
                  className={roomQuantity >= 9? "disabled" : ""}
                  onClick={() => {
                    if(roomQuantity < 9) {
                      localStorage.setItem('roomQuantity', (roomQuantity + 1).toString());
                      setRoomQuantity(roomQuantity + 1);
                      if(roomQuantity===guestQuantity) {
                        localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                        setGuestQuantity(guestQuantity + 1);
                      }
                    }
                  }}
                />
              </Icon>
            </div>
          </div>
        </div>
        <div
          className="outerContainer"
          onClick={() => {
            setIsActive(false);
          }}
        ></div>
      </GuestsAndRoomsOptionsContainer>
      <MobileGuestsAndRoomsOptions open={isActive} setIsOpen={setIsActive} />
    </GuestsAndRoomsContainer>
  );
};

export default GuestsAndRooms;
