import React, { FC, useEffect, useState, Dispatch, SetStateAction } from "react";
import Modal from "components/Modal";
import {
  StaticDateRangePicker,
  DateRangeDelimiter,
} from "@material-ui/pickers";
import {
  DatePickerModalContainer,
  TopDatePickersContainer,
  MiddleCalenderContainer,
  BottomButtonContainer,
  DateInput,
} from "./style";
import { TextField } from "@material-ui/core";
import StyledButton from "components/Button";
import { dateRangeState } from "states/SearchBarStates/atoms";
import { useRecoilState } from "recoil";
import { RangeInput } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import moment from "moment";
import { RedCloseIcon } from "assets/icons";
import {
  DEFAULT_CHECK_IN_HOUR,
  DEFAULT_CHECK_IN_MINUTE,
  DEFAULT_CHECK_OUT_HOUR,
  DEFAULT_CHECK_OUT_MINUTE,
  DEFAULT_CUT_OFF_HOUR,
  DEFAULT_CUT_OFF_MINUTE,
  DEFAULT_MAX_DAYS
} from 'constants/constant';

type DatePickerModalProps = {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

const DatePickerModal: FC<DatePickerModalProps> = ({
  openModal,
  setOpenModal,
}) => {
  const [dateRange, setDateRange] = useRecoilState(dateRangeState);
  const [dateRangeTemp, setDateRangeTemp] = useState(dateRange);
  const [startDate, setStartDate] = useState(moment(dateRangeTemp[0]).format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(moment(dateRangeTemp[1]).format("YYYY-MM-DD"))

  useEffect(() => {
    // persist data range on search bar
    localStorage.setItem("dateRange", JSON.stringify(dateRange));
  }, [dateRange]);

  const handleDone = () => {
    setDateRange(dateRangeTemp);
    setOpenModal(false);
  };

  const minDate = (moment() > moment().set({ hour: DEFAULT_CUT_OFF_HOUR, minute: DEFAULT_CUT_OFF_MINUTE, second: 0 }))? moment().set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 }): moment().subtract(1, "days").set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
  const maxDate = (moment() > moment().set({ hour: DEFAULT_CUT_OFF_HOUR, minute: DEFAULT_CUT_OFF_MINUTE, second: 0 }))? moment().add(DEFAULT_MAX_DAYS, "days").set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 }): moment().add((DEFAULT_MAX_DAYS-1), "days").set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })

  return (
    <Modal open={openModal} top="0px">
      <DatePickerModalContainer>
        <h1 className="datePickerTitle">Travel Dates</h1>
        <div
          className="closeButton"
          onClick={handleDone}
        >
          <RedCloseIcon />
        </div>
        <TopDatePickersContainer>
          <div className="dateInputContainer">
            <DateInput error={dateRangeTemp[0] === null}>
              <label>CHECK IN</label>
              <input
                maxLength={10}
                value={startDate}
                onBlur={() => {
                  if(moment(startDate).format("YYYY-MM-DD") === "Invalid date") {
                    setStartDate(moment(dateRangeTemp[0]).format("YYYY-MM-DD"))
                  }
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
                  let newFormattedDate = '';

                  if (numericValue.length > 0) {
                    newFormattedDate += numericValue.slice(0, 4);
                  }
              
                  if (numericValue.length >= 5) {
                    newFormattedDate += '-' + numericValue.slice(4, 6);
                  }
              
                  if (numericValue.length >= 7) {
                    newFormattedDate += '-' + numericValue.slice(6, 8);
                  }
                  if(moment(newFormattedDate).format("YYYY-MM-DD") !== "Invalid date") {
                    if(moment() > moment().set({ hour: DEFAULT_CUT_OFF_HOUR, minute: DEFAULT_CUT_OFF_MINUTE, second: 0 })
                    ) {
                      if(moment(newFormattedDate) >= moment()
                        && moment(newFormattedDate) <= moment().add(DEFAULT_MAX_DAYS, "days")
                      ) {
                        setDateRangeTemp([
                          moment(newFormattedDate)
                            .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                          dateRangeTemp[1]
                        ])
                      } else {
                        setDateRangeTemp([
                          moment()
                            .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                          dateRangeTemp[1]
                        ])
                      }
                    } else {
                      if(moment(newFormattedDate) >= moment().subtract(1, "days")
                        && moment(newFormattedDate) <= moment().add((DEFAULT_MAX_DAYS-1), "days")
                      ) {
                        setDateRangeTemp([
                          moment(newFormattedDate)
                            .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                          dateRangeTemp[1]
                        ])
                      } else {
                        setDateRangeTemp([
                          moment()
                            .subtract(1, "days")
                            .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                          dateRangeTemp[1]
                        ])
                      }
                    }
                  }
                  setStartDate(newFormattedDate);
                }}
              />
            </DateInput>
            <span style={{ margin: "35px 20px 10px 20px" }}>to</span>
            <DateInput error={dateRangeTemp[1] === null}>
              <label>CHECK OUT</label>
              <input
                maxLength={10} 
                value={endDate}
                onBlur={() => {
                  if(moment(endDate).format("YYYY-MM-DD") === "Invalid date"
                      || moment(endDate) < moment(dateRangeTemp[0])
                      || moment(endDate) > moment().add(DEFAULT_MAX_DAYS, "days")
                  ) {
                    setEndDate(moment(dateRangeTemp[1]).format("YYYY-MM-DD"))
                  }
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
                  let newFormattedDate = '';

                  if (numericValue.length > 0) {
                    newFormattedDate += numericValue.slice(0, 4);
                  }
              
                  if (numericValue.length >= 5) {
                    newFormattedDate += '-' + numericValue.slice(4, 6);
                  }
              
                  if (numericValue.length >= 7) {
                    newFormattedDate += '-' + numericValue.slice(6, 8);
                  }
                  if(moment(newFormattedDate).format("YYYY-MM-DD") !== "Invalid date") {
                    if(moment() > moment().set({ hour: DEFAULT_CUT_OFF_HOUR, minute: DEFAULT_CUT_OFF_MINUTE, second: 0 })) {
                      if(moment(newFormattedDate) <= moment(dateRangeTemp[0])) {
                        setDateRangeTemp([
                          dateRangeTemp[0],
                          moment(dateRangeTemp[0])
                            .add(1, "days")
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        ])
                      } else if(moment(newFormattedDate) <= moment().add(DEFAULT_MAX_DAYS, "days")) {
                        setDateRangeTemp([
                          dateRangeTemp[0],
                          moment(newFormattedDate)
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        ])
                      } else {
                        setDateRangeTemp([
                          dateRangeTemp[0],
                          moment()
                            .add(DEFAULT_MAX_DAYS, "days")
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        ])
                        setEndDate(
                          moment()
                            .add(DEFAULT_MAX_DAYS, "days")
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DD')
                        )
                      }
                    } else {
                      if(moment(newFormattedDate) <= moment(dateRangeTemp[0])) {
                        setDateRangeTemp([
                          dateRangeTemp[0],
                          moment(dateRangeTemp[0])
                            .add(1, "days")
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        ])
                      } else if(moment(newFormattedDate) <= moment().add((DEFAULT_MAX_DAYS-1), "days")) {
                        setDateRangeTemp([
                          dateRangeTemp[0],
                          moment(newFormattedDate)
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        ])
                      } else {
                        setDateRangeTemp([
                          dateRangeTemp[0],
                          moment()
                            .add((DEFAULT_MAX_DAYS-1), "days")
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
                        ])
                        setEndDate(
                          moment()
                            .add((DEFAULT_MAX_DAYS-1), "days")
                            .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                            .utc()
                            .format('YYYY-MM-DD')
                        )
                      }
                    
                    }
                  }
                  setEndDate(newFormattedDate);
                }}
              />
            </DateInput>
          </div>
        </TopDatePickersContainer>
        <MiddleCalenderContainer>
          <StaticDateRangePicker
            className="desktopDatePicker"
            displayStaticWrapperAs="desktop"
            minDate={minDate}
            maxDate={maxDate}
            renderInput={(startProps, endProps) => (
              <>
                <TextField
                  {...startProps}
                  value={moment(dateRangeTemp[0]).format("YYYY-MM-DD")}
                />
                <div className="">
                  <DateRangeDelimiter placeholder="" > to </DateRangeDelimiter>
                </div>
                <TextField
                  {...endProps}
                  value={moment(dateRangeTemp[1]).format("YYYY-MM-DD")}
                />
              </>
            )}
            onChange={(newRange) => {
              setDateRangeTemp([
                moment(newRange[0])
                  .set({ hour: DEFAULT_CHECK_IN_HOUR, minute: DEFAULT_CHECK_IN_MINUTE, second: 0 })
                  .utc()
                  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                moment(newRange[1])
                  .set({ hour: DEFAULT_CHECK_OUT_HOUR, minute: DEFAULT_CHECK_OUT_MINUTE, second: 0 })
                  .utc()
                  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
              ]);
              setStartDate(moment(newRange[0]).format("YYYY-MM-DD"))
              setEndDate(moment(newRange[1]).format("YYYY-MM-DD"))
            }}
            value={dateRangeTemp as RangeInput<any>}
          />
        </MiddleCalenderContainer>
        <BottomButtonContainer>
          <StyledButton
            className="searchBarButton"
            width="100px"
            height="49px"
            fontSize={18}
            disabled={
              moment(dateRangeTemp[0]) >= moment(dateRangeTemp[1]) ||
              moment(dateRangeTemp[0]).toString() === 'Invalid date' ||
              moment(dateRangeTemp[1]).toString() === 'Invalid date'
            }
            onClick={handleDone}
          >
            Done
          </StyledButton>
        </BottomButtonContainer>
      </DatePickerModalContainer>
    </Modal>
  );
};

export default DatePickerModal;
