import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { paymentInfoState } from 'states/CheckOutStates/atoms'

export const usePaymentForm = (creditCardType: string) => {

  const paymentInfo = useRecoilValue(paymentInfoState)

  const [paymentError, setPaymentError] = useState({
    cardHolderNameError: false,    
    creditCardNumberError: false,
    cardExpirationDateError: false,
    cardCVVError: false,
    billingAddressError: false,
  })

  const isValidatePaymentInfo = () => {
    let result = true

    if (!creditCardType.length) {
      setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: true}))
      result = false
    } else {
      if(paymentError.creditCardNumberError) {
        setPaymentError((paymentError) => ({...paymentError, creditCardNumberError: false}))
      }
    }

    if (paymentInfo.cardHolderName.length < 3) {
      setPaymentError((paymentError) => ({...paymentError, cardHolderNameError: true}))
      result = false
    } else {
      if(paymentError.cardHolderNameError) {
        setPaymentError((paymentError)=>({...paymentError, cardHolderNameError: false}))
      }
    }

    if (paymentInfo.cardExpirationDate.length < 5) {
      setPaymentError((paymentError) => ({...paymentError, cardExpirationDateError: true}))
      result = false
    } else {
      if(paymentError.cardExpirationDateError) {
        setPaymentError((paymentError) => ({...paymentError, cardExpirationDateError: false}))
      }
    }

    if (paymentInfo.cardCVV.length < 3) {
      setPaymentError((paymentError) => ({...paymentError, cardCVVError: true}))
      result = false
    } else {
      if(paymentError.cardCVVError) {
        setPaymentError((paymentError) => ({...paymentError, cardCVVError: false}))
      }
    }

    if (paymentInfo.billingAddress.length < 3 ) {
      setPaymentError((paymentError) => ({...paymentError, billingAddressError: true}))
      result = false
    } else {
      if(paymentError.billingAddressError) {
        setPaymentError((paymentError) => ({...paymentError, billingAddressError: false}))
      }
    }

    return result
  }

  return {
    isValidatePaymentInfo,
    paymentError,
    setPaymentError
  }
}
