import styled from 'styled-components';
import { colors } from 'constants/colors';

export const DatePickerContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    position: absolute;
    right: 13px;
    top: 50%;
  }
`;

export const SearchBarDateInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  label {
    font-size: 14px;
    height: 21px;
    line-height: 21px;
    font-weight: 600;
    color: ${colors.black};
    margin-bottom: 7px;
  }

  input {
    width: 100%;
    height: 43px;
    position: relative;
    font-size: 18px;
    color: ${colors.userSelectionBlack};
    cursor: pointer;
    pointer-events: none;
    border: 1px solid ${colors.signInBorderColor};
    padding: 0 13px;

    &:focus {
      outline: none;
    }
  }
`;
