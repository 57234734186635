import styled from 'styled-components';
import { colors } from 'constants/colors';

export const FooterMapContainer = styled.div`
  width: 100%;
  height: 500px;
  background-color: ${colors.black};
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
`;

export const FooterInfoContainer = styled.div`
  position: relative;
  top: 10px;
  right: 120px;
  width: 240px;
  height: 160px;
  background-color: ${colors.black};
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 40px;

  .footerDescription {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    color: ${colors.white};
  }

  a {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: ${colors.hoverBlue};
  }
`;
