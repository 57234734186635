import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import FrequentlyAskedQuestionsSection from './components/FrequentlyAskedQuestionsSection';

const FrequentlyAskedQuestionsScreen: React.FC = () => {

  return (
    <ScreenLayout disableModal>
      <FrequentlyAskedQuestionsSection />
    </ScreenLayout>
  )
}

export default FrequentlyAskedQuestionsScreen
