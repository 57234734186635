import React, { useState } from 'react';
import FormInputField from 'components/FormInputField';
import StyledButton from 'components/Button';
import { SignInModalContainer } from './style';
import { CrossIcon } from 'assets/icons';
import { colors } from 'constants/colors';
import { useAuth } from 'customHooks/useAuth'

type SignInModalBodyProps = {
  setOpenSignInModal: React.Dispatch<React.SetStateAction<boolean>>;
  emailInput: string;
}

const SignInModalBody: React.FC<SignInModalBodyProps> = ({ setOpenSignInModal, emailInput  }) => {

  const { userSignIn } = useAuth()
  const [email, setEmail] = useState(emailInput)
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  const signIn = () => {

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
      && password.length >= 8
    ) {
      setEmailError(false)
      setPasswordError(false)
      userSignIn(email, password, '/profile');
    } else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setEmailError(true)
      }
  
      if (password.length < 8) {
        setPasswordError(true)
      }

      const content = 'Invalid password or email'
      console.log(content)
    }
  }

  return (
    <SignInModalContainer>
      <div
        className="closeButton"
        onClick={() => {
          setOpenSignInModal(false);
        }}>
        <CrossIcon />
      </div>
      <div className="largeTitle">Sign in</div>
      <FormInputField
        className='input'
        label="EMAIL"
        labelPosition='center'
        borderColor={colors.modalTextColor}
        error={emailError}
        errorMessage={'Please enter a valid email address and try again.'}
        inputHeight="40px"
        marginBottom={'5px'}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value)
          if(emailError) {
            setEmailError(false)
          }
        }}
      />
      <FormInputField
        className='input'
        label="PASSWORD"
        type="password"
        autoComplete="new-password"
        labelPosition='center'
        borderColor={colors.modalTextColor}
        error={passwordError}
        errorMessage={'Please enter a valid password'}
        inputHeight="40px"
        marginBottom={'5px'}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value)
          if(passwordError) {
            setPasswordError(false)
          }
        }}
      />
      <div className='buttonContainer'>
        <StyledButton
          width="102px"
          height="59px"
          onClick={signIn}
        >
          Sign in
        </StyledButton>        
      </div>
    </SignInModalContainer>
  );
};

export default SignInModalBody;
