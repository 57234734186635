import React, { FC, useState } from 'react'
import FormInputField from 'components/FormInputField'
import PhoneNumberInput from 'components/PhoneNumberInput'
import StyledButton from 'components/Button'
import { EmailIcon, PhoneIcon } from 'assets/icons'
import { useMutation } from '@apollo/client'
import { SetPasswordScreenContainer } from './style';
import { FORGOT_PASSWORD } from 'graphql/service';

const ResetSection: FC = () => {

  const [retrieved, setRetrieved] = useState(false)
  const [option, setOption] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState('Please enter a valid email address and try again.')
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('Please enter a valid phone number and try again.')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)

  const [forgotPassword, { data, loading, error }] = useMutation(
    FORGOT_PASSWORD,
    {
      onCompleted: (data) => {
        if(data.forgotPassword.userId) {
          setRetrieved(true)
          console.log('Password reset link sent to you successfully')
        } else {
          const content = 'Password reset failed!'
          console.log(content)
        }
      },
      onError: (error) => {
        if(error.graphQLErrors[0]?.extensions?.code==='NOT_FOUND') {
          setEmailError(true)
          setPhoneNumberError(true)
          setEmailErrorMessage('The email address could not found.')
          setPhoneNumberErrorMessage('The phone number could not found.')
        } else {
          const content = error.message
          console.log(content)
        }
      }
    }
  )

  const handleRetrieve = () => {
    if(option==='phone') {
      if(phoneNumber.length>0 && phoneNumberValidity) {
        setPhoneNumberError(false)
        forgotPassword({
          variables: {
            input: {
              login: phoneNumber
            }
          }
        })
      } else if(phoneNumber.length===0) {
        setPhoneNumberError(true)
        setPhoneNumberErrorMessage('This field is required.')
      } else {
        setPhoneNumberError(true)
        setPhoneNumberErrorMessage('Please enter a valid phone number and try again.')
      }
    } else if(option==='email') {
      if(email.length>0 && email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
        setEmailError(false)
        forgotPassword({
          variables: {
            input: {
              login: email
            }
          }
        })
      } else if(email.length===0) {
        setEmailError(true)
        setEmailErrorMessage('This field is required.')
      } else {
        setEmailError(true)
        setEmailErrorMessage('Please enter a valid email address and try again.')
      }
    } else {
      return
    }
  }

  return (
    <SetPasswordScreenContainer>
      <div className="titleContainer">
        {!retrieved? (
          <div className="innerContainer">
            <div className='titleText'>
              <div className="title">
                <div>What a disaster!</div>
                <div>Forgot <span className='highLight'>your password?</span></div>
              </div>
            </div>
            <div className="forgotPasswordImage" />
          </div>
        ):(
          <div className="innerContainer">
            <div className='titleText'>
              <div className="title">
                <div>Disaster averted!</div>
                <div>We just sent you <span className='highLight'>{option==='phone'? 'a text': 'an email'}</span>.</div>
              </div>
            </div>
            <div className="passwordRetrievedImage" />
          </div>
        )}
      </div>
      <div className="inputRow">
        {retrieved? (
          <div className="innerContainer">
            <div className='retrievedContainer'>
              {option==='phone'? (
                <div className="contactText">
                  We've sent you a text to <span className='highLight'>{phoneNumber}</span> with instructions to reset your password. 
                </div>
              ):(
                <div className="contactText">
                  We've sent you an email to <span className='highLight'>{email}</span> with instructions to 
                  reset your password. If you don’t see it in your inbox, please remember to check your <span className='description'>spam folder</span>.
                </div>
              )}
            </div>
            <div className='contactContainer'>
              <div className="contactText">
                Still didn’t receive the text? No problem.
              </div>
            </div>
            <div className='buttonContainer'>
              <StyledButton
                width="119px"
                height="48px"
                margin='16px 59px 16px 59px'
                onClick={handleRetrieve}
              >
                Resend
              </StyledButton>
            </div>
            <div className='retrievedContainer'>
              <div
                className="link"
                onClick={() => {
                  setRetrieved(false)
                  setOption('')
                }}
              >
                Try Different Method
              </div>  
            </div>
          </div>
        ): option.length===0? (
          <div className="innerContainer">
            <div className="description">
              It’s okay, it happens. How would you like to receive a link to reset your password?
            </div>
            <div className='buttonContainer'>
              <StyledButton
                width="110px"
                height="48px"
                margin={'10px 0 0 0'}
                onClick={() => setOption('email')}
                startIcon={<EmailIcon />}
              >
                Email
              </StyledButton>
              <StyledButton
                width="110px"
                height="48px"
                margin={'10px 0 0 0'}
                onClick={() => setOption('phone')}
                startIcon={<PhoneIcon />}
              >
                Phone
              </StyledButton>
            </div>
            <div className="contactContainer">
              <div className="contactText">
                <div>
                  Can’t remember your email or phone? 
                </div>
                <div>
                  <span>Contact us </span>
                  <a href="tel:+19059494000">(905) 949-4000</a>
                </div>
              </div>
            </div>
          </div>
        ): (
          <div className="innerContainer">
            <div className="description">
              {option==='phone'? 'Please enter the phone number associated with this account, and we’ll send you a link to reset your password.'
                :'Please enter the email address associated with this account, and we’ll send you a link to reset your password.'}
            </div>
            {option==='phone'? (
              <PhoneNumberInput
                className='phoneNumberInput'
                label="PHONE"
                isRequired
                labelPosition='left'
                error={!phoneNumberValidity && phoneNumberError}
                errorMessage={phoneNumberErrorMessage}
                width='340px'
                inputHeight="48px"
                marginBottom='0'
                onChangeNumber={setPhoneNumber}
                setPhoneNumberValidity={setPhoneNumberValidity}
                onBlur={() => {
                  if(!phoneNumberValidity&&phoneNumber.length<3) {
                    setPhoneNumberError(true)
                  }
                }}
              />
            ):(
              <FormInputField
                isRequired
                label="EMAIL ADDRESS"
                width='340px'
                error={emailError}
                errorMessage={emailErrorMessage}
                type="email"
                marginBottom='0'
                inputHeight='48px'
                onChange={(e) => {
                  setEmail(e.target.value)
                  if(emailError) {
                    setEmailError(false)
                  }
                }}
                value={email}
              />
            )}
            <div className='buttonContainer'>
              <StyledButton
                width="160px"
                height="48px"
                margin={(emailError || phoneNumberError)? '57px 39px 0 39px':'32px 39px 0 39px'}
                onClick={handleRetrieve}
              >
                Reset Password
              </StyledButton>
            </div>
          </div>
        )}
      </div>
    </SetPasswordScreenContainer>
  )
}

export default ResetSection
