import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpScreenContainer } from './style';
import { HelpSupport, HelpContact, HelpCancel, HelpFaq, HelpAgreeTerms } from 'assets/icons';
import { Icon } from 'components/Icon';

const HelpSection: FC = () => {

  const history = useHistory();

  return (
    <HelpScreenContainer>
      <div className="background"></div>
      <div className="innerContainer">
        <div className="content">
          <h1 className="commonTitle">Help</h1>
          <div className="contOne">
            <div className="contTwo">
              <div
                className="contentBox"
                onClick={() => {
                  history.push("/termsAndConditions");
                }}
              >
                <div className="contentBoxTitle">
                  <Icon width={30} height={35}>
                    <HelpAgreeTerms />
                  </Icon>
                  Terms and Conditions
                </div>
                <div className="contentBoxContent">
                  Our Terms and Conditions outline your rights and
                  responsibilities. Understanding them ensures a smooth journey
                  with us.&nbsp;
                  <span
                    className="contentBoxLink"
                    onClick={() => {
                      history.push("/termsAndConditions");
                    }}
                  >
                    Read more
                  </span>
                </div>
              </div>
              <div
                className="contentBox"
                onClick={() => {
                  history.push("/faq");
                }}
              >
                <div className="contentBoxTitle">
                  <Icon width={36} height={26}>
                    <HelpFaq />
                  </Icon>
                  FAQ
                </div>
                <div className="contentBoxContent">
                  Find answers to common queries: Our FAQ section is here to
                  assist you every step of the way. Explore now for quick
                  solutions.&nbsp;
                  <span
                    className="contentBoxLink"
                    onClick={() => {
                      history.push("/faq");
                    }}
                  >
                    Read more
                  </span>
                </div>
              </div>
              <div
                className="contentBox"
                onClick={() => {
                  history.push("/faq");
                }}
              >
                <div className="contentBoxTitle">
                  <Icon width={32} height={29}>
                    <HelpCancel />
                  </Icon>
                  Billing
                </div>
                <div className="contentBoxContent">
                  Understand our guidelines for making changes to your bookings.
                  We prioritize your satisfaction in every step.&nbsp;
                  <span
                    className="contentBoxLink"
                    onClick={() => {
                      history.push("/faq");
                    }}
                  >
                    Read more
                  </span>
                </div>
              </div>
              <div
                className="contentBox"
                onClick={() => {
                  history.push("/contact");
                }}
              >
                <div className="contentBoxTitle">
                  <Icon width={38} height={30}>
                    <HelpContact />
                  </Icon>
                  Contact Us
                </div>
                <div className="contentBoxContent">
                  Whether you have questions, feedback, or need assistance, our
                  dedicated team is here to support you.&nbsp;
                  <span
                    className="contentBoxLink"
                    onClick={() => {
                      history.push("/contact");
                    }}
                  >
                    Read more
                  </span>
                </div>
              </div>
            </div>
            <div className="contentBoxGreen">
              <Icon width={59} height={55}>
                <HelpSupport />
              </Icon>
              <h2 className="contentBoxGreenTitle">Need Support?</h2>
              <div className="contentBoxGreenTextOne">
                Can't find the answer you're looking for? <br />
                Don't worry we're here to help!
              </div>
              <div className="contentBoxGreenTextTwo">
                Get in touch with us anytime
              </div>
              <div className="contentBoxGreenPhone">(905) 949-4000</div>
            </div>
          </div>
        </div>
      </div>
    </HelpScreenContainer>
  );
}

export default HelpSection;