import React from 'react';
import { useHistory } from 'react-router-dom';
import ScreenLayout from 'components/ScreenLayout';
import AddOnOptionsContent from './components';
import MobileFooter from 'components/MobileFooter';

const AddOnOptionsScreen: React.FC = () => {

  const history = useHistory();

  const handleContinueBooking = () => {
    history.push('/checkOut');
  }

  return (
    <ScreenLayout>
      <AddOnOptionsContent />
      <MobileFooter handleContinueBooking={handleContinueBooking} showAddOn/>
    </ScreenLayout>
  );
}

export default AddOnOptionsScreen;
