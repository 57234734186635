import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import CheckOutContent from './components';

const CheckOutScreen: React.FC = () => (
  <ScreenLayout>
    <CheckOutContent />
  </ScreenLayout>
);

export default CheckOutScreen;

