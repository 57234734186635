import styled from "styled-components";
import { colors } from "constants/colors";
import { styleSizes } from "constants/styleSizes";

export const AccountMenuBarContainer = styled.div`
  width: 260px;
  min-width: 260px;
  -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
  border: 1px solid ${colors.accounMenuBorderColor};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${colors.white};

  @media (max-width: ${styleSizes.tabletBreakpoin}) {
    width: 128px;
    min-width: 128px;
    gap: 1px;
  }

  @media (max-width: ${styleSizes.miniBreakpoint}) {
    flex-direction: row;
    width: auto;
    min-width: auto;
    flex: 1;
    padding-left: 33px;
    padding-right: 33px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 100px;
  }

  @media (max-width: ${styleSizes.mobileBreakpoint}) {
    padding-left: 14px;
    padding-right: 14px;
    height: 100px;
  }

  .accountMenuItem {
    width: 100%;
    display: flex;
    height: 60px;
    padding: 17px 12px 16px 24px;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    border: 1px solid ${colors.white};

    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      flex-direction: column;
      height: 76px;
      align-items: center;
      justify-content: center;
      padding: unset;
    }

    @media (max-width: ${styleSizes.miniBreakpoint}) {
      justify-content: flex-end;
    }

    .accountMenuIcon {
      margin-right: 16px;
      @media (max-width: ${styleSizes.tabletBreakpoin}) {
        margin-right: 0;
      }
    }

    span {
      font-size: 18px;
      height: 27px;
      line-height: 27px;
      font-weight: 400;
      color: ${colors.black};
      text-decoration: none;
      @media (max-width: ${styleSizes.tabletBreakpoin}) {
        font-size: 14px;
      }
    }
  }

  .accountSubMenu {
    padding: 17px 12px 16px 64px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      padding: unset;
    }

    @media (max-width: ${styleSizes.miniBreakpoint}) {
      display: none;
    }
  }

  .active {
    background-color: ${colors.lightBlueTransp};
    border: 1px solid ${colors.hoverBlue};
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      //padding: 10px;
      background-color: ${colors.white};
      border: none;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {

    }
    color: ${colors.hoverBlue};
    span {
      color: ${colors.hoverBlue};
    }
    .accountMenuIcon {
      color: ${colors.hoverBlue};
      filter: invert(26%) sepia(96%) saturate(2923%) hue-rotate(182deg)
        brightness(94%) contrast(101%);
    }
  }

  .buttonHelp {
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      display: none;
    }
  }

  .iconDashboard {
    width: 24px;
    height: 24px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 26px;
      height: 26px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 27px;
      height: 27px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 32px;
      height: 32px;
    }
  }

  .iconPersonalDetailsIcon {
    width: 24px;
    height: 23px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 24px;
      height: 23px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 24px;
      height: 23px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 29px;
      height: 27px;
    }
  }

  .iconContactInformation {
    width: 26px;
    height: 20px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 26px;
      height: 20px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 26px;
      height: 20px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 26px;
      height: 20px;
    }
  }

  .iconPersonalEmailIcon {
    width: 24px;
    height: 16px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 24px;
      height: 16px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 24px;
      height: 16px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 24px;
      height: 16px;
    }
  }

  .iconPersonalPhoneIcon {
    width: 24px;
    height: 28px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 20px;
      height: 24px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 20px;
      height: 24px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 20px;
      height: 24px;
    }
  }

  .iconBookingsIcon {
    width: 25px;
    height: 21px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 25px;
      height: 21px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 24px;
      height: 23px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 34px;
      height: 32px;
    }
  }

  .iconBillingIcon {
    width: 24px;
    height: 15px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 34px;
      height: 23px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 34px;
      height: 23px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 46px;
      height: 31px;
    }
  }

  .iconSecurityIcon {
    width: 24px;
    height: 30px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 19px;
      height: 24px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 19px;
      height: 24px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 22px;
      height: 27px;
    }
  }

  .iconHelpIcon {
    width: 24px;
    height: 30px;
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
      width: 24px;
      height: 24px;
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
      width: 23px;
      height: 23px;
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      width: 23px;
      height: 23px;
    }
  }
`;
