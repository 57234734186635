import React, { FC } from 'react';
import ScreenLayout from 'components/ScreenLayout';
import ContactInfoSection from './components/ContactInfoSection';

const ContactScreen: FC = () => (
  <ScreenLayout>
    <ContactInfoSection />
  </ScreenLayout>
);

export default ContactScreen;
