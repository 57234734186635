import React from "react";
import { AccountModalContainer } from "./style";
import { CrossIcon } from "assets/icons";
import Modal from "components/Modal";
import { useState, useEffect  } from 'react';

export type AccountModalProps = {
  open: boolean;
  children: JSX.Element;
  opacity?: string;
  top?: string;
  mobile?: boolean;
  verticalCenterd?: boolean;
  title: string;
  onClose?: () => void;  // Add a callback to notify parent
};


const AccountModal: React.FC<AccountModalProps> = ({
  open,
  children,
  opacity,
  top,
  mobile,
  verticalCenterd,
  title,
  onClose  // Accept the callback from parent
}) => {

    const [modalOpen, setModalOpen] = useState(open); // Local state to control modal

    // Sync local state with the `open` prop
    useEffect(() => {
      setModalOpen(open);
    }, [open]);
  
  // Function to handle close action when cross icon is clicked
  const handleClose = () => {
    setModalOpen(false);
    if (onClose) {
      onClose();  // Notify parent that modal should close
    }
  };


  return (
    <Modal
            open={modalOpen}
            opacity={opacity}
            top={top}
            mobile={mobile}
            verticalCenterd={verticalCenterd}
    >
      <AccountModalContainer
      >
        <div
          className="closeButton"
          onClick={handleClose}
        >
          <CrossIcon />
        </div>
        <h3 className="accounModalHeader">{title}</h3>
        {children}
      </AccountModalContainer>
    </Modal>
  );
};

export default AccountModal;
