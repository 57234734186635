import React, { FC } from 'react';
import ScreenLayout from 'components/ScreenLayout';
import SignUpSection from './components/SignUpSection';

const SignUpScreen: FC = () => (
  <ScreenLayout disableModal>
    <SignUpSection />
  </ScreenLayout>
);

export default SignUpScreen;
