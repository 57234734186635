import React, { FC, useState } from 'react';
import { SecurityContentContainer } from './style';
import FormInputField from 'components/FormInputField';
import StyledButton from 'components/Button';
import PasswordStrengthBar from 'components/PasswordStrengthBar';
import { useMutation } from '@apollo/client';
import { UPDATE_PASSWORD_BY_USER_ID, FORGOT_PASSWORD} from 'graphql/service';
import Card from 'components/Card';
import { HiddenIcon, VisibleIcon } from 'assets/icons';
import { colors } from 'constants/colors';
import { PasswordToolTip } from 'assets/icons'
import { PasswordToolTipRt } from 'assets/icons'

import { useRecoilValue } from 'recoil';
import { userInfoState} from 'states/CheckOutStates/atoms'

import AlertMessage from 'components/AlertMessage';


const debug = false;
// if(debug) {} for console.log



if (debug) {
  console.log (userInfoState);
}


const SecurityContent: FC = () => {

  const userId = localStorage.getItem('userId')
  
  const userInfo = useRecoilValue(userInfoState); 

  const userEmail = userInfo.emailInput;


if (debug) {
  console.log (userEmail);
}
  
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [errorMessageNewPass, setErrorMessageNewPass] = useState('');

  const [oldPasswordHidden, setOldPasswordHidden] = useState(true);
  const [newPasswordHidden, setNewPasswordHidden] = useState(true);
  const [errorMessageOldPass, setErrorMessageOldPass] = useState('');

  const [newPasswordSucsess, setNewPasswordSucsess] = useState(false);
  const [sucsessMessageNewPass, setSucsessMessageNewPass] = useState('');

  const [sowResetPasswordHint, setSowResetPasswordHint] = useState(false);


  const [sucsessMessageForgotPasswordText, setSucsessMessageForgotPasswordText] = useState('');
  const [errorMessageForgotPasswordText, setErrorMessageForgotPasswordText] = useState('');

  const [sucsessMessageForgotPassword, showSucsessMessageForgotPassword] = useState(false);
  const [errorMessageForgotPassword, setErrorMessageForgotPassword] = useState(false);


  const [saveSucsessMessage, setSaveSucsessMessage] = useState(false);
  const [saveSucsessMessageText, setSaveSucsessMessageText] = useState('Your password has been successfully updated.');


//  const [passwordEqualError, setPasswordEqualError] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  

  const [updatePasswordByUserId] = useMutation(UPDATE_PASSWORD_BY_USER_ID, {
    onCompleted: (data) => {
      if (data.changePassword?.id) {
        setSucsessMessageNewPass("Password updated successfully!");
        setNewPasswordSucsess(true);
        setSaveSucsessMessage (true);
        setSowResetPasswordHint(false);
        if (debug) {
          console.log("Password updated successfully!");
          console.log(data);
        }
      } else {
        const content = "Reset password failed!";
        if (debug) {
          console.log(data);
        }
        setErrorMessageOldPass("Reset password failed!");
        setOldPasswordError(true);
      }
    },
    onError: (error) => {
      const content = error.message;

      if (error.message == "Passwords don't match") {
        // backend return "Passwords don't match" for Incorrect password case.
        setErrorMessageOldPass("Incorrect password. Please try again.");
        setOldPasswordError(true);
        setSowResetPasswordHint(true);
      } else {
        setErrorMessageOldPass(content);
        setOldPasswordError(true);
      }

      if (debug) {
        console.log(error);
      }
    },
  });

  const [forgotPasswordByUserId] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      if (data.forgotPassword?.userId) {
        setSucsessMessageForgotPasswordText("Password reset link was sent by e-mail.");
        showSucsessMessageForgotPassword(true);
        if (debug) {
          console.log("Password reset link was sent bu e-mail.");
          console.log(data);
        }
      } else {
        if (debug) {
          console.log(data);
        }
        setErrorMessageForgotPasswordText("Password reset link sending failed, please contact hotel administration.");
        setErrorMessageForgotPassword(true);
      }
    },
    onError: (error) => {

      setErrorMessageForgotPasswordText("Password reset link sending failed, please contact hotel administration.");
      setErrorMessageForgotPassword(true);

      if (debug) {
        console.log(error);
      }
    },
  });

  //const error1 = 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character';
  //const error2 = 'Please enter a valid password';
  

  const handleForgotPassword = () => {

    showSucsessMessageForgotPassword(false)
    setErrorMessageForgotPassword(false)
    setSowResetPasswordHint (false)
      forgotPasswordByUserId({
        variables: {
          userId,
          input: {
            login: userEmail
          }
        }
      })
  }


  const handleResetPassword = () => {

    setSaveSucsessMessage (false);
    setNewPasswordSucsess(false);

    if(newPassword.length < 8 ) {
      setErrorMessageNewPass ('Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character')
      setNewPasswordError(true)
    } else if(oldPassword.length < 8) {
      setErrorMessageOldPass('Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character')
      setOldPasswordError(true)
    } else if(newPassword === oldPassword) {
      setErrorMessageNewPass ('New Password must be different from old password')
      setNewPasswordError(true)
    } else {
      setNewPasswordError(false)
      setOldPasswordError(false)
//      setPasswordEqualError(false)
      updatePasswordByUserId({
        variables: {
          userId,
          input: {
            currentPassword: oldPassword,
            newPassword
          }
        }
      })
    }
  }

  return (
    <SecurityContentContainer>
            <AlertMessage
                showMessage={saveSucsessMessage}
                //messageType = 'error'
                onClose={() => setSaveSucsessMessage(false)} // Changing state on close
            >
                {saveSucsessMessageText}
            </AlertMessage>


      <div className="accountBody">
        <div className="cardContent">
        <h2 className="secHeader">
          Change Password
        </h2>
          <div className='description'>
          For your account security, your existing password is required to make any changes. Please choose a new password that is at least 8 characters long. While there are no other restrictions, we encourage you to use a longer passphrase for added security. A passphrase is easier to remember and provides better protection against unauthorized access.          </div>
          <div className='passwordContainer'>
            <div className="inpuntCont">
            <FormInputField
              className='secInput'
              highLightLabel='CURRENT '
              label="PASSWORD"
              isRequired
              type={oldPasswordHidden ? 'password' : 'text'}
              borderColor={colors.signInBorderColor}
              error={oldPasswordError}
              errorMessage={errorMessageOldPass}
              value={oldPassword}
              inputHeight="48px"
              marginBottom="20px"
              onChange={(e) => {
                setOldPassword(e.target.value)
                setNewPasswordSucsess (false)
                if(oldPasswordError) {
                  setOldPasswordError(false)
                  setNewPasswordSucsess (false)
                  setSowResetPasswordHint(false)
                }
              }}
            />
              {oldPasswordHidden? (
              <div
                className='oldPasswordLock'
                onClick={() => {
                  setOldPasswordHidden(false)
                }}
              >
                <HiddenIcon />
              </div>
            ): (
              <div
                className='oldPasswordUnlock'
                onClick={() => {
                  setOldPasswordHidden(true)
                }}
              >
                <VisibleIcon />
              </div>
            )}
            </div>
            {sowResetPasswordHint? (
              <div className="passResetPhrase">
                If you have forgotten your current password, we can <a onClick={handleForgotPassword}>send a password reset link to your email.</a>
              </div>

            ): (
              null
            )}

              {errorMessageForgotPassword? (
                <div className="errorMessageForgotPassword">{errorMessageForgotPasswordText}</div>
              ):
                (null)
              }

              {sucsessMessageForgotPassword? (
                <div className="sucsessMessageForgotPassword">{sucsessMessageForgotPasswordText}</div>
              ):
                (null)
              }

            <div className="inpuntCont">
            <FormInputField
             className='secInput'
              highLightLabel='NEW '
              label="PASSWORD"
              isRequired
              type={newPasswordHidden ? 'password' : 'text'}
              borderColor={colors.signInBorderColor}
              error={newPasswordError}
              errorMessage={errorMessageNewPass}
              value={newPassword}
              success = {newPasswordSucsess}
              successMessage ={sucsessMessageNewPass}
              inputHeight="48px"
              marginBottom="20px"
              onChange={(e) => {
                setNewPassword(e.target.value)
                setNewPasswordSucsess (false)
                
                if(newPasswordError) {
                  setNewPasswordError(false)
                }
              }}
            />
              {newPasswordHidden? (
              <div
                className='newPasswordLock'
                onClick={() => {
                  setNewPasswordHidden(false)
                }}
              >
                <HiddenIcon />
              </div>
            ): (
              <div
                className='newPasswordUnlock'
                onClick={() => {
                  setNewPasswordHidden(true)
                }}
              >
                <VisibleIcon />
              </div>
            )}
            </div>


            <PasswordStrengthBar
              className="passwordStrengthBar"
              password={newPassword}
            />
          </div>
          <div
            className='buttonWithTooltip'
            onMouseEnter={()=>setIsHovered(true)}
            onMouseLeave={()=>setIsHovered(false)}
          >

            <StyledButton
              width="160px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor = {colors.signInBorderColor}
              disabledColor = {colors.white}
              disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={handleResetPassword}
            >
              Update Password
              {isHovered&&(newPassword.length < 8)? <PasswordToolTip className="tool toolLt" />: null}
              {isHovered&&(newPassword.length < 8)? <PasswordToolTipRt className="tool toolRt" />: null}
            </StyledButton>
            
          </div>
        </div>
      </div>
    </SecurityContentContainer>
  );
};

export default SecurityContent;
