/* eslint-disable react/react-in-jsx-scope */
import { FC } from 'react'
import {
  ContactInfoSectionContainer,
  InfoSectionContainer,
  MapSectionContainer,
} from './style'

const ContactInfoSection: FC = () => {

  return (
    <ContactInfoSectionContainer>
      <div className="innerContainer">
        <InfoSectionContainer>
          <div className="info">
            <div className="infoTitle">Rook Hotel</div>
            <div className="infoDescription">
              <div>3660 Hurontario St.</div>
              <div>Mississauga, ON </div>
              <div>L5B 3C4 </div>
              <div><a href='tel: +19059494000'>+1 (905) 949-4000</a></div>
              <div><a href='mailto: help@rookhotel.com'>help@rookhotel.com</a></div>
            </div>
          </div>
          <div className="info">
            <div className="infoTitle">Hours of Operation</div>
            <div className="infoDescription">
              <div>
                <b>Standard Check-In:</b> 3:00PM
              </div>
              <div>
                <b>Standard Check-Out:</b> 11:00AM
              </div>
            </div>
            <div className="infoDescription">
              Check in and check out times may be changed by visiting My Trips.
              Additional charges may apply to late check-outs or early check-ins.{' '}
            </div>
          </div>
        </InfoSectionContainer>
        <MapSectionContainer>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.6895752804485!2d-79.63608368427849!3d43.59218186439182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b47240507f47d%3A0x894a327932dc9d93!2sRook%20Hotel!5e0!3m2!1sen!2sca!4v1646854927818!5m2!1sen!2sca"
            style={{ border: 0 }}
            allowFullScreen={true}
            tabIndex={0}
          />
        </MapSectionContainer>
      </div>
    </ContactInfoSectionContainer>
  )
}

export default ContactInfoSection
