import styled from 'styled-components';
import { BasicSectionContainer } from 'screens/style';
import { colors } from 'constants/colors';

export const SignUpSectionContainer = styled(BasicSectionContainer)`
  width: 100%;
  min-height: 667px;
  display: flex;
  justify-content: center;
  align-content: center;

  .innerContainer {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  
  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    background-size: 100%, 100%;
  }

  @media only screen and (max-width: 767px) {

    .innerContainer {
      justify-content: center;

      .imageContainer {
        display: none;
      }
    }
  }
`;

export const SignUpFormContainer = styled.div`
  width: 35%;
  max-width: 340px;
  height: 665px;
  background-color: transparent;
  margin: 64px 0;
  z-index: 100;

  .formTitle {
    font-size: 48px;
    font-weight: bold;
    color: ${colors.black};
    margin-bottom: 32px;
    text-align: center;
  }

  .inputFieldContainer {
    width: 100%;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .tips {
    font-size: 16px;
    color: ${colors.hoverBlue};
    text-decoration: underline;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .termsOfServiceContainer {
    width: 100%;
    margin-bottom: 16px;

    .required {
      margin-top: 8px;
      color: ${colors.red};
      font-size: 16px;
    }

    .termsOfService {
      display: flex;
      padding-left: 5px;

      .text{
        margin-left: 5px;

        .plainText {
          font-size: 16px;
          height: 30px;
          line-height: 30px;
          color: ${colors.black};
        }
      }
    }
  }

  .dividerContainer {
    width: 100%;
    display: flex;
    margin: 16px 0;

    span {
      margin: 0 10px;
    }

    .divider {
      width: 100%;
      padding: 0 15px;
      align-items: center;
      margin: auto;
      box-sizing: border-box;
    }
  }



  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    padding-top: 30px;

    .formTitle {
      font-size: 37px;
    }

    .buttonContainer {
      button {
        height: 45px;
        width: 40%;
        max-width: 95px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-top: 50px;

    .buttonContainer {
      button {
        width: 115px;
      }
    }
  }
`;
