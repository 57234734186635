import React, { useState, useEffect } from 'react';
import { SliderContainer, SliderContent } from './style';
import Dots from './Dots';
import { useSwipeable } from 'react-swipeable';

type SliderProps = {
  sources: JSX.Element[];
  borderRadius?: string;
  shadow?: boolean;
};

const Slider: React.FC<SliderProps> = ({
  sources,
  borderRadius
}) => {

  const transitionTime = 0.45;
  const autoPlayTime = 3000;

  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: transitionTime,
  });

  const { translate, transition, activeIndex } = state;

  useEffect(() => {
    // Automatically advance slides every 3 seconds
    const interval = setInterval(() => {
      setState((state) => ({
        transition: transitionTime,
        activeIndex: state.activeIndex + 1,
        translate: state.activeIndex + 1,
      }));
    }, autoPlayTime);

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (activeIndex === sources.length-1) {
      setTimeout(() => {
        setState({
          translate: 0,
          activeIndex: 0,
          transition: 0
        });
      }, transitionTime * 1000);
    }
  }, [activeIndex, sources.length, setState]);

  const nextSlide = () => {
    setState({
      transition: transitionTime,
      activeIndex: activeIndex + 1,
      translate: activeIndex + 1,
    })
  }

  const prevSlide = () => {
    if(activeIndex >0 ) {
      setState({
        transition: transitionTime,
        activeIndex: activeIndex - 1,
        translate: activeIndex - 1,
      })
    }
  }

  const handles = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  return (
    <SliderContainer {...handles} borderRadius={borderRadius}>
      <Dots
        slides={sources}
        activeIndex={activeIndex}
        setState={setState}
      />
      <SliderContent
        width={`${sources.length * 100}%`}
        translateWidth={(translate * 100) / sources.length}
        transition={transition}>
        {sources}
      </SliderContent>
    </SliderContainer>
  );
};

export default Slider;
