import React, { useEffect } from 'react';
import { RoomOptionsContainer } from './style';
import OptionCard from 'components/OptionCard';
import { RoomType } from 'types/commonTypes';
import { 
  PremiumIcon,
  BusinessIcon,
  SmileIcon,
  MoneyIcon,
  CrownIcon,
  CouchIcon,
  ExpandIcon,
  WorkIcon,
  WindowIcon,
  LightBallIcon,
  HatIcon,
  CityIcon
} from 'assets/icons';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useGetAvailableRooms } from 'customHooks/useGetAvailableRooms';
import { selectedRooms } from 'states/CheckOutStates/atoms';

const RoomOptions: React.FC = () => {

  const { searchResult, noAvailableRooms, loading } = useGetAvailableRooms()
  const [persistSelectedRooms, setPersistSelectedRooms] = useRecoilState(selectedRooms)
  const clearSelectedRooms = useResetRecoilState(selectedRooms)

  const allRoomOptions: {[key: string]: any} = {
    "King": 
    {
      title:"Upgrade to our king suite",
      description:"Travel in style with this premium option",
      titleIcon: <PremiumIcon />,
      attributes: [{icon: <CrownIcon />, text: "King bed"}, {icon: <CouchIcon />, text: "Couch"}, {icon: <ExpandIcon />, text: "Extra floor space"}, {icon: <WorkIcon />, text: "Workspace (table and chair)"}, {icon: <WindowIcon />, text: "Large windows"}]
    },
    "Queen":
    { 
      title:"Upgrade to a room with workspace",
      description:"Best for the business traveller",
      titleIcon:<BusinessIcon />,
      attributes:[{icon: <CrownIcon />, text: "Queen bed"}, {icon: <WorkIcon />, text: "Workspace (table and chair)"}, {icon: <CityIcon />, text: "Room with a view"}]
    },
    "Rook":
    {
      title:"Regular room",
      description:"Affordable and cheerful",
      titleIcon:<SmileIcon />,
      attributes:[{icon: <CrownIcon />, text: "Queen bed"}]
    },
    "Knight":
    {
      title:"Regular room",
      description:"Affordable and cheerful",
      titleIcon:<SmileIcon />,
      attributes:[{icon: <CrownIcon />, text: "Queen bed"}]
    },
    "Rook Inside":
    {
      title:"Regular room",
      description:"Affordable and cheerful",
      titleIcon:<SmileIcon />,
      attributes:[{icon: <CrownIcon />, text: "Queen bed"}]
    },
    "Knight Inside":
    {
      title:"Regular room",
      description:"Affordable and cheerful",
      titleIcon:<SmileIcon />,
      attributes:[{icon: <CrownIcon />, text: "Queen bed"}]
    },
    "Pawn Inside":
    {
      title:"Downgrade to a twin bed",
      description:"For the frugal minimalist",
      titleIcon:<MoneyIcon />,
      attributes:[{icon: <HatIcon />, text: "Twin bed"}, {icon: <LightBallIcon />, text: "Most affordable option"}],
    }
  }

  const removeSpecificObject = (arr: RoomType[], searchArray: {searchValue: string, valuesToRemove: string[]}[]) => {
    const newArray = [...arr]
    searchArray.forEach((item) => {
      const index = newArray.findIndex(obj => obj.name === item.searchValue);
      if (index !== -1) {
        item.valuesToRemove.forEach((valueToRemove) => {
          const removeIndex = newArray.findIndex(obj => obj.name === valueToRemove);
          if (removeIndex !== -1) {
            newArray.splice(removeIndex, 1);
          }
        });
      }
    })
    return newArray
  }

  const filteredRoomTypes = removeSpecificObject(searchResult, 
    [
      {searchValue: "Rook Inside", valuesToRemove: ["Rook", "Knight", "Knight Inside"]},
      {searchValue: "Rook", valuesToRemove: ["Knight", "Knight Inside"]},
      {searchValue: "Knight Inside", valuesToRemove: ["Knight"]},
    ]
  )

  const regularRoomType = filteredRoomTypes.find((roomType: RoomType) => 
    roomType.name === 'Rook'
    || roomType.name === 'Knight'
    || roomType.name === 'Rook Inside'
    || roomType.name === 'Knight Inside')

  const queenRoomType = filteredRoomTypes.find((roomType: RoomType) =>
    roomType.name === 'Queen')

  const kingRoomType = filteredRoomTypes.find((roomType: RoomType) =>
    roomType.name === 'King')

  const pawnRoomType = filteredRoomTypes.find((roomType: RoomType) =>
    roomType.name === 'Pawn Inside')

  useEffect(() => {
    if(noAvailableRooms) {
      localStorage.removeItem('selectedRooms')
      clearSelectedRooms()
    }
  }
  , [noAvailableRooms, clearSelectedRooms])

  useEffect(() => {

    const handleRoomTypeSelection = (roomType: RoomType | undefined) => {
      if(roomType) {
        const updatedRooms = {
          roomTypeId: roomType.roomTypeId,
          pricePerNight: roomType.pricePerNight,
          name: roomType.name,
          bed: roomType.bed,
          hasWindows: roomType.hasWindows
        }
        setPersistSelectedRooms(updatedRooms)
        localStorage.setItem('selectedRooms', JSON.stringify(updatedRooms))
      }
    }

    if(!noAvailableRooms && !filteredRoomTypes.find((roomType: RoomType) => roomType.roomTypeId === persistSelectedRooms.roomTypeId)) { 
      switch (true) {
        case !!regularRoomType:
          handleRoomTypeSelection(regularRoomType);
          break;
        case !!queenRoomType:
          handleRoomTypeSelection(queenRoomType);
          break;
        case !!kingRoomType:
          handleRoomTypeSelection(kingRoomType);
          break;
        case !!pawnRoomType:
          handleRoomTypeSelection(pawnRoomType);
          break;
        default:
          break;
      }
    }
  }, [
    kingRoomType,
    queenRoomType,
    regularRoomType,
    pawnRoomType,
    filteredRoomTypes,
    persistSelectedRooms.roomTypeId,
    noAvailableRooms,
    setPersistSelectedRooms
  ])

  const roomArray = filteredRoomTypes.map((roomType: RoomType) => {
    const roomTypeOption = allRoomOptions[roomType.name]
    if (roomTypeOption) {
      return (
        <OptionCard
          className={roomType.name}
          key={roomType.roomTypeId}
          title={roomTypeOption.title}
          titleIcon={roomTypeOption.titleIcon}
          description={roomTypeOption.description}
          attributes={roomTypeOption.attributes}
          roomTypeId={roomType.roomTypeId}
          roomTypeName={roomType.name}
          pricePerNight={roomType.pricePerNight}
          bed={roomType.bed}
          hasWindows={roomType.hasWindows}
        />
      )
    } else {
      return null
    }
  })

  return (
    <RoomOptionsContainer>
      {noAvailableRooms && !loading? (
        <>
          <div className="noRoomsTitle">
            The HORROR
          </div>
          <div className='noRoomsPicture'></div>
        </>
      ): loading?(
        <div className="loading">
          Loading...
        </div>
      ):(
        <>
          <div className="roomOptionsTitle">
            Select your room
          </div>
          <div className="optionCardsContainer">
            {roomArray}
          </div>
          <div className="roomOptionsFooter">
            All rooms include access to our state-of-the-art fitness facility, sauna and steam room. Our hotel also features a cafe and a restaurant with options for all types of dietary needs (vegan, keto, gluten free).
          </div>
        </>
      )}
    </RoomOptionsContainer>
  );
}
export default RoomOptions;
