import React, { useEffect } from 'react';
import Card from 'components/Card';
import { OptionCardContainer } from './style';
import { useRecoilState } from 'recoil';
import { selectedRooms } from 'states/CheckOutStates/atoms';

interface Attribute {
  icon: React.ReactElement;
  text: string;
}

type OptionCardProps = {
  title: string;
  titleIcon: React.ReactElement;
  description: string;
  attributes: Attribute[];
  roomTypeId: string;
  roomTypeName: string;
  pricePerNight: number;
  bed: string;
  hasWindows: boolean;
  className?: string;
}

const OptionCard: React.FC<OptionCardProps> = ({ 
  title,
  titleIcon,
  description,
  attributes,
  roomTypeId,
  roomTypeName,
  pricePerNight,
  bed,
  hasWindows,
  className
}) => {

  const [persistSelectedRooms, setPersistSelectedRooms] = useRecoilState(selectedRooms)

  const handleRoomTypeSelection = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const updatedRooms = {...persistSelectedRooms}
    updatedRooms.roomTypeId = roomTypeId
    updatedRooms.name = roomTypeName
    updatedRooms.pricePerNight = pricePerNight
    updatedRooms.bed = bed
    updatedRooms.hasWindows = hasWindows
    setPersistSelectedRooms(updatedRooms)
    localStorage.setItem('selectedRooms', JSON.stringify(updatedRooms))
  }

  useEffect(() => {
    if(persistSelectedRooms.roomTypeId===roomTypeId) {
      const updatedRooms = {...persistSelectedRooms}
      updatedRooms.pricePerNight = pricePerNight
      setPersistSelectedRooms(updatedRooms)
      localStorage.setItem('selectedRooms', JSON.stringify(updatedRooms))
    }
  }, [])

  return (
    <Card
      pointer={true}
      selected={roomTypeId===persistSelectedRooms.roomTypeId}
      className={className}
    >
      <OptionCardContainer
        selected={roomTypeId===persistSelectedRooms.roomTypeId}
        onClick={handleRoomTypeSelection}
      >
        <div className="optionCardTitle">
          <div className="title">
            {title}
          </div>
          <div className="description">
            {titleIcon}
            <span>{description}</span>
          </div>
        </div>
        <div className="optionCardAttributes">
          {
            attributes.map((attribute, index) => {
              return (
                <div key={index} className="roomAttribute">
                  {attribute.icon}
                  {attribute.text}
                </div>
              )
            })
          }
        </div>
        <div className="optionCardPrice">
          <div className="customizedRadio"></div>
          <div className="priceDiff">
            {`$${pricePerNight.toFixed(2)}`}
          </div>
          <div className="explanation">
            per night, not including tax / fees
          </div>
        </div>
      </OptionCardContainer>
    </Card>
  )
}
export default OptionCard;
