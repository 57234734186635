import styled from "styled-components";
import { colors } from "constants/colors";
import { styleSizes } from "constants/styleSizes";

export const BasicSectionContainer = styled.section`

  width: 100%;
  box-sizing: border-box;
  .cardContent {
    min-width: 260px;
    -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
    border: 1px solid ${colors.accounBorderColor};
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: stretch;
    flex: 1;
    color: ${colors.commonBlack};
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      padding: 0;
      border: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .innerContainer {
    width: 100%;
    max-width: 1229px;
    box-sizing: border-box;
    padding: 0 31px;
    position: relative;
    overflow: visible;

    @media only screen and (max-width: ${styleSizes.mobileBreakpoint}) {
      padding: 0 24px;
      min-width: 320px;
    }
  }
  .commonTitle {
    width: 100%;
    font-size: 32px;
    text-align: left;
    font-weight: 600;
    color: ${colors.almostBlack};
    margin: 0 0 35px 0;
    padding: 0;
  }

  .confirmed {
    color: ${colors.green};
  }

  .incomplete {
    color: ${colors.red};
  }

  .checkedIn {
    color: ${colors.hoverBlue};
  }

  .checkedOut {
    color: ${colors.black};
  }

`;
