import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { StylesProvider } from '@material-ui/core/styles'
import { ApolloProvider } from '@apollo/client'
import { client } from 'graphql/apolloClient';
import ErrorBoundary from 'components/ErrorBoundary'
import { RecoilRoot } from 'recoil'

ReactDOM.render(

  <React.StrictMode>
    <ErrorBoundary>      
      <RecoilRoot>
        <BrowserRouter>
          <ApolloProvider client={client}>
            <StylesProvider injectFirst>
              <App />
            </StylesProvider>
          </ApolloProvider>
        </BrowserRouter>
      </RecoilRoot>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()


