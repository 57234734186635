import { FC } from "react";
import Modal from "components/Modal";
import React from "react";
import { BottomButtonContainer, MobileGuestsAndRoomsOptionsContainer } from "./style";
import {
  guestQuantityState,
  roomQuantityState,
} from "states/SearchBarStates/atoms";
import { useRecoilState } from "recoil";
import { DecreaseIcon, IncreaseIcon, RedCloseIcon } from "assets/icons";
import { Icon } from "components/Icon";
import StyledButton from "components/Button";

type MobileGuestsAndRoomsOptionsProps = {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileGuestsAndRoomsOptions: FC<MobileGuestsAndRoomsOptionsProps> = ({ open, setIsOpen }) => {

  const [guestQuantity, setGuestQuantity] = useRecoilState(guestQuantityState);
  const [roomQuantity, setRoomQuantity] = useRecoilState(roomQuantityState);

  return (
    <Modal open={open} mobile>
      <MobileGuestsAndRoomsOptionsContainer>
        <div
          role="presentation"
          className="closeButton"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <h1 className="modal-title">Guests & Rooms</h1>
          <div className="close-icon">
            <RedCloseIcon />
          </div>
        </div>
        <div className="guestsOption">
          <div className="text">
            <div className="optionTitle">GUESTS</div>
            <span className="subtitle">How many people are travelling?</span>
          </div>
          <div className="optionButtons">
            <Icon width={30} height={30}>
              <DecreaseIcon
                className={guestQuantity <= 1? "disabled" : ""}
                onClick={() => {
                  if(guestQuantity > 1) {
                    localStorage.setItem('guestQuantity', (guestQuantity - 1).toString());
                    setGuestQuantity(guestQuantity - 1);
                    if(roomQuantity > guestQuantity - 1) {
                      localStorage.setItem('roomQuantity', (guestQuantity - 1).toString());
                      setRoomQuantity(guestQuantity - 1);
                    }
                  }
                }}
              />
            </Icon>
            <div className="quantity">{guestQuantity}</div>
            <Icon width={30} height={30}>
              <IncreaseIcon
                className={guestQuantity >= 18? "disabled": ""}
                onClick={() => {
                  if (guestQuantity < 18) {
                    localStorage.setItem("guestQuantity",(guestQuantity + 1).toString());
                    setGuestQuantity(guestQuantity + 1);
                    if (roomQuantity < Math.ceil((guestQuantity + 1) / 2)) {
                      localStorage.setItem("roomQuantity",Math.ceil((guestQuantity + 1) / 2).toString());
                      setRoomQuantity(Math.ceil((guestQuantity + 1) / 2));
                    }
                  }
                }}
              />
            </Icon>
          </div>
        </div>
        <div className="roomsOption">
          <div>
            <div className="optionTitle">ROOMS</div>
            <div className="subtitle">Max 2 guests per room</div>
          </div>
          <div className="optionButtons">
            <Icon width={30} height={30}>
              <DecreaseIcon
                className={roomQuantity <= Math.ceil(guestQuantity/2)? "disabled": ""}
                onClick={() => {
                  if(roomQuantity > Math.ceil(guestQuantity/2)) {
                    localStorage.setItem('roomQuantity', (roomQuantity - 1).toString());
                    setRoomQuantity(roomQuantity - 1);
                  }
                }}
              />
            </Icon>
            <div className="quantity">{roomQuantity}</div>
            <Icon width={30} height={30}>
              <IncreaseIcon
                className={roomQuantity >= 9? "disabled": ""}
                onClick={() => {
                  if(roomQuantity < 9) {
                    localStorage.setItem('roomQuantity', (roomQuantity + 1).toString());
                    setRoomQuantity(roomQuantity + 1);
                    if(roomQuantity===guestQuantity) {
                      localStorage.setItem('guestQuantity', (guestQuantity + 1).toString());
                      setGuestQuantity(guestQuantity + 1);
                    }
                  }
                }}
              />
            </Icon>
          </div>
        </div>
        <BottomButtonContainer>
          <span style={{ fontSize: 15, paddingBottom: 12 }}>
            {roomQuantity} room(s) - {guestQuantity} guest per room
          </span>
          <StyledButton
            className="searchBarButton"
            width="100px"
            height="59px"
            fontSize={18}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Select rooms and guests
          </StyledButton>
        </BottomButtonContainer>
      </MobileGuestsAndRoomsOptionsContainer>
    </Modal>
  );
};

export default MobileGuestsAndRoomsOptions;
