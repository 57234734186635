import React, { FC, useState, useEffect } from 'react';
import zxcvbn from 'zxcvbn';
import { BarContainer, Item } from './style';
import { colors } from 'constants/colors';

type PasswordStrengthBarProps = {
  className?: string;
  password: string;
  userInputs?: string[];
  barColors?: string[];
  scoreWords?: string[];
  minLength?: number;
  shortScoreWord?: string;
};

const PasswordStrengthBar: FC<PasswordStrengthBarProps> = ({
  className = '',
  password,
  barColors = [colors.borderGrey3, colors.red, colors.orange, colors.yellow, colors.green, colors.green],
  scoreWords = [
    '',
    '',
    'Weak',
    'Medium',
    'Strong',
  ],
  minLength = 8,
  userInputs = [],
  shortScoreWord = 'Too short, minimum 8 characters',
}) => {

  const [score, setScore] = useState(0);
  const [string, setString] = useState('');



  useEffect(() => {
    if (password.length >= minLength) {
      const result = zxcvbn(password, userInputs);
      if (result?.score > 1) {
        setScore(result.score);
        setString(scoreWords[result.score]);
      } else {
        setScore(2);
        setString(scoreWords[2]);
      }
    } else if (password.length === 0) {
      setScore(0);
      setString(scoreWords[0]);
    } else {
      setScore(1);
      setString(shortScoreWord);
    }
  }, [password, setScore, setString]);

  return (
    <BarContainer className={className}>
      <div className='bar'>
        {[1, 2, 3, 4].map((el: number) => (
          <Item score={score} itemNum={el} barColors={barColors} key={`password-strength-bar-item-${el}`}/>
        ))}
      </div>
      <p className="passwordStrengthBarText" style={{color: barColors[score], marginLeft: ((score - 1) * 25 + 2) + '%'}}>
        {string}
      </p>
    </BarContainer>
  );
}

export default PasswordStrengthBar;