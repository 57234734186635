import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import CancellationPolicySection from './components/CancellationPolicySection';

const CancellationPolicyScreen: React.FC = () => {

  return (
    <ScreenLayout disableModal>
      <CancellationPolicySection />
    </ScreenLayout>
  )
}

export default CancellationPolicyScreen
