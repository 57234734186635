import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import resetPassword from 'assets/icons/resetPassword.svg';

export const ResetPasswordScreenContainer = styled(BasicSectionContainer)`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.forgotPasswordBackground};
    width: 100%;
    height: 414px;
    padding: 0 15px;

    .titleText {
      width: 100%;
      margin: 0 20px 0 0;

      .title {
        font-size: 48px;
        font-weight: bold;
        line-height: 72px;
        text-align: left;
        color: ${colors.white};

        .highLight {
          color: ${colors.checkOutCompleteScreenHighlight};
        }
      }
    }

    .resetPasswordImage {
      width: 490px;
      min-width: 490px;
      height: 396px;
      background-image: url(${resetPassword});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
      margin: 18px 0 0 0;
    }
  }

  .inputRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 15px;

    .innerContainer {
      display: flex;
      flex-direction: column;
      width: 340px;
      min-width: 340px;
      padding: 44px 0;

      .titleText {
        font-size: 24px;
        font-weight: bold;
        color: ${colors.black};
        margin-bottom: 32px;
      }

      .buttonContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          position: absolute;
          top: -85px;
          left: 100px;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {

    .innerContainer {
      flex-direction: column-reverse;
      padding: 0;
      margin: 0;
    }

    .titleContainer {
      height: auto;

      .titleText {
        margin: 20px 0;

        .title {
          text-align: center;
          font-size: 36px;
        }
      }

      .resetPasswordImage {
        max-width: 270px;
        min-width: 270px;
        height: 183px;
        margin-top: 20px;
      }
    }
  }
`;