import styled from 'styled-components';
import { colors } from 'constants/colors';

export const AddOnCardContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  padding: 5px;
  flex-direction: column;

  .optionCardTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .optionCardAttributes {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;

    .roomAttribute {
      margin: 0 2% 0 0;
      align-items: center;
      font-size: 13px;
      letter-spacing: -0.5px;

      svg {
        position: relative;
        width: 14px;
        height: 14px;
        margin-right: 4px;
        top: 3px;
      }
    }
  }

  .optionCardPrice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid ${colors.signInBorderColor};
    padding: 11px 10px;
    margin: 15px 0;

    .switch {
      width: 46px;
      min-width: 46px;
      height: 28px;
      border: 2px solid ${colors.borderGrey};
      background-color: ${colors.borderGrey3};
      border-radius: 14px;

      .switchSlider {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: ${colors.white};
        position: relative;
        top: calc(50% - 9px);
        left: 3px;
        transition: transform 0.3s ease;

        svg {
          position: relative;
          width: 12px;
          height: 12px;
          margin: 3px;
        }
      }

      .switchSlider.active {
        transform: translateX(18px);

        svg {
          top: 0;
          left: 0;
        }
      }
    }

    .switch.active {
      border: 2px solid ${colors.radioGreen};
      background-color: ${colors.generalInfoIconBorder};
    }

    .price {
      min-width: 50px;
      font-size: 15px;
      font-weight: 600;
      margin: 0 10px;
    }

    .explanation {
      font-size: 11px;
      color: ${colors.textInputLabel};
    }
  }
`;