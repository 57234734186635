import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import ResetForm from './components/ResetForm';

const ResetPasswordScreen: React.FC = () => {
  
  return (
    <ScreenLayout disableModal>
      <ResetForm />
    </ScreenLayout>
  );
};

export default ResetPasswordScreen;
