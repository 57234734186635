import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import SetPasswordForm from './components/SetPasswordForm';

const SetPasswordScreen: React.FC = () => {

  return (
    <ScreenLayout disableModal>
      <SetPasswordForm />
    </ScreenLayout>
  );
};

export default SetPasswordScreen;
