import React, { FC, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SignInSectionContainer, SignInFormContainer } from './style'
import FormInputField from 'components/FormInputField'
import PhoneNumberInput from 'components/PhoneNumberInput'
import StyledButton from 'components/Button'
import { colors } from 'constants/colors'
import { useAuth } from 'customHooks/useAuth'

const SignInSection: FC = () => {

  const history = useHistory()
  const { validToken, userSignIn } = useAuth()
  const [emailSignIn, setEmailSignIn] = useState(true)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(false)

  useEffect(() => {
    if(validToken) {
      history.push('/')
    }
  },[])

  const handleSignIn = () => {

    if(emailSignIn) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        && password.length >= 8
      ) {
        setEmailError(false)
        setPasswordError(false)
        userSignIn(email, password, '/profile');
      } else {
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          setEmailError(true)
        }
    
        if (password.length < 8) {
          setPasswordError(true)
        }
        const content = 'Invalid password or email'
        console.log(content)
      }
    } else {
      if(phoneNumberValidity && password.length >= 8) {
        setPhoneNumberError(false)
        setPasswordError(false)
        userSignIn(phoneNumber, password, '/profile');
      } else {

        if (password.length < 8) {
          setPasswordError(true)
        }
        const content = 'Invalid password or phone number'
        console.log(content)
      }
    }
  }

  return (
    <SignInSectionContainer>
      <div className="innerContainer">
        <SignInFormContainer>
          <div className="container">
            <div className="formTitle">Sign in</div>
            <div className="inputFieldContainer">
              {emailSignIn? (
                <FormInputField
                  label="EMAIL"
                  isRequired
                  inputHeight="48px"
                  borderColor={colors.signInBorderColor}
                  marginBottom="32px"
                  placeholder="User Email"
                  value={email}
                  error={emailError}
                  errorMessage='Please enter a valid email address and try again.'
                  onChange={(e) => {
                    setEmail(e.target.value)
                    if(emailError) {
                      setEmailError(false)
                    }
                  }}
                />
              ): (
                  <PhoneNumberInput
                    className='phoneNumberInput'
                    label="PHONE"
                    isRequired={true}
                    labelPosition='left'
                    error={!phoneNumberValidity && phoneNumberError}
                    errorMessage='Please enter a valid phone number'
                    inputHeight="48px"
                    marginBottom={'32px'}
                    onChangeNumber={setPhoneNumber}
                    setPhoneNumberValidity={setPhoneNumberValidity}
                    onBlur={() => {
                      if(!phoneNumberValidity&&phoneNumber.length<3) {
                        setPhoneNumberError(true)
                      }
                    }}
                  />
                )
              }
              <FormInputField
                label="PASSWORD"
                isRequired
                borderColor={colors.signInBorderColor}
                marginBottom="8px"
                placeholder="Password"
                value={password}
                error={passwordError}
                errorMessage='Please enter a valid password'
                inputHeight="48px"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value)
                  if(passwordError) {
                    setPasswordError(false)
                  }
                }}
              />
              <div
                className="tips"
                onClick={() => {
                  history.push('/forgotPassword')
                }}
                style={{marginTop: passwordError?25: 0}}
              >
                Forgot your password?
              </div>
            </div>
            <div className="buttonContainer">
              <StyledButton
                width="111px"
                height="48px"
                fontSize={18}
                fontWeight={700}
                onClick={handleSignIn}
              >
                Sign in
              </StyledButton>
              <div className='dividerContainer'>
                <hr className="divider" />
                <span>or</span>
                <hr className="divider" />
              </div>
              <div
                className="tips"
                onClick={() => setEmailSignIn(!emailSignIn)}
              >
                Sign in with {emailSignIn? 'phone number': 'email'}
              </div>
              <div>
                <span>You don't have an account? </span>
                <span
                  className="tips"
                  onClick={() => {
                    history.push('/signUp')
                  }}
                >
                  Sign up
                  </span>
              </div>
            </div>
          </div>
        </SignInFormContainer>
        <div className="imageContainer" />
      </div>
    </SignInSectionContainer>
  );
};

export default SignInSection;
