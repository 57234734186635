import { FC, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import FormInputField from 'components/FormInputField'
import { useAuth } from 'customHooks/useAuth'
import { SetPasswordScreenContainer } from './style';
import StyledButton from 'components/Button';
import { useMutation } from '@apollo/client';
import { SETUP_USER_PASSWORD_NEW } from 'graphql/service';
import PasswordStrengthBar from 'components/PasswordStrengthBar';
import jwt_decode from "jwt-decode"
import { JsonWebToken } from 'types/commonTypes'
import { colors } from 'constants/colors'
import { PasswordToolTip } from 'assets/icons'

const SetPasswordForm: FC = () => {

  const history = useHistory()
  const { userVerification, validToken } = useAuth()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const email = params.get('email')
  const oneTimeToken = params.get('oneTimeToken')

  // useEffect(()=> {
  //   if(email && oneTimeToken) {
  //     userVerification(email, oneTimeToken)
  //   }
  // },[])

  const [password, setPassword] = useState('')
  const [isHovered, setIsHovered] = useState(false)

//FIXME: not sure that this code work correctly, it should be reviewed, tested and fixed in future - Vadim
  const [setupUserPassword, { data: setupUserPasswordData }] = useMutation(
    SETUP_USER_PASSWORD_NEW,
    {
      onCompleted: (setupUserPasswordData)=> {
        if(setupUserPasswordData.setupUserPassword?.auth.accessToken) {
          const token = setupUserPasswordData.setupUserPassword?.auth.accessToken;        
          const decoded: JsonWebToken = jwt_decode(token)
          localStorage.setItem('userId', decoded.sub)
          localStorage.setItem('token', token)
          history.push('/account/dashboard')
        }  else {
          const content = 'Setup user failed!'
          console.log(content)
        }
      },
      onError: (error)=> {
        const content = error.message
        console.log(content)
      }
    }
  )

  // const handleSubmit = () => {
  //   if(validToken) {
  //     setupUserPassword({
  //       variables: {
  //         input: {
  //           oneTimeToken,
  //           newPassword: password,
  //         }
  //       }
  //     })
  //   } else {
  //     console.log('Session expired, please try again!')
  //     history.push('/signIn')
  //   }
  // }

const handleSubmit = () => {
  
  if(oneTimeToken) {
      localStorage.setItem('token', oneTimeToken);
      // console.log (oneTimeToken);
  }
    
      setupUserPassword({
        variables: {
          input: {
            oneTimeToken: oneTimeToken,
            newPassword: password
          }
        }
      })
  }



  return (
    <SetPasswordScreenContainer>
      <div className="titleContainer">
        <div className="innerContainer">
          <div className='titleText'>
            <div className="title">
              <div>Select <span className='highLight'>a secret password</span></div>
              <div>to finish setting up your account.</div>
            </div>
          </div>
          <div className="setPasswordImage" />
        </div>
      </div>
      <div className="inputRow">
        <div className="innerContainer">
          <div className="description">
            You’ll use this password to access your Rook account, manage your bookings, and even make special requests during your hotel stay. Please choose a password that is <span className='highLight'>at least 8 characters long</span>.
          </div>
          <FormInputField
            label={'PASSWORD'}
            labelColor={colors.grey}
            labelSize={'14px'}
            labelWeight={'600'}
            isRequired
            inputTextColor={colors.black}
            inputFontSize={'14px'}
            inputFontWeight={'600'}
            inputHeight={'43px'}
            inputBackgroundColor={'transparent'}
            width={'70%'}
            marginBottom='8px'
            borderColor={colors.signInBorderColor}
            autoComplete="new-password"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            value={password}
          />
          <PasswordStrengthBar
            className="passwordStrengthBar"
            password={password}
          />
          <div
            className='buttonContainer'
            onMouseEnter={()=>setIsHovered(true)}
            onMouseLeave={()=>setIsHovered(false)}
          >
            <StyledButton
              width="114px"
              height="48px"
              disabled={password.length < 8}
              margin={'10px 0 0 0'}
              onClick={handleSubmit}
            >
              Submit
            </StyledButton>
            {isHovered&&(password.length < 8)? <PasswordToolTip />: null}
          </div>
        </div>
      </div>
    </SetPasswordScreenContainer>
  )
}

export default SetPasswordForm;