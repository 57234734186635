import styled from 'styled-components';
import { colors } from 'constants/colors';
import LeavingOffer1 from 'assets/images/well.svg'
import LeavingOffer2 from 'assets/images/LeavingOffer2.png'

export const BasicScreenContainer = styled.div`
  width: 100%;
  min-height: calc(100vh -56px);
  height: 100%;
  display: flex;
  flex-direction: column;

  main {
    height: 100%;
    margin-top: 56px;
  }
`;

export const BasicModalContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 34px;
  position: relative;
  border-radius: 32px;
  z-index: 200;

  .close{
    position: absolute;
    cursor: pointer;
    top: 25px;
    right: 25px;
    width: 2px;
    height: 14px;
    background-color: ${colors.closeRed};
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .close:after {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    content: "";
    width: 2px;
    height: 14px;
    background-color: ${colors.closeRed};
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
`;

export const WhiteModalContainer = styled(BasicModalContainer)`
  background-color: ${colors.white};
  background-image: url('${LeavingOffer1}');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: right;
  width: 778px;
  height: 407px;
  top: calc(50vh - 250px);

  .text {
    width: 324px;
  }

  .text1-1 {
    font-size: 31px;
    font-weight: bold;
    margin: 8px 0;
    font-family: 'Ubuntu';
  }

  .text1-2 {
    font-size: 16px;
    font-weight: medium;
    font-family: 'Ubuntu';
  }

  .text1-3 {
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
    margin-bottom: 20px;
    font-family: 'Ubuntu';
  }
`;

export const BlackModalContainer = styled(BasicModalContainer)`
  background-color: ${colors.black};
  background-image: url('${LeavingOffer2}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 691px;
  height: 405px;
  top: calc(50vh - 250px);

  .text {
    width: 324px;
  }

  .text2-1 {
    font-size: 40px;
    font-weight: 300;
    color: ${colors.white};
    margin-top: 9px;
    line-height: 1.2;
  }

  .text2-2 {
    font-size: 40px;
    font-weight: bold;
    color: ${colors.red}
  }

  .text2-3 {
    font-size: 24px;
    font-weight: bold;
    color: ${colors.white};
  }

  .text2-4 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.grey};
    margin-top: 10px;
    margin-bottom: 15px;
  }
`;