import React from 'react';
import StyledButton from 'components/Button';
import { CancelModalContainer } from './style';
import { CrossIcon } from 'assets/icons';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { cancelBookingModalOpenState, cancelReasonModalOpenState, cancelGroupId, cancelBookingId } from 'states/ModalStates/atoms';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import moment from 'moment';

const CancelBookingModalBody: React.FC = () => {

  const setOpenCancelBookingModal = useSetRecoilState(cancelBookingModalOpenState)
  const setOpenCancelReasonModal = useSetRecoilState(cancelReasonModalOpenState)
  const groupId = useRecoilValue(cancelGroupId)
  const bookingId = useRecoilValue(cancelBookingId)
  const { trips } = useLoadTrips()
  const trip = trips[groupId]

  const cancellationFeeChargeDateTime = moment(trip?.bookings?.reduce((min, current) => {
    return current.checkInDate < min? current.checkInDate: min;
  }, trip?.bookings[0]?.checkInDate)).add(-1, 'day').format('ddd DD MMM, h:mm A')

  const cancellationFee = trip?.bookings?.reduce((prev, current)=> {
      return (current.cancellationFee ?? 0) + prev
  }, 0)

  const handleCancelBooking = () => {
    setOpenCancelBookingModal(false)
    setOpenCancelReasonModal(true)
  }

  return (
    <CancelModalContainer>
      <div
        className="closeButton"
        onClick={() => {
          setOpenCancelBookingModal(false);
        }}>
        <CrossIcon />
      </div>
      <div className="cardTitle">Cancel your booking</div>
      <div className="contentTitle">Are you sure you want to cancel this booking?</div>
      <div className="description">
        {`This booking was made with a flex rate plan. If you cancel before ${cancellationFeeChargeDateTime} you will not incur any charges. If you cancel after this date there will be a fee of $${cancellationFee}.`}
      </div>
      <div className='buttonContainer'>
        <div
          className='link'
          onClick={handleCancelBooking}
        >
          Cancel Booking
        </div>
        <StyledButton
          width="142px"
          height="48px"
          onClick={() => setOpenCancelBookingModal(false)}
        >
          Keep Booking
        </StyledButton>
      </div>
    </CancelModalContainer>
  );
};

export default CancelBookingModalBody
