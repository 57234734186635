import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';

export const SlideContentContainer = styled(BasicSectionContainer)`
  position: relative;
  margin: 82px 0 50px 0;
  display: flex;
  justify-content: center;
  background-color: ${colors.searchBarBackground};

  .slideSectionTitle {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    margin: 0;

    .slideSectionTitle {
      position: absolute;
      display: block;
      top: 26px;
      left: 13px;
      font-size: 32px;
      font-weight: 600;
    }
  }
`;

type SlideContentItemContainerProps = {
  reverse?: boolean;
};

export const SlideContentItemContainer = styled.section<SlideContentItemContainerProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => (props.reverse? 'row-reverse' : 'row')};
  column-gap: 30px;

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;

type ContentContainerProps = {
  reverse?: boolean;
  isLast?: boolean;
  lastTitle?: boolean;
  titleOneLayout?: string;
  titleTwoLayout?: string;
  titleThreeLayout?: string;
  descriptionLayout?: string;
};

export const ContentContainer = styled.div<ContentContainerProps>`
  flex: 1;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  .contentTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;

    & > span {
      font-size: 68px;
      letter-spacing: 2px;
      line-height: 181px;
      font-weight: 400;
    }

    & > span:first-child {
      ${(props) => props.isLast? `
        width: 75%;
        font-size: 68px;
        line-height:80px;
        font-weight: 700;
        color: ${colors.hoverBlue};
        text-align: center;
      `: `
        font-weight: 400;
        line-height:85px;
        color: ${colors.homeTitle};
      `};
      ${(props) => (props.titleOneLayout?? null)}
    }

    & > span:nth-child(2) {
      font-weight: 400;
      margin-left: 53px;
      margin-bottom: 10px;
      line-height: 60px;
      color: ${colors.homeTitle};
      ${(props) => (props.titleTwoLayout?? null)}
    }
    & > span:last-child {
      ${(props) => !props.isLast ? `
        width: 75%;
        font-size: 68px;
        font-weight: 700;
        line-height:80px;
        color: ${colors.hoverBlue};
        text-align: left;
        margin-left:${props.lastTitle? '80px' : '110px'};
      `: `
        font-weight: 400;
        width:100%;
        line-height:80px;
        color: ${colors.homeTitle};
        text-align: left;
        margin-left:25%;
      `};
      ${(props) => (props.titleThreeLayout?? null)};
    }
    
    @media only screen and (min-width: 767px) and (max-width: 1200px) {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;

      & > span:first-child {
        width: auto;
        font-size: 4vw;
        font-weight: bold;
        letter-spacing: 0;
        margin: 0px 8px 0px 0px;
        color: ${colors.homeTitle};
        line-height: 4vw;
      }

      & > span:nth-child(2) {
        width: auto;
        font-size: 4vw;
        font-weight: bold;
        letter-spacing: 0;
        margin: 0px 8px 0px 0px;
        line-height: 4vw;
      }

      & > span:last-child {
        width: auto;
        font-size: 4vw;
        font-weight: bold;
        letter-spacing: 0;
        margin: 0px 8px 0px 0px;
        color: ${colors.homeTitle};
        line-height: 4vw;
      }
    }

    @media only screen and (max-width: 767px) {
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      margin: 15px 0;

      & > span:first-child {
        width: auto;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0;
        margin: 0px 8px 0px 0px;
        color: ${colors.homeTitle};
        line-height: 28px;
      }

      & > span:nth-child(2) {
        width: auto;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0;
        margin: 0px 8px 0px 0px;
        line-height: 28px;
      }

      & > span:last-child {
        width: auto;
        font-size: 28px;
        font-weight: 600;
        letter-spacing: 0;
        margin: 0px 8px 0px 0px;
        color: ${colors.homeTitle};
        line-height: 28px;
      }
    }
  }

  .contentDescriptionContainer {
    width: 100%;
    align-self: center;
    margin: 5px 0 0 0;
    font-size: 15px;
    color: ${colors.homeDescription};
    ${(props) => (props.descriptionLayout?? null)};

    .link {
      font-weight: 600;
      font-size: 18px;
      color: ${colors.hoverBlue};
      cursor: pointer;
    }

    p {
      height: 115px;
    }

    @media only screen and (min-width: 767px) and (max-width: 1200px) {
      margin: 0;
      align-self: auto;

      p {
        margin-top: 0;
        padding-right: 10px;
      }
    }

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      margin: 0;

      .link {
        display: none;
      }

      p {
        margin: 0 0 10px 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

type ImageContainerProps = {
  imageHeight?: string;
  imageWidth?: string;
};

export const ImageContainer = styled.picture<ImageContainerProps>`
  width: 65%;
  display: flex;
  height: ${(props) => (props.imageHeight ? props.imageHeight : '100%')};
  box-sizing: border-box;
  max-width: 100%;
  max-height: 384px;

  img {
    max-width: 100%;
    min-width: 410px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    flex: 1;
    height: auto;
    img {
      max-width: 100%;
      min-width: 100%;
      max-height: 100%;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    img {
      max-width: 100%;
      min-width: 100%;
      max-height: 100%;
    }
  }
`;
