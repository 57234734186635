import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { AuthProvider } from 'customHooks/useAuth'
import GlobalStyles from 'globalStyles'
import ProtectRoute from 'components/ProtectRoute'
import HomeScreen from 'screens/HomeScreen'
import SearchResultOptionsScreen from 'screens/SearchResultOptionsScreen'
import RoomOptionsScreen from 'screens/RoomOptionsScreen'
import AddOnOptionsScreen from 'screens/AddOnOptionsScreen'
import CheckOutScreen from 'screens/CheckOutScreen'
import CheckOutConfirmScreen from 'screens/CheckOutConfirmScreen'
import ContactScreen from 'screens/ContactScreen'
import SignInScreen from 'screens/SignInScreen'
import SignUpScreen from 'screens/SignUpScreen'
import AccountScreen from 'screens/AccountScreen'
import SetPasswordScreen from 'screens/SetPasswordScreen'
import SetPasswordCompleteScreen from 'screens/SetPasswordCompleteScreen'
import ResetPasswordScreen from 'screens/ResetPasswordScreen'
import UnsubscribeScreen from 'screens/UnsubscribeScreen'
import CancellationPolicyScreen from 'screens/CancellationPolicy'
import TermsAndConditionsScreen from 'screens/TermsAndConditions'
import FrequentlyAskedQuestionsScreen from 'screens/FrequentlyAskedQuestions'
import ForgotPasswordScreen from 'screens/ForgotPasswordScreen'
import HelpScreen from 'screens/HelpScreen'

const App: React.FC = () => {

  return (
    <AuthProvider >
      <GlobalStyles />
      <Switch>
        <Route path="/" exact>
          <HomeScreen />
        </Route> 
        <Route path="/roomOptions" exact>
          <RoomOptionsScreen />
        </Route>  
        <Route path="/addOnOptions" exact>
          <AddOnOptionsScreen />
        </Route> 
        <Route path="/checkOut" exact>
          <CheckOutScreen />
        </Route>
        <Route path="/checkOutConfirm" exact>
          <CheckOutConfirmScreen />
        </Route>
        <Route path="/contact" exact>
          <ContactScreen />
        </Route>        
        <Route path="/signIn" exact>
          <SignInScreen />
        </Route>
        <Route path="/signUp" exact>
          <SignUpScreen />
        </Route>
        <ProtectRoute path="/account" redirectPath="/signIn">
          <AccountScreen />
        </ProtectRoute>
        <Route path="/search" render={() => <SearchResultOptionsScreen />}/>
        <Route path="/setup" render={() => <SetPasswordScreen />}/>
        <Route path="/setupComplete" exact render={() => <SetPasswordCompleteScreen />} />
        <Route path="/reset" render={() => <ResetPasswordScreen />}/>
        <Route path="/unsubscribe/:params" exact render={() => <UnsubscribeScreen />}/>
        <Route path="/cancellationPolicy" exact render={() => <CancellationPolicyScreen />}/>
        <Route path="/termsAndConditions" exact render={() => <TermsAndConditionsScreen />}/>
        <Route path="/faq" exact render={() => <FrequentlyAskedQuestionsScreen />}/>
        <Route path="/forgotPassword" exact render={() => <ForgotPasswordScreen />} />
        <Route path="/Help" exact render={() => <HelpScreen />} />
      </Switch>
    </AuthProvider>
  )
}

export default App
