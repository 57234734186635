import React, { FC, useState, ReactNode } from 'react';
import { useRecoilState } from 'recoil';
import { leavingOfferShowed } from 'states/ModalStates/atoms';
import { BasicScreenContainer } from './style';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Modal from 'components/Modal';
import { LeavingModalBodyBlack, LeavingModalBodyWhite } from './LeavingModalBody';
import { useBackToTop } from 'customHooks/useBackToTop';
import { Helmet } from 'react-helmet';

type ScreenLayoutProps = {
  children: ReactNode;
  disableFooter?: boolean;
  disableModal?: boolean;
  disableMap?: boolean;
};

interface Address {
  streetAddress: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;
}

interface Hotel {
  name: string;
  description: string;
  address: Address;
  telephone: string;
  url: string;
}

const hotel: Hotel = {
  name: "Rook Hotel",
  description: "Welcome to Example Hotel, the perfect destination for your stay!",
  address: {
    streetAddress: "3660 Hurontario Street",
    addressLocality: "Mississauga",
    addressRegion: "Ontario",
    postalCode: "L5B 3C4",
    addressCountry: "Canada"
  },
  telephone: "+19059494000",
  url: "https://www.rookhotel.com",
};

const jsonLdScript = `
{
  "@context": "https://schema.org",
  "@type": "Hotel",
  "name": "${hotel.name}",
  "description": "${hotel.description}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${hotel.address.streetAddress}",
    "addressLocality": "${hotel.address.addressLocality}",
    "addressRegion": "${hotel.address.addressRegion}",
    "postalCode": "${hotel.address.postalCode}",
    "addressCountry": "${hotel.address.addressCountry}"
  },
  "telephone": "${hotel.telephone}",
  "url": "${hotel.url}",
}
`;

const ScreenLayout: FC<ScreenLayoutProps> = ({ children, disableFooter, disableMap, disableModal }) => {

  useBackToTop();
  const time = Date.now()

  const [offerShowed, setOfferShowed] = useRecoilState(leavingOfferShowed)
  const [leavingModal, setLeavingModal] = useState(false);
  const [couponId, setCouponId] = useState('DADJOKES');

  const leavingOffer = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if(Date.now() > (time + 10000)) {
      if(!offerShowed && window.innerWidth > 1200) {
        if (e.clientY <= 0 || (e.movementY < 0 && e.movementY < -e.clientY)) {
          setLeavingModal(true)
          setOfferShowed(true)
        }
      }
    }
  }

  const handleClick = () => {
    localStorage.setItem('couponId', couponId)
    setLeavingModal(false)
  }

  const close = () => {
    setLeavingModal(false)
  }

  return (
    <>
      <BasicScreenContainer onMouseLeave={(e)=>leavingOffer(e)}>
        <Header />
        <main>{children}</main>
        <Footer disableFooter={disableFooter} disableMap={disableMap}/>
      </BasicScreenContainer>
      {/* disable this at developing stage temporarily*/}
      {/* <Modal open={disableModal? false:leavingModal}>
        {time% 2? <LeavingModalBodyWhite handleClick={handleClick} close={close} couponId={couponId}/>:<LeavingModalBodyBlack handleClick={handleClick} close={close} couponId={couponId}/>}    
      </Modal> */}
      <Helmet>
        <script type="application/ld+json">
          {jsonLdScript}
        </script>
      </Helmet>
    </>
  );
};

export default ScreenLayout;
