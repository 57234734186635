import React, { useState, useEffect } from "react";
import moment from "moment";
import { SearchBarContainer } from "./style";
import { useRecoilValue } from "recoil";
import { useHistory } from "react-router-dom";
import { dateRangeState, guestQuantityState, roomQuantityState } from "states/SearchBarStates/atoms";
import SearchIcon from "@material-ui/icons/Search";
import StyledButton from "components/Button";
import DatePickers from "./DatePickers";
import CheckBoxes from "./CheckBoxes";
import GuestsAndRooms from "./GuestsAndRooms";

const SearchBar: React.FC = ({ ...rest }) => {
  const dateRange = useRecoilValue(dateRangeState);
  const roomQuantity = useRecoilValue(roomQuantityState);
  const guestQuantity = useRecoilValue(guestQuantityState);
  const checkInDate = dateRange[0];
  const checkOutDate = dateRange[1];
  const history = useHistory();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(moment(checkOutDate) > moment(checkInDate));
  }, [checkInDate, checkOutDate, setValid]);

  const search = () => {
    history.push(`/search?checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&guestQuantity=${guestQuantity}&roomQuantity=${roomQuantity}`);
  };

  return (
    <SearchBarContainer {...rest}>
      <DatePickers />
      <GuestsAndRooms />
      <CheckBoxes />
      <StyledButton
        className="searchBarButton"
        disabled={!valid}
        startIcon={<SearchIcon style={{ width: "25px", height: "25px" }} />}
        fontSize={18}
        onClick={search}
      >
        Search
      </StyledButton>
    </SearchBarContainer>
  );
};

export default SearchBar;
