import React, { FC } from 'react';
import { HomeContentContainer, BlogPostContainer } from './style';
import BlogPost from 'components/BlogPost';
import stretchMedium from 'assets/images/stretch-medium.jpg';
import sanitizedMedium from 'assets/images/sanitized-medium.jpg';
import workingMedium from 'assets/images/working-medium.jpg';

const HomeContent: FC = () => {

  return (
    <HomeContentContainer>
      <div className="innerContainer">
        <div className="blogSectionTitle">What’s new at Rook Hotel</div>
        <BlogPostContainer>
          <BlogPost
            title={'Blog post title'}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            imageSource={stretchMedium}
          />
          <BlogPost
            title={'Blog post title'}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            imageSource={sanitizedMedium}
          />
          <BlogPost
            title={'Blog post title'}
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            imageSource={workingMedium}
          />
        </BlogPostContainer>
      </div>
    </HomeContentContainer>
  )
}

export default HomeContent;
