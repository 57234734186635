import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import UnsubscribeSection from './components/UnsubscribeSection';

const UnsubscribeScreen: React.FC = () => {

  return (
    <ScreenLayout disableModal>
      <UnsubscribeSection />
    </ScreenLayout>
  )
}

export default UnsubscribeScreen
