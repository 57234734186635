import React, { FC, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SignUpSectionContainer, SignUpFormContainer } from './style'
import FormInputField from 'components/FormInputField'
import Checkbox from 'components/Checkbox'
import StyledButton from 'components/Button'
import { useRecoilState } from 'recoil'
import { 
  termsOfServiceState,
  termsOfServiceErrorState,
} from 'states/CheckOutStates/atoms';
import { colors } from 'constants/colors'
import { useAuth } from 'customHooks/useAuth'
import { useMutation } from '@apollo/client';
import { SIGN_UP } from 'graphql/service';

const SignUpSection: FC = () => {

  const history = useHistory()
  const { validToken } = useAuth()

  const [termsOfServiceError, setTermsOfServiceError] = useRecoilState(termsOfServiceErrorState)
  const [termsOfServiceChecked, setTermsOfServiceChecked] = useRecoilState(termsOfServiceState)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [fullName, setFullName] = useState('')
  const [fullNameError, setFullNameError] = useState(false)

  const [signUp, { data: signUpData, loading, error }]  = useMutation(
    SIGN_UP,
    { 
      onCompleted: (signUpData) => {
        if(signUpData.signUp?.id) {
          console.log('Sign up successful! Please check your email to verify your account.')
        }
      },
      onError(error) {
        const content = error.message
        console.log(content)
      }
    }
  )

  useEffect(() => {
    if(validToken) {
      history.push('/')
    }
  },[])

  const handleSignUp = () => {
    let result = true

    if(!termsOfServiceChecked) {
      setTermsOfServiceError(true)
      result = false
      return
    } else {
      if(termsOfServiceError) {
        setTermsOfServiceError(false)
      }
    }

    if (fullName.length < 3) {
      setFullNameError(true)
      result = false
    } else {
      if(fullNameError) {
        setFullNameError(false)
      }
    }

    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailError(true)
      result = false
    } else {
      if(emailError) {
        setEmailError(false)
      }
    }

    if(result) {
      signUp({
        variables: {
          input: {
            email,
            fullName
          }
        }
      })
    } else {
      const content = 'Please fill in all required fields and try again.'
      console.log(content)
    }
  }

  return (
    <SignUpSectionContainer>
      <div className="innerContainer">
        <SignUpFormContainer>
          <div className="container">
            <div className="formTitle">Sign up</div>
            <div className="inputFieldContainer">
              <FormInputField
                label="FULL NAME"
                isRequired
                inputHeight="48px"
                borderColor={colors.signInBorderColor}
                marginBottom="16px"
                placeholder="Full Name"
                value={fullName}
                error={fullNameError}
                errorMessage='Please enter your full name'
                onChange={(e) => {
                  setFullName(e.target.value)
                  if(fullNameError) {
                    setFullNameError(false)
                  }
                }}
              />
              <FormInputField
                label="EMAIL"
                isRequired
                inputHeight="48px"
                borderColor={colors.signInBorderColor}
                marginBottom="16px"
                placeholder="User Email"
                value={email}
                error={emailError}
                errorMessage='Please enter a valid email address'
                onChange={(e) => {
                  setEmail(e.target.value)
                  if(emailError) {
                    setEmailError(false)
                  }
                }}
              />
              <div className='termsOfServiceContainer'>
                <div className='termsOfService'>
                  <Checkbox
                    checked={termsOfServiceChecked}
                    error={termsOfServiceError}
                    borderColor={colors.labelGrey}
                    onChange={() => {
                      setTermsOfServiceChecked(!termsOfServiceChecked)
                      localStorage.setItem('termsOfServiceChecked', (!termsOfServiceChecked).toString())
                      setTermsOfServiceError(false)
                    }}
                    checkboxScale={1.5}
                  />
                  <div className='text'>
                    <span className='plainText'>I agree to Rook Hotel's </span>
                    <span
                      className="tips"
                      onClick={() => {
                        history.push('/termsAndConditions')
                      }}
                    >
                      Terms of Service 
                    </span>
                    <span className='required'> *</span>
                  </div>
                </div>
                {termsOfServiceError? <div className='required'>Please accept the terms of service and try again.</div>: null}
              </div>
            </div>
            <div className="buttonContainer">
              <StyledButton
                width="111px"
                height="48px"
                fontSize={18}
                onClick={handleSignUp}
              >
                Sign up
              </StyledButton>
              <div className='dividerContainer'>
                <hr className="divider" />
                <span>or</span>
                <hr className="divider" />
              </div>
              <div>
                <span>Already have an account? </span>
                <span
                  className="tips"
                  onClick={() => {
                    history.push('/signIn')
                  }}
                >
                  Sign in
                  </span>
              </div>
            </div>
          </div>
        </SignUpFormContainer>
      </div>
    </SignUpSectionContainer>
  );
};

export default SignUpSection;
