import styled from 'styled-components';
import { colors } from 'constants/colors';

type IconProps = {
  width?: number;
  height?: number;
  customerStyle?: boolean;
  activeIconStyle?: boolean;
  isActiveIcon?: boolean;
  omitted?: boolean;
};

export const Icon = styled.div<IconProps>`
  width: ${(props) => (props.width? `${props.width}px` : '25px')};
  height: ${(props) => (props.height? `${props.height}px` : '25px')};
  border-radius: 0%;
  background-color: transparent;
  display: ${(props) =>
      props.omitted ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.customerStyle
      ? `border-radius:100%; 
          background-color:${colors.iconBlue}; 
          width:36px; 
          height:36px; 
          margin-left:10px`
      : ''};
  ${(props) => (props.isActiveIcon ? `background-color: ${colors.white}` : '')}
`;

export default Icon