import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  /**Overwrite Footer map */
  #map .ol-attribution .ol-unselectable .ol-control .ol-collapsed {
    display: none;
  }

  #map .ol-rotate .ol-unselectable .ol-control .ol-hidden{
    display:none;
  }

  #map .ol-rotate-reset{
    display:none;
  }

  #map .ol-attribution {
  display: none;
  } 
`;

export default GlobalStyle;
