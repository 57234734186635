import styled from 'styled-components';
import { colors } from 'constants/colors';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.black};
  z-index: 1000;
  
  .innerContainer {
    position: absolute;
    width: 100%;
    max-width: 1229px;
    padding: 0 31px;
    display: flex;
    align-items: center;
    line-height: 56px;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      padding: 0 13px;
      min-width: 320px;
    }
  }

  .navTitle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .HeaderContent {
    width: 80%;
    display: flex;
    justify-content: right;
    margin-right: 12px;

    .HeaderLink {
      font-size: 16px;
      font-weight: bold;
      color: ${colors.white};
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .Header {
    height: 44px;
    min-width: 60px;
    border-radius: 22px;
    box-sizing: border-box;
    padding: 0 16px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${colors.menuGrey};
  }

  .menuContainer {
    position: absolute;
    top: 6px;
    right: 31px;
    width: 93px;
    height: 44px;
  }

  @media only screen and (max-width: 767px) {
    .HeaderContent {
      display: none;
    }
  }
`;

type HotelTitleProps = {
  fontSize?: number;
};

export const HotelTitle = styled.div<HotelTitleProps>`
  display: flex;
  font-size: ${(props) => (props.fontSize? `${props.fontSize}px` : '22px')};
  font-weight: 300;
  color: ${colors.white};
  text-align: center;
  cursor: pointer;
  span {
    font-weight: 600;
    margin-right: 5px;
    margin-left: 5px;
  }
`;