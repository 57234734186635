import React, { FC, useState } from 'react';
import ScreenLayout from 'components/ScreenLayout';
import ResetSection from './components/ResetSection';

const SignInScreen: FC = () => {

  return (
    <ScreenLayout disableModal>
      <ResetSection />
    </ScreenLayout>
    )
}

export default SignInScreen;
