import React, { FC } from "react";
import { ProfileContainer } from "./style";
import { Icon } from "components/Icon";
import { useHistory } from 'react-router-dom'


import {
  PersonalEmailIcon,
  PersonalPhoneIcon,
  ArrowRightAccount,
  ContactInformation,
} from "assets/icons";

const Profile: FC = () => {
  const history = useHistory();
  return (
    <ProfileContainer>
      <div className="accountBody">
        <div className="cardContent">
          <div className="topText">
            It is important to review and verify that all details are correct
            and current. Keeping your information accurate ensures you receive
            important updates and notifications without any issues. Please make
            any necessary updates to reflect your latest contact information.
          </div>

          <div className="subsectionLinks">
            <div className="subsectionLink"
            onClick={() => history.push(`/account/personalDetails`)}
            >
              <div className="subsectionLinkBody">
                <div className="subsectionLinkBodyHeader">
                  <div className="subsectionLinkBodyTitle">
                    <Icon className="iconContactInformation itemIcon">
                      <ContactInformation />
                    </Icon>
                    <span>Personal Details</span>
                  </div>
                </div>
                <div className="subsectionLinkBodyText">
                  It is important to review and verify that all details are
                  correct and current. Keeping your informatio
                </div>
              </div>
              <div className="subsectionLinkArrow">
                <Icon className="arrowRightAccount" width={7} height={12}>
                  <ArrowRightAccount />
                </Icon>
              </div>
            </div>

            <div className="subsectionLink"
            onClick={() => history.push(`/account/email`)}
            >
              <div className="subsectionLinkBody">
                <div className="subsectionLinkBodyHeader">
                  <div className="subsectionLinkBodyTitle">
                    <Icon className="itemIcon iconPersonalEmailIcon">
                      <PersonalEmailIcon />
                    </Icon>
                    <span>Email Address</span>
                  </div>
                </div>
                <div className="subsectionLinkBodyText">
                  It is important to review and verify that all details are
                  correct and current. Keeping your informatio
                </div>
              </div>
              <div className="subsectionLinkArrow">
                <Icon className="arrowRightAccount" width={7} height={12}>
                  <ArrowRightAccount />
                </Icon>
              </div>
            </div>

            <div className="subsectionLink"
            onClick={() => history.push(`/account/phoneNumber`)}
            >
              <div className="subsectionLinkBody">
                <div className="subsectionLinkBodyHeader">
                  <div className="subsectionLinkBodyTitle">
                    <Icon className="itemIcon iconPersonalPhoneIcon">
                      <PersonalPhoneIcon />
                    </Icon>
                    <span>Phone Number</span>
                  </div>
                </div>
                <div className="subsectionLinkBodyText">
                  It is important to review and verify that all details are
                  correct and current. Keeping your informatio
                </div>
              </div>
              <div className="subsectionLinkArrow">
                <Icon className="arrowRightAccount" width={7} height={12}>
                  <ArrowRightAccount />
                </Icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProfileContainer>
  );
};

export default Profile;
