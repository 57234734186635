import styled from 'styled-components';
import { colors } from 'constants/colors';

export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${colors.white};
  border-radius: 8px;
  z-index: 200;
  width: 100%;
  max-width: 90%;
  min-width: 310px;

  .closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .cardTitle {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 16px;
    border-bottom: 1px solid ${colors.borderGrey2};
  }

  .contentTitle {
    width: 80%;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 32px 0 24px 0;
  }

  .description {
    width: 100%;
    font-size: 16px;
    margin-bottom: 8px;

    .warning {
      color: ${colors.red};
    }
  }
`;

export const CancelModalContainer = styled(BasicModalContainer)`

  max-width: 490px;
  min-height: 495px;
  padding: 16px 32px;

  .textareaContainer {
    width: 100%;
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    margin: 10px 0 0 0;
    justify-content: flex-end;
    align-items: center;
    column-gap: 24px;
    .link{
      font-size: 16px;
      font-weight: bold;
      color: ${colors.hoverBlue};
      text-decoration: underline;
      cursor: pointer;
    }
  }


`;