import React from 'react';
import Card from 'components/Card';
import { AddOnCardContainer } from './style';
import { SwitchOffIcon, SwitchOnIcon, GreenCheckIcon } from 'assets/icons';

export type AddOnCardProps = {
  title: string;
  attributes: string[];
  checked: boolean;
  setChecked: () => void;
  price: number;
  isOneTimePriced: boolean;
}

const AddOnCard: React.FC<AddOnCardProps> = ({ 
  title,
  attributes,
  checked,
  setChecked,
  price,
  isOneTimePriced
}) => {

  return (
    <Card>
      <AddOnCardContainer
        onClick={setChecked}
      >
        <div className="optionCardTitle">
          {title}
        </div>
        <div className="optionCardAttributes">
          {
            attributes.map((attribute, index) => {
              return (
                <div key={index} className="roomAttribute">
                  <GreenCheckIcon />
                  {attribute}
                </div>
              )
            })
          }
        </div>
        <div className="optionCardPrice">
          <div className={`switch ${checked? 'active': ''}`}>
            <div className={`switchSlider ${checked? 'active': ''}`}>
            {checked? <SwitchOnIcon />: <SwitchOffIcon />}
            </div>
          </div>
          <div className="price">
            {`+ $${price.toFixed(2)}`}
          </div>
          <div className="explanation">
            {`per room, ${isOneTimePriced? '': 'per night, '} not including tax / fees`}
          </div>
        </div>
      </AddOnCardContainer>
    </Card>
  )
}
export default AddOnCard;
