import React, { FC, useEffect } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import Vector from 'ol/source/Vector';
import StadiaMaps from 'ol/source/StadiaMaps';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import * as proj from 'ol/proj';
import * as style from 'ol/style';
import * as geom from 'ol/geom';
import { defaults as defaultInteractions } from 'ol/interaction.js';
import { fromLonLat } from 'ol/proj';
import icon from 'assets/icons/hotelFooter.svg';
import { FooterMapContainer } from './style';

const FooterMap: FC = () => {
  useEffect(() => {
    const map = new Map({
      layers: [
        new TileLayer({
          source: new StadiaMaps({
            layer: 'stamen_toner',
            apiKey: '01df85c8-40e1-4a9b-94e8-e60bab083807',
          }),
          opacity: 0.3,
        }),
      ],
      target: 'map',
      view: new View({
        center: fromLonLat([-79.63345228944864, 43.59388064879]),
        zoom: 14,
      }),
      interactions: defaultInteractions({
        doubleClickZoom: false,
        dragPan: false,
        mouseWheelZoom: false,
      }),
    });

    const center = [-79.633827, 43.592191];
    const markers = new VectorLayer({
      source: new Vector(),
      style: new style.Style({
        image: new style.Icon({
          anchor: [0.5, 1],
          src: icon,
        }),
      }),
    });
    const marker = new Feature(new geom.Point(proj.fromLonLat(center)));
    markers.getSource()?.addFeature(marker);
    map.addLayer(markers);

    const popup = new Overlay({
      element: document.getElementById('popup') as HTMLElement,
      position: fromLonLat(center),
    });
    map.addOverlay(popup);
  }, []);

  return (
    <FooterMapContainer id="map" className="map">
      {/* <FooterInfoContainer id="popup">
        <HotelTitle fontSize={28}>
          <span>ROOK</span> HOTEL
        </HotelTitle>
        <div className="footerDescription">3660 Hurontario St.</div>
        <div className="footerDescription">Mississauga, ON</div>
        <div className="footerDescription">+1 (905) 949-4000</div>
        <a href='https://www.google.com/maps?cid=9892775027598466451' target="_blank">view on map</a>
      </FooterInfoContainer> */}
    </FooterMapContainer>
  );
};

export default FooterMap;
