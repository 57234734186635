import { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PhoneNumberInput from 'components/PhoneNumberInput'
import VerificationInputField from 'components/VerificationInputField'
import { useAuth } from 'customHooks/useAuth'
import { CompleteContentContainer } from './style';
import StyledButton from 'components/Button';
import { useMutation } from '@apollo/client';
import { SEND_PHONE_NUMBER_VERIFICATION_CODE, VERIFY_PHONE_NUMBER } from 'graphql/service';

const CompleteContent: FC = () => {

  const history = useHistory()
  const { validToken, userId } = useAuth()

  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [phoneNumberSubmitted, setPhoneNumberSubmitted] = useState(false)

  const CODE_LENGTH = 6;
  const [verificationCode, setVerificationCode] = useState<string[]>(Array(CODE_LENGTH).fill(''));

  useEffect(() => {
    if(phoneNumberValidity) {
      setPhoneNumberError(false)
    }
  }, [phoneNumberValidity, setPhoneNumberError])

  const [sendPhoneNumberVerificationCode] = useMutation(
    SEND_PHONE_NUMBER_VERIFICATION_CODE,
    {
      onCompleted: (sendPhoneNumberVerificationCodeData)=> {
        if(sendPhoneNumberVerificationCodeData.sendPhoneNumberVerificationSMS) {
          setPhoneNumberSubmitted(true)
        } else {
          const content = 'Send phone number verification code failed!'
          console.log(content)
        }
      },
      onError: (error)=> {
        const content = error.message
        console.log(content)
      }
    }
  )

  const [verifyPhoneNumber] = useMutation(
    VERIFY_PHONE_NUMBER,
    {
      onCompleted: (verifyPhoneNumberData)=> {
        if(verifyPhoneNumberData.verifyPhoneNumber) {          
          history.push('/account/dashboard')
        } else {
          const content = 'Verification failed!'
          console.log(content)
        }
      },
      onError: (error)=> {
        const content = error.message
        console.log(content)
      }
    }
  )

  const handleSubmit = () => {
    if(validToken) {
      sendPhoneNumberVerificationCode({
        variables: {
          input: {
            phoneNumber,
            userId,
          }
        }
      })
    } else {
      console.log('Session expired, please try again!')
      history.push('/signIn')
    }
  }

  const handleVerifyPhoneNumber = () => {
    if(validToken) {
      verifyPhoneNumber({
        variables: {
          input: {
            phoneNumber,
            token: verificationCode.join(''),
          }
        }
      })
    } else {
      console.log('Session expired, please try again!')
      history.push('/signIn')
    }
  }

  return (
    <CompleteContentContainer>
      <div className="titleContainer">
        <div className="innerContainer">
          <div className='titleText'>
            <div className="title">
              <div>Unlock your stay! <span className='highLight'>Verify your number</span> for</div>
              <div>seamless access and security.</div>
            </div>
          </div>
          <div className="setPhoneNumberImage" />
        </div>
      </div>
      <div className="inputRow">
        {!phoneNumberSubmitted? (
          <div className="inputContainer">
            <div className="description">
              Please enter <span className='highLight'>your phone number</span> to link it to your account. This will help us verify your identity, make check-ins a breeze at our kiosks, and assist you in recovering your account if you forget your password. After entering your number, you’ll receive <span className='highLight'>a 6-digit code</span> to confirm it’s valid.
            </div>
            <PhoneNumberInput
              className='phoneNumberInput'
              label="PHONE NUMBER"
              isRequired={true}
              labelPosition='left'
              error={!phoneNumberValidity && phoneNumberError}
              errorMessage='Please enter a valid phone number'
              inputHeight="43px"
              width='60%'
              marginBottom={'20px'}
              onChangeNumber={setPhoneNumber}
              setPhoneNumberValidity={setPhoneNumberValidity}
              onBlur={() => {
                if(!phoneNumberValidity&&phoneNumber.length<3) {
                  setPhoneNumberError(true)
                }
              }}
            />
            <StyledButton
              width="114px"
              height="48px"
              disabled={phoneNumberError}
              margin={'10px 0 0 0'}
              onClick={handleSubmit}
            >
              Submit
            </StyledButton>
          </div>
      ): (
        <div className="verifyContainer">
          <div className='verifyTitle'>
            <div>Verify phone number</div>
          </div>
          <div className="description">
            <div>A verification code has been sent to your current phone number ending in {phoneNumber.substring(phoneNumber.length - 4)}. Please enter the code below to confirm your update:</div>
          </div>
          <VerificationInputField
            label="Verification Code"
            isRequired
            margin='20px 0'
            width='100%'
            length={CODE_LENGTH}
            values={verificationCode}
            setValues={setVerificationCode}
          />
          <StyledButton
            width="196px"
            height="48px"
            disabled={verificationCode.length !== 6}
            margin={'10px 0'}
            onClick={handleVerifyPhoneNumber}
          >
            Verify Phone Number
          </StyledButton>
          <div className="description">
            <div>Didn’t receive the code?
              <span
                className='highLight'
                onClick={handleSubmit}
              >
                Resend it
              </span>
              .
            </div>
          </div>
        </div>
      )}
      </div>
    </CompleteContentContainer>
  )
}

export default CompleteContent;