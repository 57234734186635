import { colors } from "constants/colors";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 56px;
  z-index: 300;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 15px;

    .modal-title {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
    }

    .close-icon {
      display: flex;
      column-gap: 10px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      color: ${colors.red};
      cursor: pointer;
    }
  }
`;

export const SummaryContent = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto; 

  .select-room {
    margin-top: 58px;
  }
`;


export const SelectRoomContainer = styled.div`
  .content {
    background-color: ${colors.white};
    width: 100%;
    height: 153px;
    z-index: 240;

    .guestsOption {
      border-bottom: 1px solid ${colors.borderGrey};
    }

    .guestsOption, .roomsOption {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 76px;
      border-bottom: 1px solid ${colors.borderGrey};

      .text {
        .optionTitle {
          font-size: 14px;
          font-weight: 600;
        }
        .optionDescription {
          font-size: 16px;
          font-style: italic;
          color: ${colors.borderGrey};
        }
      }

      .quantity {
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 21px;
        font-weight: 600;
      }

      .optionButtons {
        display: flex;
      }

      .disabled {
        g, line {
          stroke: ${colors.signInBorderColor};
        }
      }
    }
  }

  .outerContainer {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
  }
`;

export const RatePlanContainer = styled.div`
  width: 100%;
  padding: 0 0 5px 0;
  border-bottom: 1px solid ${colors.borderGrey};
  margin: 10px 0;

  .infoLabel {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.black};
    text-align: left;
    margin: 0 0 5px 0;
  }

  .infoValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: ${colors.black};

    svg {
      cursor: pointer;
    }
  }
`;

export const AddOnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;

  .addOnOption {
    border-bottom: 1px solid ${colors.borderGrey};
    padding: 5px 0;
    margin: 10px 0;

    .row{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        font-size: 14px;
        font-weight: 600;
      }

      .remove {
        font-size: 16px;
        font-weight: bold;
        color: ${colors.red};
        cursor: pointer;

        .removeIcon {
          margin-left: 5px;
          height: 10px;
          width: 10px;
        }
      }
    }
  }
`

export const BottomButtonContainer = styled.div`
  width: 100%;
  padding: 15px 29px 15px 0px;
  position: fixed;
  bottom: 0;
  margin-top: 20px;
`;

export const CouponContainer = styled.div`

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;

  label {
    color: ${colors.black};
  }

  .applyCouponButton {
    margin: 5px 0 0 0;
  }
`;

export const SubtotalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  padding-bottom: 16px;
  margin: 15px 0 100px 0;

  .total{
      font-size: 20px;
  }
`;