import React, { FC } from 'react';
import Slider from 'components/Slider';
import { SlideContentContainer } from './style';
import SlideContentItem from './SlideContentItem';
import stretchMedium from 'assets/images/stretch-medium.jpg';
import sanitizedMedium from 'assets/images/sanitized-medium.jpg';
import workingMedium from 'assets/images/working-medium.jpg';
import popcornMedium from 'assets/images/popcorn-medium.jpg';
import luggageMedium from 'assets/images/luggage-medium.jpg';

const SlideContent: FC = () => {

  const sources = [
    <SlideContentItem
      key={0}
      title={['best', 'sleep', 'ever']}
      description="All our rooms feature luxury Serta Diamond Suite mattresses, constructed with coil spring spinal support and cooling gel foam for increased airflow."
      descriptionLayout="max-width: 218px; margin-left: 70px;"
      imageSource={stretchMedium}
    />,
    <SlideContentItem
      key={1}
      reverse
      title={['your', 'health', 'prioritized']}
      description="Our hotel uses ozone-generating technology which effectively eliminates viruses, fungi and bacteria from surfaces and the air."
      descriptionLayout="max-width: 280px;"
      imageSource={sanitizedMedium}
      lastTitle
      titleThreeLayout="margin-left:10px; font-size:75px;"
    />,
    <SlideContentItem
      key={2}
      title={['fiber', 'optic', 'speed']}
      description="Our hotel’s lightning-fast, fiber optic, unthrottled internet is the fastest internet in any hotel in the city, guaranteed."
      descriptionLayout="max-width: 245px; margin-left: 95px;"
      imageSource={workingMedium}
    />,
    <SlideContentItem
      key={3}
      reverse
      title={['just add', 'popcorn']}
      description="Our Roomcast system allows guests to stream content directly to the room’s television from their own devices."
      imageSource={popcornMedium}
      imageWidth="800px"
      titleOneLayout="margin-top:45px;"
      titleThreeLayout="margin-left:60px;"
      descriptionLayout="max-width: 280px; margin-left: 30px;"
    />,
    <SlideContentItem
      key={4}
      isLast
      imageWidth="800px"
      title={['executive', 'group', 'rates']}
      description="Contact us for special group, day and business rates."
      imageSource={luggageMedium}
      descriptionLayout="max-width:245px; margin-left: 65px;"
    />,
    <SlideContentItem
      key={6}
      title={['best', 'sleep', 'ever']}
      description="All our rooms feature luxury Serta Diamond Suite mattresses, constructed with coil spring spinal support and cooling gel foam for increased airflow."
      descriptionLayout="max-width: 218px; margin-left: 70px;"
      imageSource={stretchMedium}
    />
  ]

  return (
    <SlideContentContainer>
      <div className="innerContainer">
        <div className="slideSectionTitle">Hotel Features</div>
        <Slider sources={sources} />
      </div>
    </SlideContentContainer>
  );
}

export default SlideContent;
