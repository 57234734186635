import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AccountContentContainer } from './style';
import AddOnCard from 'components/AddOnCard';
import Card from 'components/Card';
import StyledButton from 'components/Button';
import { BookingAddOn } from 'states/OptionStates/atoms';
import { useGetBookingAddOns } from 'customHooks/useGetBookingAddOns';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import moment from 'moment';

const AddOnsContent: FC = () => {

  const { trips } = useLoadTrips()
  const bookingAddOns = useGetBookingAddOns()
  const { groupId } = useParams<{groupId: string}>()
  const trip = trips[groupId]
  const tripAddOns = trip?.addOns?.map((groupSubscribedAddOn) => groupSubscribedAddOn.bookingAddOn.addOnId) ?? []
  console.log(tripAddOns)
  const [addOnOptions, setAddOnOptions] = useState(tripAddOns)
  const [addOnsChanged, setAddOnsChanged] = useState(false)

  const compareArrays = (arr1, arr2)=> {

    const extraInArr1 = arr1.slice();
    const missingInArr1 = arr2.slice();

    arr1.forEach((item, index) => {
        const indexInArr2 = missingInArr1.indexOf(item);
        if (indexInArr2 !== -1) {
          missingInArr1.splice(indexInArr2, 1);
          extraInArr1.splice(index, 1);
        }
    });

    const isEqual = extraInArr1.length === 0 && missingInArr1.length === 0;

    return {
      extraInArr1,
      missingInArr1,
      isEqual
    };
  }

  const setChecked = (addOnId: string) => {
    if (addOnOptions.includes(addOnId)) {
      const index = addOnOptions.indexOf(addOnId)
      const newAddOnOptions = [...addOnOptions]
      newAddOnOptions.splice(index, 1)
      if (compareArrays(newAddOnOptions, tripAddOns).isEqual) {
        setAddOnsChanged(false)
      } else {
        setAddOnsChanged(true)
      }
      setAddOnOptions(newAddOnOptions)
    } else {
      const newAddOnOptions = [...addOnOptions]
      newAddOnOptions.push(addOnId)
      setAddOnOptions(newAddOnOptions)
      if (compareArrays(newAddOnOptions, tripAddOns).isEqual) {
        setAddOnsChanged(false)
      } else {  
        setAddOnsChanged(true)
      }
    }
  }

  const optionArray = bookingAddOns.map((addOn: BookingAddOn) => {
      return (
        <AddOnCard
          key={addOn.addOnId}
          title={addOn.title}
          attributes={addOn.description}
          checked={addOnOptions.includes(addOn.addOnId)}
          setChecked={() => setChecked(addOn.addOnId)}
          isOneTimePriced={addOn.isOneTimePriced}
          price={addOn.pricePerNight}
        />
      )
  })

  const addedArray = compareArrays(addOnOptions, tripAddOns).extraInArr1.map((addOnId) => {
    const addOn = bookingAddOns.find((addOn) => addOn.addOnId === addOnId)
    return(
      <div key={addOnId} className="addOnChange">
        {addOn?.label + ' (added)'} <span className={'success'}>' +$'</span> {addOn?.pricePerNight+ '/night'}
      </div>
    )
  })

  const removedArray = compareArrays(addOnOptions, tripAddOns).missingInArr1.map((addOnId) => {
    const addOn = bookingAddOns.find((addOn) => addOn.addOnId === addOnId)
    return (
      <div key={addOnId} className="addOnChange">
        {addOn?.label + ' (added)'} <span className={'warning'}>{` -$${addOn?.pricePerNight}`}</span> {addOn?.pricePerNight+ '/night'}
      </div>
    )

  })

  return (
    <AccountContentContainer>
      <div className="optionCardsContainer">
        {optionArray}
      </div>
      {
        addOnsChanged? (
          <Card 
            padding="32px"
          >
            <div className="cardTitle">DATES</div>
            <div className="description">
              {
                moment(trip.bookings?.reduce((min, current) => {
                  return current.checkInDate < min? current.checkInDate: min;
                }, trip.bookings[0]?.checkInDate)).format('ddd DD MMM') + ' - ' +
                moment(trip.bookings?.reduce((max, current) => {
                  return current.checkOutDate > max? current.checkOutDate: max;
                }, trip.bookings[0]?.checkOutDate)).format('ddd DD MMM')
              }
            </div>
            <div className="cardTitle">Your Add-ons:</div>
            <div className="addOnsChanges">
              {addedArray}
              {removedArray}
            </div>
            <div className="buttonContainer">
              <StyledButton
                width="98px"
                height="48px"
                margin='32px 0 0 0'
                onClick={() => {
                  setAddOnsChanged(false)
                }}
              >
                Submit
              </StyledButton>
            </div>
          </Card>
        ):null
      }
    </AccountContentContainer>
  );
};

export default AddOnsContent;