import React, { useState, FC } from 'react'
import { useHistory } from 'react-router-dom'
import StyledButton from 'components/Button'
import FormInputField from 'components/FormInputField'
import FooterMap from 'components/FooterMap'
import useWindowSize from 'customHooks/useWindowSize'
import { useAuth } from 'customHooks/useAuth';
import { useMutation } from '@apollo/client';
import { SUBSCRIBE } from 'graphql/service';
import { 
  FooterContainer,
  FooterFormContainer,
  FooterDetailsContainer,
  FooterBottomContainer,
  FooterBox
} from './style'
import { FacebookIcon, TwitterIcon, InstagramIcon, BigLogo } from 'assets/icons'

type FooterProps = {
  disableFooter?: boolean;
  disableMap?: boolean;
};

const Footer: FC<FooterProps> = ({ disableFooter, disableMap }) => {

  const { validToken } = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const { width } = useWindowSize()

  const [subscribe, { data: subscribeData }] = useMutation(
    SUBSCRIBE,
    {
      onCompleted: (subscribeData)=> {
        if(subscribeData.createEmailSubscription?.id) {
          console.log('Thank you for subscribing')
        } else {
          const content = 'Subscribe failed!'
          console.log(content)
        }
      },
      onError: (error)=> {
        const content = error.message
        console.log(content)
      }
    }
  )

  const handleSubscribe = () => {
    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      subscribe({
        variables: {
          input: {
            email,
            subscriptionTypeIds: ['5f705113-b914-4cee-b503-72c476ae27ee']
          }
        }
      })
    } else {
      const content = 'Invalid email address'
      console.log(content)
    }
  }

  return (
    <FooterBox>
      {!disableFooter && width > 767 ? (
        <FooterContainer>
          {disableMap? null:<FooterMap />}
          {!validToken?
            <FooterFormContainer centerWidth="40%" centerHeight="40%">
              <div className="footerContentContainer">
                <div className="footerSubscribeContainer">
                  <div className="footerSubscribeTitle">
                    Receive a 5% discount on your next stay at Rook Hotel
                  </div>
                  <div className="footerSubscribeDescription">
                    Sign up to our mailing list for an instant discount code!
                  </div>
                  <div className="footerInputContainer">
                    <FormInputField
                      className="footerInput"
                      placeholder="Your email"
                      marginBottom='0'
                      inputHeight='48px'
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <StyledButton
                      className="footerSignUpButton"
                      width="98px"
                      height="48px"
                      onClick={handleSubscribe}
                    >
                      Sign up
                    </StyledButton>
                  </div>
                </div>
              </div>
            </FooterFormContainer>
            : 
            <FooterFormContainer centerWidth="100%" centerHeight="100%">
              <div className="innerContainer">
                <div className="footerContentContainer">
                  <div className="footerHelpContainer">
                    <div className="footerHelpTitle">
                      Get Help During Your Stay
                    </div>
                    <div className="footerHelpDescription">
                      Whether you have a question, need booking assistance, or just want some local tips, our Help Center is here for you. Explore our resources or reach out directly to ensure your stay is nothing short of exceptional!
                    </div>
                    <StyledButton
                      width="162px"
                      height="48px"
                      margin="24px 0 16px 0"
                      onClick={() => {
                        history.push('/help')
                      }}
                    >
                      Need Assistance
                    </StyledButton>
                  </div>
                  <div className="footerHelpImage"/>
                </div>
              </div>
            </FooterFormContainer>
          }
          <FooterDetailsContainer>
            <div className="innerContainer">
              <div className="footerDetails">
                <div className="footerDetail">
                  <div className="footerDetailsTitle">About Us</div>
                  <div className="footerDetailsContent">
                    <div>
                      <a href='/' >Home</a>        
                    </div>
                    <div>
                      <a href='/about' >About Rook Hotel</a>        
                    </div>
                    <div>
                      <a href='/contact' >Contact Us</a>        
                    </div>
                    <div>
                      <a href='/discover' >Discover</a>        
                    </div>
                  </div>
                </div>
                <div className="footerDetail">
                  <div className="footerDetailsTitle">Contact Information</div>
                  <div className="footerDetailsContent">
                    <div><span className='highLight'>Phone: </span><a href='tel:+19059494000'> +1 (905) 949-4000</a></div>
                    <div><span className='highLight'>Email: </span><a href="mailto:info@rookhotel.com"> info@rookhotel.com</a></div>
                  </div>
                </div>
                <div className="footerDetail">
                  <div className="footerDetailsTitle">Address</div>
                  <div
                    className="footerDetailsContent address"
                    onClick={() => {
                      window.open('https://www.google.com/maps?cid=9892775027598466451', '_blank')
                    }}
                  >
                    <div>3660 Hurontario St.</div>
                    <div>Mississauga, ON</div>
                  </div>
                </div>
                <div className="logoContainer">
                  <div className='bigLogo'>
                    <BigLogo />
                  </div>
                  <StyledButton
                    width="153px"
                    height="48px" 
                    onClick={() => {
                      window.open('https://www.google.com/maps?cid=9892775027598466451', '_blank')
                    }}
                  >
                    View on Map
                  </StyledButton>
                </div>
              </div>

            </div>
          </FooterDetailsContainer>
          <FooterBottomContainer>
            <div className='innerContainer'>
              <div className="footerBottom">
                <div className="footerBottomIcons">
                  <div className='socialMedia'>
                    <a href="https://www.facebook.com/rookhotel" target="_blank">
                      <FacebookIcon />
                    </a>
                    <a href="https://twitter.com/rookhotel" target="_blank">
                      <TwitterIcon />
                    </a>
                    <a href="https://www.instagram.com/rookhotel" target="_blank">
                      <InstagramIcon />
                    </a>
                  </div>
                </div>
                <div className="footerBottomContent">
                  <div className='policy'>
                    <span
                      className='terms'
                      onClick={() => {
                        history.push('/termsAndConditions')
                      }}
                    >
                      Terms
                    </span>
                    <span
                      className='privacy'
                      onClick={() => {
                        history.push('/termsAndConditions')
                      }}
                    >
                      Privacy
                    </span>
                  </div>
                  <div>© 2024 Rook Hotel . All Rights Reserved.</div>
                </div>
              </div>
            </div>
          </FooterBottomContainer>
        </FooterContainer>
      ) : null}
    </FooterBox>
  )
}

export default Footer;
