import React from 'react';
import { useHistory } from 'react-router-dom';
import ScreenLayout from 'components/ScreenLayout';
import RoomOptionsContent from './components';
import MobileFooter from 'components/MobileFooter';

const RoomOptionsScreen: React.FC = () => {

  const history = useHistory();

  const handleContinueBooking = () => {
    //history.push('/addOnOptions');
    history.push('/checkOut');
  }

  return (
    <ScreenLayout>
      <RoomOptionsContent />
      <MobileFooter handleContinueBooking={handleContinueBooking}/>
    </ScreenLayout>
  );
}

export default RoomOptionsScreen;
