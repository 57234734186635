import React from 'react';
import { AddOnOptionsContainer } from './style';
import AddOnCard from 'components/AddOnCard';
import { useRecoilState } from 'recoil';
import { addOnOptionsState } from 'states/OptionStates/atoms';
import { BookingAddOn } from 'states/OptionStates/atoms';
import { useGetBookingAddOns } from 'customHooks/useGetBookingAddOns';

const AddOnOptions: React.FC = () => {

  const [addOnOptions, setAddOnOptions] = useRecoilState(addOnOptionsState)
  const bookingAddOns = useGetBookingAddOns()

  const setChecked = (addOnId: string) => {
    if (addOnOptions.includes(addOnId)) {
      const index = addOnOptions.indexOf(addOnId)
      const newAddOnOptions = [...addOnOptions]
      newAddOnOptions.splice(index, 1)
      setAddOnOptions(newAddOnOptions)
      localStorage.setItem("addOnOptions", JSON.stringify(newAddOnOptions))
    } else {
      setAddOnOptions([...addOnOptions, addOnId])
      localStorage.setItem("addOnOptions", JSON.stringify([...addOnOptions, addOnId]))
    }
  }

  const optionArray = bookingAddOns.map((addOn: BookingAddOn) => {
      return (
        <AddOnCard
          key={addOn.addOnId}
          title={addOn.title}
          attributes={addOn.description}
          checked={addOnOptions.includes(addOn.addOnId)}
          setChecked={() => setChecked(addOn.addOnId)}
          isOneTimePriced={addOn.isOneTimePriced}
          price={addOn.pricePerNight}
        />
      )
  })

  return (
    <AddOnOptionsContainer>
      <div className="addOnOptionsTitle">
        Add-ons:
      </div>
      <div className="optionCardsContainer">
        {optionArray}
      </div>
    </AddOnOptionsContainer>
  );
}
export default AddOnOptions;
