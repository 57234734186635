import React, { FC } from 'react';
import { AccountContentContainer } from './style';
import Card from 'components/Card';

const DashboardContent: FC = () => {

  return (
    <AccountContentContainer>
      <Card
        padding='32px'
      >
        Dashboard
      </Card>
    </AccountContentContainer>
  );
};

export default DashboardContent;
