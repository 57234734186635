/* eslint-disable @typescript-eslint/ban-types */
type maskProps = {
  mask: string | (string | RegExp)[];
  maskPlaceholder?: string;
  formatChars?: object;
};

export const creditCardMask: maskProps = {
  mask: '9999 9999 9999 9999',
};

export const expireDateMask: maskProps = {
  mask: [/[0-1]/, /[0-9]/, "/", /[2-9]/, /[0-9]/],
};

export const cvvMask: maskProps = {
  mask: '999',
};
