import React, { ReactNode } from 'react'
import { CardContainer } from './style'

type CardProps = {
  selected?: boolean;
  padding?: string;
  pointer?: boolean;
  children: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Card: React.FC<CardProps> = ({ selected, children, padding, pointer, ...rest }) => {

  return (
    <CardContainer
      className='card'
      padding={padding}
      pointer={pointer}
      selected={selected}
      {...rest}
    >
      {children}
    </CardContainer>  
  )
}

export default Card;
