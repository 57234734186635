import styled from 'styled-components';
import { colors } from 'constants/colors';
import CheckMarkIcon from 'assets/icons/checkMark.svg';

export const SearchBarContainer = styled.div`
  width: 94%;
  min-height: 84px;
  background-color: ${colors.searchBarBackground};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 17px;
  top: 15px;
  margin: 0 3%;
  z-index: 200;

  .searchBarButton {
    flex: 1;
    max-width: 138px;
    min-width: 100px;
    height: 55px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    margin: 0;
    width: 100%;

    .searchBarButton {
      height: 49px;
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 767px) {

    margin: 0;
    width: 100%;
    top: 0;
    height: 265px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 20px 15px;

    .searchBarButton {
      max-width: 100%;
      width: 100%;
      height: 52px;
    }
  }
`;

export const DatePickersContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 333px;
  min-width: 220px;
  height: 55px;
  padding: 0px 10px;
  background-color: ${colors.white};
  border: 1px solid ${colors.borderGrey};
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 21px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 3px 10px 0px 10px;
    margin-right: 2%;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0px;
    padding: 10px;
    margin-bottom: 10px;
  }
`;

export const GuestsAndRoomsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 372px;
  min-width: 200px;
  height: 55px;
  padding: 0px 10px;
  background-color: ${colors.white};
  border: 1px solid ${colors.borderGrey};
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 50px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    padding: 3px 10px 0px 10px;
    margin-right: 2%;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0px;
    padding: 10px;
  }

  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    label {
      font-size: 10px;
      height: 16px;
      font-weight: bold;
      color: ${colors.borderGrey};
    }

    input {
      width: 100%;
      height: 20px;
      line-height: 20px;
      padding: 0;
      margin: 0;
      border: none;
      position: relative;
      color: ${colors.userSelectionBlack};
      cursor: pointer;
      pointer-events: none;
      font-family: 'Titillium Web', sans-serif;
      text-align: left;

      &:focus {
        outline: none;
      }
    }

    @media only screen and (max-width: 767px) {
      label {
        font-size: 12px;
      }
      input {
        font-size: 15px;
        line-height: 23px;
        height: 23px;
      }
    }
  }
`;

export const GuestsAndRoomsOptionsContainer = styled.div<{isActive: boolean}>`
  display: ${({isActive}) => isActive? "block" : "none"};

  .content {
    position: absolute;
    top: 69px;
    left: 0px;
    background-color: ${colors.white};
    width: 100%;
    height: 153px;
    box-shadow: 0 0 5px 0 ${colors.borderGrey2};
    z-index: 2000;

    .guestsOption {
      border-bottom: 1px solid ${colors.borderGrey};
    }

    .guestsOption, .roomsOption {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      height: 76px;

      .text {
        .optionTitle {
          font-size: 14px;
          font-weight: 600;
        }
        .optionDescription {
          font-size: 16px;
          font-style: italic;
          color: ${colors.borderGrey};
        }
      }

      .quantity {
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 21px;
        font-weight: 600;
      }

      .optionButtons {
        display: flex;
      }

      .disabled {
        g, line {
          stroke: ${colors.signInBorderColor};
        }
      }
    }
  }

  .outerContainer {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    .content{
      .guestsOption, .roomsOption {
        .optionButtons {
          width: 30%;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const MobileGuestsAndRoomsOptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  box-sizing: border-box;
  position: fixed;
  top: 56px;
  z-index: 200;

  .modal-title {
    font-size: 30px;
    margin: 0;
  }

  .close-icon {
    margin-right: 25px;
    font-size: 8px;
  }

  .closeButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    margin: 14px;
    margin-right: 0px;
    font-weight: bold;
  }

  .guestsOption {
    margin-top: 42.6px;
  }

  .guestsOption, .roomsOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    height: 76px;
    border-bottom: 1px solid ${colors.borderGrey};

    .optionTitle {
      font-size: 14px;
      font-weight: semibold;
    }
    .subtitle {
      font-size: 16px;
      font-style: italic;
      color: ${colors.grey};
    }

    .quantity {
      text-align: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 21px;
      font-weight: 600;
    }

    .optionButtons {
      display: flex;
    }

    .disabled {
      g, line {
        stroke: ${colors.signInBorderColor};
      }
    }
  }
`;

export const BottomButtonContainer = styled.div`
  width: 100%;
  padding: 23px 29px 31px 29px;
  height: 141px;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.21);
`;

export const CheckBoxesContainer = styled.div`
  flex: 1;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    margin-right: 2%;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    margin-right: 0px;
  }
`;

export const CheckBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  label input {
    display: none; /* Hide the default checkbox */
  }

  /* Style the artificial checkbox */
  label span {
    height: 25px;
    width: 25px;
    border: 1px solid ${colors.borderGrey};
    display: inline-block;
    position: relative;
    margin-right: 10px;
    margin-top: 5px;
    background-color: ${colors.white};
  }

  /* Style its checked state...with a ticked icon */
  [type='checkbox']:checked + span:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 20px;
    height: 20px;
    background: url(${CheckMarkIcon}) no-repeat center center;
  }

  .checkBoxIcon {
    width: 20px;
    height: 20px;
  }

  .checkBoxLabel {
    font-size: 14px;
    color: ${colors.userSelectionBlack};
    margin-left: 5px;
    cursor: pointer;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {
    .checkBoxLabel {
      font-size: 12px;
      margin-left: 5px;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    justify-content: flex-start;    
    .checkBoxLabel {
      font-size: 10px;
      margin-left: 5px;
    }
  }
`;