import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const AccountContentContainer = styled(BasicSectionContainer)`
  
  .card {

    .cardTitle {
      font-size: 24px;
      font-weight: 600;
      color: ${colors.black};
      margin-bottom: 16px;
    }

    .cardContent {

      .description {
        font-size: 16px;
        color: ${colors.black};
      }

      .passwordContainer {
        position: relative;
        width: 45%;
        margin: 32px 0;

        .oldPasswordLock, .oldPasswordUnlock {
          position: absolute;
          top: 45px;
          right: 20px;
          cursor: pointer;
        }

        .newPasswordLock, .newPasswordUnlock {
          position: absolute;
          top: 142px;
          right: 20px;
          cursor: pointer;
        }
      }

      .buttonContainer {
        position: relative;

        svg {
          position: absolute;
          top: -85px;
          left: 150px;
        }
      }
    }
  }
`;
