import styled from 'styled-components';
import { BasicSectionContainer } from 'screens/style';
import { colors } from 'constants/colors';
import getHelp from 'assets/images/getHelp.png';

export const FooterBox = styled.footer`
  width: 100%;
  height: 100%;
`;


export const FooterContainer = styled.footer`
  width: 100%;
`;

type footerProps = {
  centerWidth: string;
  centerHeight: string;
};

export const FooterBaseContainer = styled(BasicSectionContainer)<footerProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};

  .footerContentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterFormContainer = styled(FooterBaseContainer)`
  align-items: flex-start;
  background-image: none;
  background-color: ${colors.black};
  padding: 16px 0;

  .footerSubscribeContainer {
    padding: 32px 0;

    .footerSubscribeTitle {
      font-size: 24px;
      font-weight: 300;
      text-align: center;
    }

    .footerSubscribeDescription {
      width: 100%;
      text-align: center;
      font-size: 15px;
      font-weight: 300;
      margin-top: 13px;
    }

    .footerInputContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      margin-top: 23px;

      .footerSignUpButton {
        font-size: 16px;
        margin-left: 10px;
      }

      .footerInput {
        width: 100%;
        max-width: 343px;
        height: 44px;
        box-sizing: border-box;
        padding: 0 15px;
        font-size: 15px;
        color: ${colors.textInputLabel};
      }
    }

  }

  .footerHelpContainer {
    width: 50%;
    margin-right: 16px;

    .footerHelpTitle {
      width: 80%;
      font-size: 32px;
      font-weight: bold;
      margin-top: 16px;
    }

    .footerHelpDescription {
      width: 80%;
      font-size: 19px;
      margin-top: 24px;
    }
  }

  .footerHelpImage {
    width: 50%;
    background-image: url(${getHelp});
    min-width: 627px;
    min-height: 292px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
  }

  @media only screen and (max-width: 767px) {

    .footerContentContainer {
      width: 90%;
    }

    .footerInputContainer {
      .footerSignUpButton {
        font-size: 12px;
        margin-left: 10px;
      }
      .footerInput {
        max-width: 90%;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .footerContentContainer {
      flex-direction: column;

      .footerHelpContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 0;
        margin-bottom: 16px;

        .footerHelpTitle {
          width: 100%;
          text-align: center;
        }
      }

      .footerHelpImage {
        width: 100%;
        background-size: cover;
        background-position: center;
        margin-bottom: 16px;
      }
    }
  }
`;

export const FooterDetailsContainer = styled(BasicSectionContainer)`
  height: 329px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  background-image: none;
  background-color: ${colors.footerDetailsBackground};

  .innerContainer {
    height: 100%;

    .footerDetails {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: 329px;
      padding: 67px 0 78px 0;

      .footerDetail {
        width: 25%;

        .footerDetailsTitle {
          font-size: 18px;
          font-weight: bold;
        }

        .address {
          text-decoration: underline;
          cursor: pointer;
        }

        .footerDetailsContent {
          margin-top: 16px;

          div {
            font-size: 16px;
            margin-top: 10px;

            .highLight {
              font-weight: 600;
            }

            a {
              color: ${colors.white};
              text-decoration: underline;
            }
          }
        }
      }

      .logoContainer {
        width: 153px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .bigLogo {

        }
      }
    }

  }
`;

export const FooterBottomContainer = styled(BasicSectionContainer)`
  width: 100%;
  height: 70px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  background-image: none;
  background-color: ${colors.footerDetailsBackground};
  border-top: 1px solid ${colors.borderGrey2};

  .innerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .footerBottom {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footerBottomContent {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 16px;

        .policy {
          width: 110px;
          min-width: 110px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-right: 50px;

          .terms {
            width: 50%;
            cursor: pointer; 
            padding-right: 10px;
            border-right: 1px solid ${colors.borderGrey2};
          }

          .privacy {
            width: 50%;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .footerBottomIcons {
        width: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;

        .socialMedia {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
`;