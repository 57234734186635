import React, { FC } from 'react';
import { CheckboxContainer, CheckboxContainerProps } from './styles';

type CheckboxProps = {
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  label?: string;
  className?: string;
} & CheckboxContainerProps;

const Checkbox: FC<CheckboxProps> = ({
  disabled,
  onChange,
  checked,
  label,
  error,
  backgroundColor,
  borderColor,
  checkboxScale,
  spaceBetween,
  className
}) => {
  
  
  return (
    <CheckboxContainer
      className={className}
      checkboxScale={checkboxScale}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      spaceBetween={spaceBetween}
      error={error}
    >
      <input
        className='input'
        disabled={disabled}
        type="checkbox"
        checked={checked}
        readOnly
      />
      <div className="checkbox"
        onClick={onChange}
      />
      <label className="label" onClick={onChange}>{label}</label>
    </CheckboxContainer>
  )
}

export default Checkbox
