import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import viewLarge from 'assets/images/viewLarge.jpg';
import viewSmall from 'assets/images/shutterStock.png';

export const HomeHeaderContainer = styled(BasicSectionContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 601px;
  background-repeat: repeat;
  background-size: cover;
  background-position: bottom;
  background-image: url(${viewLarge});

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 601px;
    background-color: rgba(0, 0, 0, 0.25); 
    z-index: 100;
  }

  @media only screen and (max-width: 767px) {
    height: 447px;
    background-image: url(${viewSmall});
    padding-top: 16px;

    .overlay {
      display: none;
    }
  }
`;

export const HomeTitleContainer = styled.div`

  position: relative;
  width: 100%;
  padding: 216px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 200;

  .title1 {
    color: ${colors.white};
    font-size: 25px;
    height: 38px;
    line-height: 38px;
    margin-top: 10px;

    .highLight {
      font-weight: 700;
    }
  }

  .title2 {
    color: ${colors.white};
    font-size: 41px;
    font-style: italic;
    height: 62px;
    line-height: 62px;
    margin-bottom: 10px;
  }

  .title3 {
    color: ${colors.white};
    font-size: 78px;
    font-weight: 700;
    height: 80px;
    line-height: 80px;
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    .title2 {
      font-size: 30px;
    }

    .title3 {
      font-size: 60px;
    }
  }

  @media only screen and (max-width: 767px) {

    padding: 20px 0px 20px 0px;

    .logo {
      display: none;
    }

    .title1 {
      display: none;
    }

    .title2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 30px;
      font-weight: bold;
      font-style: normal;
      color: ${colors.black};
      height: 82px;
      line-height: 41px;
      padding: 0px 15px;

      span {
        display: block; 
      }
    }

    .title3 {
      display: none;
    }
  }
`;
