import React, { FC } from 'react';
import { useAuth } from 'customHooks/useAuth';
import { Redirect, Route, RouteProps } from 'react-router-dom';

type ProtectProps = RouteProps & {
  redirectPath: string;
};
//If a route needs an auth, wrap it with ProtectRoute
//See how to use it in App.tsx
const ProtectRoute: FC<ProtectProps> = ({
  children,
  redirectPath,
  ...rest
}) => {

  const  { validToken } = useAuth()
  
  if(validToken) {
    return <Route {...rest} render={()=>children as React.ReactNode} />
  }
  else {
    return <Redirect to={redirectPath} />
  }
}

export default ProtectRoute;
