import React, { FC, useState } from "react";
import StyledButton from "components/Button";
import { useRecoilValue, useRecoilState } from "recoil";
import { addOnOptionsState } from "states/OptionStates/atoms";
import { selectedRooms, couponState, couponSuccessState, ratePlanChoice } from "states/CheckOutStates/atoms";
import {
  roomQuantityState,
  dateRangeState,
} from "states/SearchBarStates/atoms";
import { useGetBookingAddOns } from "customHooks/useGetBookingAddOns";
import { dateDiff } from "utils/util";
import { MobileFooterContainer } from "./styles";
import Icon from "components/Icon";
import { ExclamationIcon } from "assets/icons";
import BookingSummaryModal from "components/BookingSummaryModal";
import { MAT_RATE } from "constants/constant";
import { ADVANCED, ADVANCED_RATE } from 'constants/ratePlanId';

export type MobileFooterProps = {
  handleContinueBooking: () => void;
  showAddOn?: boolean;
};

const MobileFooter: FC<MobileFooterProps> = ({ handleContinueBooking, showAddOn }) => {

  const [open, setOpen] = useState(false);
  const persistSelectedRooms = useRecoilValue(selectedRooms);
  const dateRange = useRecoilValue(dateRangeState);
  const roomQuantity = useRecoilValue(roomQuantityState);
  // customer selected addOns
  const addOnOptions = useRecoilValue(addOnOptionsState);
  const ratePlanId = useRecoilValue(ratePlanChoice);
  // all addOns query from server
  const bookingAddOns = useGetBookingAddOns()

  const [coupon, setCoupon] = useRecoilState(couponState)
  const [couponSuccess, setCouponSuccess] = useRecoilState(couponSuccessState)

  const nights = dateDiff(new Date(dateRange[0]), new Date(dateRange[1]))
  const roomFee = (persistSelectedRooms.pricePerNight || 0) * nights * roomQuantity
  const addOnFee = bookingAddOns.reduce((acc, addOn) => {
    if (addOnOptions.includes(addOn.addOnId)) {
      if(addOn.isOneTimePriced) {
        return acc + (addOn.pricePerNight || 0) * roomQuantity
      } else {
        return acc + (addOn.pricePerNight || 0) * nights * roomQuantity
      }
    }
    return acc
  }
  , 0)

  const couponDiscount = coupon.rateType==='FIXED_RATE'? (coupon.discount || 0): (coupon.discount || 0) / 100 * roomFee
  const ratePlanDiscount = ratePlanId === ADVANCED? coupon.rateType==='FIXED_RATE'? (roomFee - (coupon.discount || 0)) * (1 - ADVANCED_RATE): roomFee * (1 - (coupon.discount || 0) / 100) * (1 - ADVANCED_RATE): 0
  const discountedRoomFee = coupon.rateType==='FIXED_RATE'? (roomFee - (coupon.discount || 0)) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1): roomFee * (1 - (coupon.discount || 0) / 100) * (ratePlanId === ADVANCED? ADVANCED_RATE: 1)
  const subtotal = discountedRoomFee + addOnFee

  return (
    <MobileFooterContainer>
      <div className="bar" />
      <div
        className="footerContent"
        onClick={() => {
          setOpen(true)
        }}
      >
        <div className="left">
          <div className="bookingSummary">
            <div className="price">{"$ " + subtotal.toFixed(2)}</div>
            <div className="nights">
              {nights > 1 ? nights + " nights" : nights + " night"}
            </div>
          </div>
        </div>
        <Icon className="exclamation">
          <ExclamationIcon />
        </Icon>
        <div className="right">
          <StyledButton
            className="nextButton"
            width="180px"
            height="48px"
            fontSize={18}
            onClick={handleContinueBooking}
            disabled={!persistSelectedRooms.roomTypeId || dateRange[0] === null || dateRange[1] === null}
          >
            Continue Booking
          </StyledButton>
        </div>
      </div>
      <BookingSummaryModal open={open} setOpen={setOpen} showAddOn/>
    </MobileFooterContainer>
  );
};

export default MobileFooter;
