import React, { FC } from 'react';
import {
  HomeHeaderContainer,
  HomeTitleContainer,
} from './style';
import { Icon } from 'components/Icon';
import { NewLogoIcon } from 'assets/icons';
import SearchBar from 'components/SearchBar';

const HomeHeader: FC = () => (
  <HomeHeaderContainer>
    <div className='overlay'></div>
    <div className="innerContainer">
      <HomeTitleContainer>
        {/* <Icon
          className='logo'
          width={116}
          height={87}
        >
          <NewLogoIcon />
        </Icon>
        <div className='title1'>
          <span className='highLight'>ROOK </span>
          HOTEL
        </div> */}
        <div className='title2'><span>Travel like a King</span> <span>for the price of a Rook</span></div>
        <div className='title3'>Mississauga’s best move</div>
      </HomeTitleContainer>
      <SearchBar />
    </div>
  </HomeHeaderContainer>
);

export default HomeHeader;
