import React, { FC } from 'react';
import { PhoneNumber }  from './style';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import { useState, useEffect   } from 'react';
import StyledButton from 'components/Button';
import Checkbox from 'components/Checkbox'
import AccountModal from "components/AccountModal";
import VerificationInputField from 'components/VerificationInputField'
import PhoneNumberInput from 'components/PhoneNumberInput';


const PhoneNumberContent: FC = () => {
  const debug = true;

  const [phoneValue, setPhoneValue] = useState('3434322');
  
  const [newPhoneValue, setNewPhoneValue] = useState('234234234');

  const [receivePhoneNotifications, setReceivePhoneNotifications] = useState(false);

  const [changePhoneMoad, setChangePhoneMoadl] = useState(false);
  const [changeConfirmationMoadal, setChangeConfirmationMoadal] = useState(false);

  //const [phoneNumber, setPhoneNumber] = useState(userInfo.phoneNumberInput);
  
  const [phoneNumber, setPhoneNumber] = useState('123123123');

  const CODE_LENGTH = 6;
  const [verificationCode, setVerificationCode] = useState<string[]>(Array(CODE_LENGTH).fill(''));



  // Verification code resizing based on window size:

  const [verificationInputInputHeight, setVerificationInputInputHeight] = useState("67px");
  const [verificationInputInputWidth, setVerificationInputInputWidth] = useState("63px");
  const [verificationInputMargin, setVerificationInputMargin] = useState("20px 0");

  useEffect(() => {
    // Function to update the size based on window width
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setVerificationInputInputHeight("40px");
        setVerificationInputInputWidth("40px");
        setVerificationInputMargin ("8px 0 22px 0");
      } else {
        setVerificationInputInputHeight("67px");
        setVerificationInputInputWidth("63px");
        setVerificationInputMargin ("20px 0");
      }
    };

    // Call the function initially to set the size when the component mounts
    handleResize();

    // Add event listener to listen for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <PhoneNumber>
      {/* Change Phone Number modal */}
      <AccountModal
        open={changePhoneMoad}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Enter New Phone Number"
        onClose={() => setChangePhoneMoadl(false)}
      >
        <div className="accounModalBody phoneModal">
          <div className="inpuntCont phone">
          <PhoneNumberInput
                  className='phoneNumberInput'
                  label="PHONE"
                  isRequired={true}
                  labelPosition='left'
                  //error={!phoneNumberValidity && phoneNumberError}
                  errorMessage='Please enter a valid phone number'
                  inputHeight="43px"
                  marginBottom={'20px'}
                  onChangeNumber={setNewPhoneValue}
                  //setPhoneNumberValidity={setPhoneNumberValidity}
                  // onBlur={() => {
                  //   if(!phoneNumberValidity&&(phoneNumber.length<3)) {
                  //     setPhoneNumberError(true)
                  //   }
                  // }}
                />
            {/* <FormInputField
              disabled={false}
              className="secInput"
              // highLightLabel='CURRENT '
              label="Phone"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              value={newPhoneValue}
              inputHeight="48px"
              marginBottom="20px"
               onChange={(e) => {
                setNewPhoneValue(e.target.value)
                 }
               }
            /> */}
          </div>
          <div className="continueButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              onClick={() => {
                setChangePhoneMoadl(false);
                setChangeConfirmationMoadal(true);
              }}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              // onClick={handleResetPassword}
            >
              Continue
            </StyledButton>
          </div>
        </div>
      </AccountModal>

      {/* Verify phone number modal */}
      <AccountModal
        open={changeConfirmationMoadal}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Confirmation Required"
        onClose={() => setChangeConfirmationMoadal(false)}
      >
        <div className="accounModalBody confirmationMoadal">
          <h4 className="confirmationModalHeader">Verify phone number</h4>
          <div className="confirmationModalText">
          A verification code has been sent to your current phone number ending in 1111. Please enter the code below to confirm your update:          </div>
          <div className="inpuntCont confirmationModalInput">
            {/* <label>
              <span>VERIFICATION CODE</span>
              <span className="required"> *</span>
            </label> */}
            <VerificationInputField
              label="Verification Code"
              isRequired
              margin={verificationInputMargin}
              width="100%"
              length={CODE_LENGTH}
              values={verificationCode}
              setValues={setVerificationCode}
              inputHeight = {verificationInputInputHeight}
              inputWidth = {verificationInputInputWidth}
              labelSize = "14px"
            />
          </div>
          <div className="continueButton">
            <StyledButton
              width="192px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                setChangeConfirmationMoadal(false);
              }}
            >
              Verify Phone Number
            </StyledButton>
          </div>
          <div className="bottomText">
            Haven’t received the code? <a href="#">Resend code</a>
          </div>
        </div>
      </AccountModal>

      <div className="accountBody">
        <div className="cardContent">
          <div className="topText">
          To update your phone number, we'll send a six-digit verification code to verify the new phone number added to your account.
          </div>
          <div className="inpuntCont phone">
          <PhoneNumberInput
                  //value={phoneValue}
                  className='phoneNumberInput'
                  label="PHONE"
                  isRequired={true}
                  labelPosition='left'
                  //error={!phoneNumberValidity && phoneNumberError}
                  errorMessage='Please enter a valid phone number'
                  inputHeight="43px"
                  marginBottom={'20px'}
                  onChangeNumber={setPhoneValue}
                  //setPhoneNumberValidity={setPhoneNumberValidity}
                  // onBlur={() => {
                  //   if(!phoneNumberValidity&&(phoneNumber.length<3)) {
                  //     setPhoneNumberError(true)
                  //   }
                  // }}
                />
            {/* <FormInputField
              disabled={true}
              className="secInput"
              // highLightLabel='CURRENT '
              label="PHONE"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              value={phoneValue}
              inputHeight="48px"
              marginBottom="20px"
              // onChange={(e) => {
              //   setOldPassword(e.target.value)
              //   setNewPasswordSucsess (false)
              //   if(oldPasswordError) {
              //     setOldPasswordError(false)
              //     setNewPasswordSucsess (false)
              //     setSowResetPasswordHint(false)
              //   }
              // }}
            /> */}
          </div>
          <div className="changeButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                setChangePhoneMoadl(true);
                if (debug) {
                  console.log(changePhoneMoad);
                  console.log(changeConfirmationMoadal);
                }
              }}
            >
              Change
            </StyledButton>
          </div>
        </div>
      </div>

      <div className="accountBody">
        <div className="cardContent">
          <h3>Phone notification preferences</h3>
          <Checkbox
            className="accountCheckbox"
            checked={receivePhoneNotifications}
            //error={termsOfServiceError}
            borderColor={colors.labelGrey}
            label="Receive occasional marketing texts from Rook Hotel."
            onChange={() => {
              setReceivePhoneNotifications(!receivePhoneNotifications);
            }}
            checkboxScale={1.2}
          />
          <div className="note">
            <strong>Note:</strong> This does not include account related messages, such as booking confirmation, check in / check out confirmations, etc.
          </div>
          <div className="saveButton">
            <StyledButton
              width="81px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
    </PhoneNumber>
  );
};

export default PhoneNumberContent;
