import React, { FC, useEffect, useState } from 'react';
import { CompleteAlertContainer } from './style';
import { CheckIcon } from 'assets/icons';
import { useLazyQuery } from '@apollo/client';
import { GET_TENANT_INFO_BY_USER_ID } from 'graphql/service';
import { Booking } from 'types/commonTypes';

const CompleteAlert: FC<{
  userId?: string,
  receivedReservationCode?: string
}> = ({ userId, receivedReservationCode }) => {

  const [reservationCode, setReservationCode] = useState(receivedReservationCode)

  const [getTenantInfoByUserId] = useLazyQuery(
    GET_TENANT_INFO_BY_USER_ID,
    {
      variables: { userId },
      onCompleted: (tenantInfoData) => {
        const bookings = tenantInfoData.tenantByUserID?.groups?.[0]?.group.bookings    
        const reservationCode = bookings.find((booking: Booking) => booking.tenantGroup?.isPrimaryTenant===true)?.reservationCode
        setReservationCode(reservationCode)
      },
      onError: (error) => {
        const content = error.message
        console.log(content)
      }
    }
  )

  useEffect(() => {
    if (userId) {
      getTenantInfoByUserId()
    } 
  }, [userId, getTenantInfoByUserId]);

  return (
    <CompleteAlertContainer>
      <CheckIcon style={{ marginRight: '5px' }} />
      <div className="alertContent">
        <div>
          <b>Your reservation is complete! {'\u00A0'}</b>
        </div>
        <div>Your Reservation Code: <span className='reservationCode'>{reservationCode}</span>.</div>         
      </div>
    </CompleteAlertContainer>
  )
}

export default CompleteAlert
