import styled from 'styled-components';
import { colors } from 'constants/colors';

export type InputFieldContainerProps = {
  labelPosition?: 'left' | 'center';
  labelColor?: string;
  labelSize?: string;
  labelWeight?: string;
  borderColor?: string;
  inputHeight?: string;
  inputTextColor?: string;
  inputFontSize?: string;
  inputFontWeight?: string;
  inputBackgroundColor?: string;
  error?: boolean;
  width?: string;
  marginLeft?: string;
  marginBottom?: string;
  iconUrl?: string;
};

export const PhoneNumberInputContainer = styled.div<InputFieldContainerProps>`

  width: ${(props) => props.width?? '100%'};
  margin-left: ${(props) => props.marginLeft?? '0'};
  margin-bottom: ${(props) => props.marginBottom?? '20px'};
  
  label {
    width: 100%;
    display: block;
    font-size: ${(props) => (props.labelSize?? '14px')};
    font-weight: ${(props) => (props.labelWeight?? '600')};
    color: ${(props) => props.error? colors.red: (props.labelColor?? colors.labelGrey)};
    margin-bottom: 5px;
    letter-spacing: 0.75px;
    text-align: ${(props) => props.labelPosition === 'center'? 'center': 'left'};

    span {
      font-size: 18px;
      color: ${colors.red};
    }
  }

  .iti {
    width: 100%;

    .iti__country-container {
      :focus-visible {
        outline: none;
      }

      button {
        :focus-visible, :focus {
          outline: none;
        }

        .iti__selected-country-primary {
          :focus-visible {
            outline: none;
          }
        }
      }
    }

    input {
      font-size: ${(props) => (props.inputFontSize?? '16px')};
      font-weight: ${(props) => (props.inputFontWeight?? '400')};
      font-family: 'Titillium Web', sans-serif;
      width: 100%;
      box-sizing: border-box;
      height: ${(props) => (props.inputHeight?? '40px')};
      border: 1px solid
        ${(props) => props.error? colors.red: (props.borderColor?? colors.signInBorderColor)};
      color: ${(props) => props.inputTextColor?? colors.black};
      padding-left: 10px;
      background-color: ${(props) => props.inputBackgroundColor?? colors.white};

      :focus {
        border-color: ${(props) => props.error? colors.red: colors.hoverBlue};
      }

      :focus-visible {
        outline: none;
      }
    }
  }

  .warning {
    width: ${(props) => (props.width?? null)};
    height: 20px;
    position: absolute;
    color: ${colors.red};
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
