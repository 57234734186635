import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import StyledButton from 'components/Button';
import Card from 'components/Card';
import { BookingSummaryContainer } from './style';
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedRooms } from 'states/CheckOutStates/atoms';
import {
  dateRangeState,
  guestQuantityState,
  roomQuantityState,
} from 'states/SearchBarStates/atoms';
import { addOnOptionsState } from "states/OptionStates/atoms";
import { useGetBookingAddOns } from 'customHooks/useGetBookingAddOns';
import { dateDiff } from 'utils/util';
import moment from 'moment';
import { EditIcon, RedCloseIcon } from 'assets/icons';
import { MAT_RATE } from 'constants/constant';

const BookingSummary: FC = () => {

  const history = useHistory()

  const [persistSelectedRooms, setPersistSelectedRooms] = useRecoilState(selectedRooms);
  const [guestQuantity, setGuestQuantity] = useRecoilState(guestQuantityState);
  const [roomQuantity, setRoomQuantity] = useRecoilState(roomQuantityState);
  const dateRange = useRecoilValue(dateRangeState);
  // customer selected addOns
  const [addOnOptions, setAddOnOptions] = useRecoilState(addOnOptionsState);
  // all addOns query from server
  const bookingAddOns = useGetBookingAddOns()
  
  const nights = dateDiff(new Date(dateRange[0]), new Date(dateRange[1]))
  const roomFee = (persistSelectedRooms.pricePerNight || 0) * nights * roomQuantity
  const addOnFee = bookingAddOns.reduce((acc, addOn) => {
    if (addOnOptions.includes(addOn.addOnId)) {
      if(addOn.isOneTimePriced) {
        return acc + (addOn.pricePerNight || 0) * roomQuantity
      } else {
        return acc + (addOn.pricePerNight || 0) * nights * roomQuantity
      }
    }
    return acc
  }
  , 0)

  const subtotal = roomFee + addOnFee

  const handleNext = () => {
    history.push('/checkOut');
  }

  const addOnOptionsArray =  bookingAddOns.map((addOn) => {
    if(addOnOptions.includes(addOn.addOnId)) {
      return (
        <div className="priceDetails" key={addOn.addOnId}>
          <span className="text">{addOn.label?.toUpperCase()}</span>
          <span className="price">{
            `CAD ${addOn.pricePerNight.toFixed(2)}${addOn.label==='Breakfast'? ' / day':
              addOn.label==='Parking'? ' / 24 hrs': ''}`
          }</span>
        </div>
      )
    }
    return null
  })

  return (
    <BookingSummaryContainer>
      <Card padding='10px 20px'>
        <div className="title">
          <span className='text'>Book your stay with us</span>
          <span
            className='editTrip'
            onClick={() => {
              history.push(`/search?checkInDate=${dateRange[0]}&checkOutDate=${dateRange[1]}&guestQuantity=${guestQuantity}&roomQuantity=${roomQuantity}`);
            }}
          >
            <EditIcon className='editIcon'/>
            Edit trip
          </span>
        </div>
        <div className="bookingDates">
          <div className="text">DATES</div>
          <div className='checkInOutDates'>
            {moment(dateRange[0]).format('ddd, DD MMM')} -
            {moment(dateRange[1]).format('ddd, DD MMM')}
          </div>
        </div>
        <div className="guestOption">
          <span className="text">GUESTS</span>
          <span className="numbers">
            {guestQuantity}
          </span>
        </div>
        <div className="roomOption">
          <span className="text">ROOMS</span>
          <span className="numbers">
            {roomQuantity}
          </span>
        </div>
        <div className="booking">Your Booking: </div>
        <div className="priceDetails">
          <span className='text'>{persistSelectedRooms.name?.toUpperCase()}</span>
          <span className='price'>{` CAD ${persistSelectedRooms.pricePerNight?.toFixed(2)} / night`}</span>
        </div>
        <>{addOnOptionsArray}</>
        <StyledButton
          className="nextButton"
          width="100%"
          height="59px"
          margin='42px 0 10px 0'
          fontSize={18}
          onClick={handleNext}
        >
          Next Step {'>'}
        </StyledButton>
      </Card>
    </BookingSummaryContainer>
  )
}

export default BookingSummary