import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { VerificationInputFieldContainer, InputFieldContainerProps } from './style'

type VerificationCodeInputProps = {
  className?: string,
  label?: string;
  iconUrl?: string;
  errorMessage?: string;
  successMessage?: string;
  isRequired?: boolean;
  margin?: string;
  length: number;
  values: string[];
  setValues: (values: string[]) => void;
} & InputFieldContainerProps;

const VerificationInputField: React.FC<VerificationCodeInputProps> = ({
  className,
  label,
  width,
  iconUrl,
  isRequired,
  length,
  labelPosition,
  labelColor,
  labelSize,
  labelWeight,
  inputFontSize,
  inputFontWeight,
  inputColor,
  inputBackgroundColor,
  borderColor,
  inputHeight,
  inputWidth,
  margin,
  error,
  success,
  values,
  setValues
}) => {

    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
        const { value } = e.target;
        if (/^[a-zA-Z0-9]$/.test(value) || value === '') {
            const newValues = [...values];
            newValues[idx] = value.toUpperCase();
            setValues(newValues);
            if (value !== '' && idx < length - 1) {
                inputsRef.current[idx + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, idx: number) => {
        if (e.key === 'Backspace' && values[idx] === '' && idx > 0) {
            inputsRef.current[idx - 1]?.focus();
        }
    };

    return (
        <VerificationInputFieldContainer
          className={className}
          labelPosition={labelPosition}
          labelColor={labelColor}
          labelSize={labelSize}
          labelWeight={labelWeight}
          borderColor={borderColor}
          inputHeight={inputHeight}
          inputWidth={inputWidth}
          inputColor={inputColor}
          inputFontSize={inputFontSize}
          inputFontWeight={inputFontWeight}
          inputBackgroundColor={inputBackgroundColor}
          error={error}
          success={success}
          width={width}
          margin={margin}
        >
          {label?
            <label>
              VERIFICATION CODE
              {isRequired? <span className="required"> *</span>: null}
            </label>
          : null}
          {(iconUrl && iconUrl.length > 0)? <img src={iconUrl} className="icon" alt="check"/>: null}
          <div className='blockContainer'>
            {Array.from({ length }).map((_, idx) => (
                <input
                    key={idx}
                    type="text"
                    maxLength={1}
                    value={values[idx]}
                    onChange={(e) => handleChange(e, idx)}
                    onKeyDown={(e) => handleKeyDown(e, idx)}
                    ref={(el) => (inputsRef.current[idx] = el)}
                />
            ))}
          </div>
        </VerificationInputFieldContainer>
    );
};

export default VerificationInputField;