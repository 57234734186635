import React, { FC } from 'react';
import { Email }  from './style';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import { useState, useEffect   } from 'react';
import StyledButton from 'components/Button';
import Checkbox from 'components/Checkbox'
import AccountModal from "components/AccountModal";
import VerificationInputField from 'components/VerificationInputField'

const EmailContent: FC = () => {
  const debug = true;

  const [emailValue, setEmailValue] = useState('test@test.com');
  
  const [newEmailValue, setNewEmailValue] = useState('test@test.com');

  const [receiveMarketingEmails, setReceiveMarketingEmails] = useState(false);

  const [changeEmailMoad, setChangeEmailMoadl] = useState(false);
  const [changeConfirmationMoadal, setChangeConfirmationMoadal] = useState(false);


  const CODE_LENGTH = 6;
  const [verificationCode, setVerificationCode] = useState<string[]>(Array(CODE_LENGTH).fill(''));



  // Verification code resizing based on window size:

  const [verificationInputInputHeight, setVerificationInputInputHeight] = useState("67px");
  const [verificationInputInputWidth, setVerificationInputInputWidth] = useState("63px");
  const [verificationInputMargin, setVerificationInputMargin] = useState("20px 0");

  useEffect(() => {
    // Function to update the size based on window width
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setVerificationInputInputHeight("40px");
        setVerificationInputInputWidth("40px");
        setVerificationInputMargin ("8px 0 22px 0");
      } else {
        setVerificationInputInputHeight("67px");
        setVerificationInputInputWidth("63px");
        setVerificationInputMargin ("20px 0");
      }
    };

    // Call the function initially to set the size when the component mounts
    handleResize();

    // Add event listener to listen for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Email>
      {/* Change E-mail modal */}
      <AccountModal
        open={changeEmailMoad}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Enter New Email Address"
        onClose={() => setChangeEmailMoadl(false)}
      >
        <div className="accounModalBody emailModal">
          <div className="inpuntCont email">
            <FormInputField
              disabled={false}
              className="secInput"
              // highLightLabel='CURRENT '
              label="EMAIL ADDRESS"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              value={newEmailValue}
              inputHeight="48px"
              marginBottom="20px"
               onChange={(e) => {
                setNewEmailValue(e.target.value)
                 }
               }
            />
          </div>
          <div className="continueButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              onClick={() => {
                setChangeEmailMoadl(false);
                setChangeConfirmationMoadal(true);
              }}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              // onClick={handleResetPassword}
            >
              Continue
            </StyledButton>
          </div>
        </div>
      </AccountModal>

      {/* Verify email address modal */}
      <AccountModal
        open={changeConfirmationMoadal}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Confirmation Required"
        onClose={() => setChangeConfirmationMoadal(false)}
      >
        <div className="accounModalBody confirmationMoadal">
          <h4 className="confirmationModalHeader">Verify email address</h4>
          <div className="confirmationModalText">
            A verification code has been sent to your email address
            t****@gmail.com. Please enter the code below to confirm your update:
          </div>
          <div className="inpuntCont confirmationModalInput">
            {/* <label>
              <span>VERIFICATION CODE</span>
              <span className="required"> *</span>
            </label> */}
            <VerificationInputField
              label="Verification Code"
              isRequired
              margin={verificationInputMargin}
              width="100%"
              length={CODE_LENGTH}
              values={verificationCode}
              setValues={setVerificationCode}
              inputHeight = {verificationInputInputHeight}
              inputWidth = {verificationInputInputWidth}
              labelSize = "14px"
            />
          </div>
          <div className="continueButton">
            <StyledButton
              width="192px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                setChangeConfirmationMoadal(false);
              }}
            >
              Verify Email Address
            </StyledButton>
          </div>
          <div className="bottomText">
            Haven’t received the code? <a href="#">Resend code</a>
          </div>
        </div>
      </AccountModal>

      <div className="accountBody">
        <div className="cardContent">
          <div className="topText">
            Your email address has already been verified. If you wish to change
            your email address click the change button below.
          </div>
          <div className="inpuntCont email">
            <FormInputField
              disabled={true}
              className="secInput"
              // highLightLabel='CURRENT '
              label="EMAIL ADDRESS"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              value={emailValue}
              inputHeight="48px"
              marginBottom="20px"
              // onChange={(e) => {
              //   setOldPassword(e.target.value)
              //   setNewPasswordSucsess (false)
              //   if(oldPasswordError) {
              //     setOldPasswordError(false)
              //     setNewPasswordSucsess (false)
              //     setSowResetPasswordHint(false)
              //   }
              // }}
            />
          </div>
          <div className="changeButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                setChangeEmailMoadl(true);
                if (debug) {
                  console.log(changeEmailMoad);
                  console.log(changeConfirmationMoadal);
                }
              }}
            >
              Change
            </StyledButton>
          </div>
        </div>
      </div>

      <div className="accountBody">
        <div className="cardContent">
          <h3>Email notification preferences</h3>
          <Checkbox
            className="accountCheckbox"
            checked={receiveMarketingEmails}
            //error={termsOfServiceError}
            borderColor={colors.labelGrey}
            label="Receive occasional marketing emails from Rook Hotel."
            onChange={() => {
              setReceiveMarketingEmails(!receiveMarketingEmails);
            }}
            checkboxScale={1.2}
          />
          <div className="note">
            <strong>Note:</strong> this does not include account related emails,
            such as booking confirmations, or check-out receipts.
          </div>
          <div className="saveButton">
            <StyledButton
              width="81px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
    </Email>
  );
};

export default EmailContent;
