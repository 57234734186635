import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import setPhoneNumber from 'assets/icons/setPhoneNumber.svg';

export const CompleteContentContainer = styled(BasicSectionContainer)`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .innerContainer {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.setPhoneNumberBackground};
    width: 100%;
    height: 346px;
    padding: 0 15px;

    .titleText {
      width: 100%;

      .title {
        font-size: 48px;
        font-weight: bold;
        line-height: 72px;
        text-align: left;
        white-space: nowrap;
        color: ${colors.white};
        margin-top: -20px;

        .highLight {
          color: ${colors.checkOutCompleteScreenHighlight};
        }
      }
    }

    .setPhoneNumberImage {
      position: absolute;
      right: 31px;
      top: 23px;
      width: 475px;
      min-width: 475px;
      height: 323px;
      background-image: url(${setPhoneNumber});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0 center;
    }
  }

  .inputRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 15px;

    .inputContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 515px;
      min-width: 270px;
      padding: 20px 0;

      .description {
        font-size: 16px;
        font-weight: 400;
        color: ${colors.black};
        margin: 15px 0;

        .highLight {
          font-weight: 600;
        }
      }
    }

    .verifyContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 373px;
      min-width: 373px;
      padding: 30px 0;

      .verifyTitle {
        font-size: 24px;
        font-weight: bold;
        color: ${colors.black};
        margin: 15px 0;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        color: ${colors.black};
        margin: 15px 0;

        .highLight {
          color: ${colors.hoverBlue};
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {

    .innerContainer {
      flex-direction: column-reverse;
      padding: 0;
      margin: 0;
    }

    .titleContainer {
      height: auto;

      .titleText {
        margin: 20px 0;

        .title {
          text-align: center;
          font-size: 36px;
        }

        .description {
          text-align: center;
        }
      }

      .setPhoneNumberImage {
        position: static;
        width: 100%;
        max-width: 270px;
        min-width: 270px;
        height: 183px;
        margin-top: 20px;
      }
    }
  }
`;
