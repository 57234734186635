import React from 'react';
import AccountHeader from './components/AccountHeader';
import AccountContent from './components/AccountContent';
import AccountMenuBar from './components/AccountMenuBar';
import ScreenLayout from 'components/ScreenLayout';
import { AccountContainer } from './style';

const AccountScreen: React.FC = () => (
  <ScreenLayout disableModal>
    <AccountContainer>
      <div className="innerContainer">
        <div className='header'>
          <AccountHeader />
        </div>
        <div className='account'>     
          <AccountMenuBar />
          <AccountContent />
        </div>
      </div>
    </AccountContainer>
  </ScreenLayout>
);

export default AccountScreen;
