import React from 'react';
import { SearchResultOptionsContentContainer } from './style';
import RoomOptions from './RoomOptions';
import BookingSummary from './BookingSummary';
import TimeOut from 'components/TimeOut';

const SearchResultOptionsContent: React.FC<{ handleContinueBooking: () => void }> = ({ handleContinueBooking }) => {

  return (
    <SearchResultOptionsContentContainer>
      <div className="background"></div>
      <div className="innerContainer">
        <div className="content">
          <RoomOptions />
          <BookingSummary handleContinueBooking={handleContinueBooking} />
        </div>
      </div>
      <TimeOut />
    </SearchResultOptionsContentContainer>
  )
}

export default SearchResultOptionsContent;