import React from 'react';
import { useHistory } from 'react-router';
import {
  SlideContentItemContainer,
  ContentContainer,
  ImageContainer,
} from './style';

type SlideContentItemProps = {
  reverse?: boolean;
  description: string;
  title: string[];
  imageSource: string;
  isLast?: boolean;
  lastTitle?: boolean;
  titleOneLayout?: string;
  titleTwoLayout?: string;
  titleThreeLayout?: string;
  descriptionLayout?: string;
  imageWidth?: string;
  imageHeight?: string;
};

const SlideContentItem: React.FC<SlideContentItemProps> = ({
  reverse,
  description,
  title,
  imageSource,
  isLast,
  lastTitle,
  titleOneLayout,
  titleTwoLayout,
  titleThreeLayout,
  descriptionLayout,
  imageWidth,
  imageHeight,
}) => {
  const history = useHistory()
  return (
    <SlideContentItemContainer reverse={reverse}>
      <ContentContainer
        reverse={reverse}
        isLast={isLast}
        lastTitle={lastTitle}
        titleOneLayout={titleOneLayout}
        titleTwoLayout={titleTwoLayout}
        titleThreeLayout={titleThreeLayout}
        descriptionLayout={descriptionLayout}>
        <div className="contentTitleContainer">
          {title.map((item, index) => (
            <span key={`${item}+${index}`}>{item}</span>
          ))}
        </div>
        <div className="contentDescriptionContainer">
          <p>{description}</p>
          <span
            className="link"
            onClick={()=>{
              history.push('/searchResult')
            }}
          >
            Book now{' >'}
          </span>
        </div>
      </ContentContainer>
      <ImageContainer imageWidth={imageWidth} imageHeight={imageHeight}>
        <source media="(min-width:767px)" srcSet={imageSource} />
        <source media="(max-width:767px)" srcSet={imageSource} />
        <img src={imageSource} alt="homeImage" />
      </ImageContainer>
    </SlideContentItemContainer>
  )
}
export default SlideContentItem;
