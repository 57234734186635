import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import HomeHeader from './components/Header';
import HomeContent from './components/HomeContent';
import SlideContent from './components/SlideContent';

const HomeScreen: React.FC = () => {
  return (
    <ScreenLayout>
      <HomeHeader />
      <HomeContent />
      <SlideContent />
    </ScreenLayout>
  );
};

export default HomeScreen;
