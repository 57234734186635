import React from 'react';
import { useHistory } from 'react-router';
import {
    HintContainer
} from './styles';

type HintProps = {
  title: string;
  message: string;

  width?: string;
  arrowPosition?: string;
  positionBottom: string;
  positionLeft: string;

  widthTablet?: string;
  arrowPositionTablet?: string;
  positionBottomTablet?: string;
  positionLeftTablet?: string;


  widthMini?: string;
  arrowPositionMini?: string;
  positionBottomMini?: string;
  positionLeftMini?: string;

  widthMobile?: string;
  arrowPositionMobile?: string;
  positionBottomMobile?: string;
  positionLeftMobile?: string;

};

const Hint: React.FC<HintProps> = ({
    title,
    message,
    width,
    arrowPosition,
    positionBottom,
    positionLeft,

    widthTablet,
    arrowPositionTablet,
    positionBottomTablet,
    positionLeftTablet,

    widthMini,
    arrowPositionMini,
    positionBottomMini,
    positionLeftMini,

    widthMobile,
    arrowPositionMobile,
    positionBottomMobile,
    positionLeftMobile,

}) => {

  return (

      <HintContainer
        className="dateHint"

        width = {width}
        arrowPosition = {arrowPosition}
        positionBottom = {positionBottom}
        positionLeft = {positionLeft}

        widthTablet = {widthTablet}
        arrowPositionTablet = {arrowPositionTablet}
        positionBottomTablet = {positionBottomTablet}
        positionLeftTablet = {positionLeftTablet}


        widthMini = {widthMini}
        arrowPositionMini = {arrowPositionMini}
        positionBottomMini = {positionBottomMini}
        positionLeftMini = {positionLeftMini}


        widthMobile = {widthMobile}
        arrowPositionMobile = {arrowPositionMobile}
        positionBottomMobile = {positionBottomMobile}
        positionLeftMobile = {positionLeftMobile}

      >
        <div className="header">{title}</div>
        <div className="message">{message}</div>
      </HintContainer>

  );
}
export default Hint;
