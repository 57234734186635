import styled from 'styled-components';
import { BasicSectionContainer } from 'screens/style';

export const HomeContentContainer = styled(BasicSectionContainer)`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  text-align: center;

  .blogSectionTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 51px;
    font-weight: 600;
    height: 78px;
    line-height: 78px;
    margin: 40px 0;
  }

  @media only screen and (max-width: 767px) {
    margin: 0;
    
    .blogSectionTitle {
      font-size: 25px;
    }
  }
`;

export const BlogPostContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;