import styled from 'styled-components';
import { colors } from 'constants/colors';

export const OptionCardContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  padding: 5px;
  flex-direction: column;

  .optionCardTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .description {
      display: flex;
      align-items: center;
      height: 36px;
      font-weight: bold;
      background-color: ${colors.optionBackground};
      padding: 7px 10px;

      svg {
        position: relative;
        width: 21px;
        height: 21px;
        margin-right: 8px;
      }

      span {
        color: ${colors.white};
        font-size: 14px;
        line-height: 21px;
        height: 21px;
      }
    }
  }

  .optionCardAttributes {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-bottom: 20px;

    .roomAttribute {
      flex-basis: 31%;
      margin: 0 2% 0 0;
      align-items: center;
      font-size: 14px;

      svg {
        position: relative;
        width: 19px;
        height: 19px;
        margin-right: 8px;
        top: 4px;
      }
    }
  }

  .optionCardPrice {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: ${({selected}) => selected? `1px solid ${colors.optionBackground}`: `1px solid ${colors.signInBorderColor}`};
    padding: 11px 0;

    .customizedRadio {
      position: relative;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      border: 2px solid ${colors.signInBorderColor};
      margin: 0 10px;

      ${({selected}) => selected? `
        ::before {
          content: '';
          position: absolute;
          left: calc(50% - 6px);
          top: calc(50% - 6px);
          width: 12px;
          height: 12px;
          background-color: ${colors.radioGreen};
          border-radius: 50%;
        }
      `: null};
    }

    .priceDiff {
      font-size: 15px;
      font-weight: 600;
      margin-right: 10px;
    }

    .explanation {
      font-size: 11px;
      color: ${colors.textInputLabel};
    }
  }

  @media only screen and (max-width: 1200px) {
    .optionCardTitle {
      flex-direction: column;
      margin-bottom: 10px;

      .title {
        width: 100%;
      }

      .description {
        width: calc( 100% + 40px);
        padding: 7px 20px;
      }
    }

    .optionCardAttributes {
      flex-direction: column;
      row-gap: 10px;
      margin-bottom: 15px;
    }

    .optionCardPrice {
      width: calc( 100% + 40px);
      margin: 0 0 -15px -20px;
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }
`;