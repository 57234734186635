import React, { ReactNode, FC, useState, useEffect } from 'react';


const ErrorBoundary: FC<{ children: ReactNode }> = ({ children }) =>{
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error: Event | PromiseRejectionEvent, errorInfo?: React.ErrorInfo): void => {
      // You can log the error to an error reporting service
      const content = `Sorry, something get wrong, error caught by error boundary: ${JSON.stringify(error)}`
      console.log(content)
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('error', handleError);
      window.removeEventListener('unhandledrejection', handleError);
    };
  }, []);

    if (hasError) {
      return <h1>Something went wrong. Please Try Again</h1>;
    } else {
      return <>{children}</>
    }
}

export default ErrorBoundary;
