import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const CancelGroupBookingContainer = styled(BasicSectionContainer)`
  
    .cardContent {

       .removeBooking {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
       }
      .guestsHeader {
         font-size: 24px;
         font-weight: 600;
         line-height: 24px;
         margin-bottom: 32px;
         
       }



      .guestsTableHeader {
        justify-items: stretch;
        width: 100%;
        display: grid;
        grid-template-columns: 355fr 355fr 438fr 200fr;
        font-size: 15px;
        font-weight: 600;
        color: ${colors.black};
        padding: 20px 0;
        border-bottom: 1px solid ${colors.black};
        border-top: 1px solid ${colors.black};
        flex: 1;
        .col {
          text-align: left;
          font-size: 15px;
          font-weight: 600;
          line-height: 21px;
          padding-right: 10px;
        }
      }



.guestsTableContent {
  justify-items: stretch;
  width: 100%;
  display: grid;
        grid-template-columns: 355fr 355fr 438fr 200fr;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.black};
  padding: 20px 0;
  border-bottom: 1px solid ${colors.black};

  .col {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    padding-right: 10px;
  }

  .name {
    font-weight: 600;
  }

  .remove {
    color: ${colors.closeRed};
    text-decoration: underline;
    cursor: pointer;
  }

  &:last-child {
    border-bottom: 1px solid ${colors.white};
  }
}
    }

`;
