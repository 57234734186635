import styled from 'styled-components'

type ItemProps = {
  score: number;
  itemNum: number;
  barColors: string[];
}

export const BarContainer = styled.div`
  width: 100%;
  margin: 0 0 10px 0;
  
  .bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 2%;
    height: 8px;
  }

  .passwordStrengthBarText {
    width: 20%;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    margin: 10px;
  }
`;

export const Item = styled.div<ItemProps>`
  position: relative;
  width: 100%;
  height: 8px;
  background-color: ${({ score, itemNum, barColors }) => {
      let bgColor = barColors[0];
      if (score >= itemNum) {
        bgColor = barColors[score];
      }
      return bgColor;
    }
  };
`;