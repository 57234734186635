import React from 'react';
import { TextField } from '@material-ui/core';
import { FormTextFieldContainer, TextFieldContainerProps } from './style';

type MaterialUiTextField = React.ComponentProps<typeof TextField>;

type TextFieldProps = {
  label?: string;
  inputBackgroundColor?: string;
  labelWeight?: string;
  labelSize?: string;
  errorMessage?:  string;
} & TextFieldContainerProps &
  MaterialUiTextField;

const FormTextField: React.FC<TextFieldProps> = ({
  label,
  width,
  marginBottom,
  inputBackgroundColor,
  ...layout
}) => {
  return (
    <FormTextFieldContainer
      labelPosition={layout.labelPosition}
      labelColor={layout.labelColor}
      labelSize={layout.labelSize}
      labelWeight={layout.labelWeight}
      inputTextColor={layout.inputTextColor}
      borderColor={layout.borderColor}
      inputHeight={layout.inputHeight}
      error={layout.error}
      width={width}
      marginBottom={marginBottom}
      inputBackgroundColor={inputBackgroundColor}>
      {label? <label>{label}</label>: null}
      <TextField
        fullWidth={true}
        value={layout.value}
        size={layout.size}
        type={layout.type}
        variant={layout.variant}
        onChange={layout.onChange}
        inputRef={layout.inputRef}
        placeholder={layout.placeholder}
        disabled={layout.disabled}
        multiline={layout.multiline}
        maxRows={layout.maxRows}
        minRows={layout.minRows}
      />
      {layout.error? <div className="warning">{layout.errorMessage?.length? layout.errorMessage: 'This is a required field.'}</div>: null}
    </FormTextFieldContainer>
  );
};

export default FormTextField;
