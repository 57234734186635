import { FC, useState } from 'react'
import FormInputField from 'components/FormInputField'
import {
  CheckOutConfirmScreenContainer,
  InputContainer,
} from './style';
import StyledButton from 'components/Button';
import { useMutation } from '@apollo/client';
import { RESET_EMAIL_BY_TOKEN } from 'graphql/service';
import { colors } from 'constants/colors'



const CheckOutConfirmForm: FC<{
  resetToken: string,
  originalEmail: string,
  reservationCode: string
}> = ({ resetToken, originalEmail, reservationCode }) => {

  const [email, setEmail] = useState(originalEmail)
  const [emailError, setEmailError] = useState(false)

  const [resetEmail, { data: resetEmailData }] = useMutation(
    RESET_EMAIL_BY_TOKEN,
    {
      onCompleted: (resetEmailData)=> {
        if(resetEmailData.resetEmail?.userId) {
          console.log('Email updated successfully')
        } else {
          const content = 'Email update failed!'
          console.log(content)
        }
      },
      onError: (error)=> {
        const content = error.message
        console.log(content)
      }
    }
  )

  const handleSubmit = () => {
    if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      resetEmail({
        variables: {
          input: {
            newEmail: email,
            token: resetToken
          }
        }
      })
      setEmailError(false)
    } else {
      setEmailError(true)
    }
  }

  return (
    <CheckOutConfirmScreenContainer>
      <div className="titleContainer">
        <div className="innerContainer">
          <div className="checkEmailImage" />
          <div>
            <div className="titleHighlight">
              Check your Email
            </div>
            <div className="subtitle">
              You will be receiving an email giving you a link to access your new Rook Hotel account.
            </div>
          </div>
        </div>
      </div>
      <div className="changeEmailContainer">
        <div className="innerContainer">
          <div className="title">
            Not receiving it?
          </div>
          <div className="description">
            1. If you don’t see the email, check other places it might be, like your junk, spam, social or other folders.
          </div>
          <div className="description">
            2. Make sure your email address is correct:
          </div>
          <InputContainer>
            <div className="row">
              <FormInputField
                inputTextColor={colors.black}
                inputFontSize={'16px'}
                inputFontWeight={'400'}
                inputBackgroundColor={colors.white}
                borderColor={colors.signInBorderColor}
                error={emailError}
                onChange={(e) => {
                  setEmail(e.target.value)
                  if(emailError) {
                    setEmailError(false)
                  }
                }}
                value={email}
              />
              <StyledButton
                width="111px"
                height="40px"
                onClick={handleSubmit}
              >
                Change
              </StyledButton>
            </div>
          </InputContainer>  
          <div className="description">
            3. Wait up to 5 minutes. Depending on your email provider, it could take a while to arrive.
          </div>
        </div>    
      </div>
    </CheckOutConfirmScreenContainer>
  )
}

export default CheckOutConfirmForm;