import styled from 'styled-components';
import { colors } from 'constants/colors';

export type TextFieldContainerProps = {
  labelPosition?: 'left' | 'center';
  labelColor?: string;
  labelSize?: string;
  labelWeight?: string;
  borderColor?: string;
  inputTextColor?: string;
  inputHeight?: string;
  error?: boolean;
  width?: string;
  marginBottom?: string;
  inputBackgroundColor?: string;
};

export const FormTextFieldContainer = styled.div<TextFieldContainerProps>`
  position: relative;
  width: ${(props) => (props.width?? '100%')};
  margin-bottom: ${(props) => props.marginBottom?? '20px'};
  color: ${colors.white};

  .MuiTextField-root, .MuiInputBase-root {

    :focus-visible {
      outline: none;
    }
    
    ::before, ::after {
      display: none;
    }
  }

  .MuiInputBase-inputMultiline {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.error? colors.red: (props.borderColor?? colors.borderGrey)};
    color: ${(props) => props.inputTextColor?? colors.black};
    background-color: ${(props) => props.inputBackgroundColor?? colors.white};
    padding: 10px;
  }

  textarea:focus {
    border-color: ${(props) => props.error? colors.red: colors.hoverBlue};
  }

  label {
    width: 100%;
    display: block;
    font-size: ${(props) => (props.labelSize?? '14px')};
    font-weight: ${(props) => (props.labelWeight?? '400')};
    color: ${(props) => props.labelColor?? colors.userSelectionBlack};
    margin-bottom: 5px;
    letter-spacing: 0.75px;
    text-align: ${(props) => props.labelPosition === 'center'? 'center': 'left'};
  }

  .warning {
    width: ${(props) => (props.width?? null)};
    height: 20px;
    position: absolute;
    color: ${colors.red};
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
