import { useState, useEffect } from 'react';
import {
  searchResultState,
  isWheelchairAccessibleState,
  dateRangeState,
  guestQuantityState,
  roomQuantityState
} from 'states/SearchBarStates/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE } from 'graphql/service';

export const useGetAvailableRooms = () => {
  const [noAvailableRooms, setNoAvailableRooms] = useState(JSON.parse(process.env.REACT_APP_NO_AVAILABILITY || 'false'))
  //Global state for search options
  const dateRange = useRecoilValue(dateRangeState)
  const guestQuantity = useRecoilValue(guestQuantityState)
  const roomQuantity = useRecoilValue(roomQuantityState)
  const isWheelchairAccessible = useRecoilValue(isWheelchairAccessibleState)
  //Global state for search result
  const [searchResult, setSearchResult] = useRecoilState(searchResultState)

  const [getAvailableRoomTypesByDateRange, { data: availableRoomTypesData, loading, error }] = useLazyQuery(GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE, 
    {
      notifyOnNetworkStatusChange: true,
      onCompleted: (availableRoomTypesData)=> {
        if(!JSON.parse(process.env.REACT_APP_NO_AVAILABILITY || 'false')) {
          setSearchResult(availableRoomTypesData.availableRoomTypesByDateRange)
          setNoAvailableRooms(availableRoomTypesData.availableRoomTypesByDateRange.length === 0)
        }
      },
      onError: (error) => {
        const content = error.message
        console.log(content)
      }  
    }
  )

  const searchData = {
    checkInDate: dateRange[0],
    checkOutDate: dateRange[1],
    isWheelchairAccessible,
    roomQuantity,
    guestQuantity
  }

  /**
   * api call to get available rooms according to start day and end day
   * set search result and api status as global state
   */

  useEffect(() => {
    getAvailableRoomTypesByDateRange({
      variables: {
        input: searchData
      }
    }) 
  }, [
    dateRange[0],
    dateRange[1],
    guestQuantity,
    roomQuantity,
    isWheelchairAccessible,
    getAvailableRoomTypesByDateRange
  ])

  if(error) {
    const content = 'Available ROOMs Request failed!'
    console.log(content)
  }

  return {
    getAvailableRoomTypesByDateRange,
    searchData,
    searchResult,
    noAvailableRooms,
    loading,
    error
  }
}
