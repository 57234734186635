import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../../../../style';

export const AccountContentContainer = styled(BasicSectionContainer)`
  
  .card {

    .cardTitle {
      font-size: 24px;
      font-weight: 600;
      color: ${colors.black};
      margin-bottom: 16px;
    }

    .description {
      font-size: 18px;
      color: ${colors.black};
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

