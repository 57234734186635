import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from '../../style';
import optionBackground from 'assets/images/optionBackground.svg';

export const AddOnOptionsContentContainer = styled(BasicSectionContainer)`

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .background {
    width: 100%;
    height: 69px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${optionBackground});
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 20px;
  }

  @media only screen and (max-width: 767px) {

    .background {
      display: none;
    }
    
    .content {
      flex-direction: column;
      margin-bottom: 92px;
    }
  }
`;

export const AddOnOptionsContainer = styled(BasicSectionContainer)`
  flex-basis: 60%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 45px 0 50px 0;

  .addOnOptionsTitle {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const BookingSummaryContainer = styled.div`
  flex-basis: 40%;
  min-width: 400px;
  margin: 45px 0 0 20px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .text {
      font-size: 26px;
      margin-bottom: 10px;
    }

    .editTrip {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      color: ${colors.hoverBlue};

      .editIcon {
        margin-right: 8px;
      }
    }
  }

  .booking {
    font-size: 26px;
    margin: 38px 0 10px 0;
  }

  .bookingDates {
    .text {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .checkInOutDates {
      font-size: 18px;
    }
  }

  .guestOption, .roomOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.checkOutTableTextColor};

    .text {
      font-size: 14px;
      font-weight: 600;
    }

    .numbers {
      font-size: 21px;
      font-weight: 600;
    }

    .remove {
      cursor: pointer;

      .removeIcon {
        margin: 11px 0 4px 5px;
        height: 12px;
        width: 12px;
      }
    }
  }

  .priceDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.checkOutTableTextColor};

    .text {
      font-size: 14px;
      font-weight: 600;
    }

    .price {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 767px) {
    /* width: 100%;
    max-width: 100%;
    margin: 0 0 50px 0;

    .title {
      text-align: center;
    } */

    display: none;
  }
`;
