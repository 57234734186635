import styled from 'styled-components';
import { colors } from 'constants/colors';

export const MobileFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 92px;
  background: ${colors.white};
  justify-content: center;
  align-items: center;
  padding: 13px 20px;
  box-shadow: 0 0 5px 0 ${colors.borderGrey2};

  .bar {
    width: 20%;
    margin: 0 auto;
    height: 4px;
    border-radius: 2px;
    background-color: ${colors.reviewsBackground};
    margin-bottom: 13px;
  }

  .footerContent {
    width: 100%;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .bookingSummary {

        .price {
          font-size: 14px;
          font-weight: 600;
          color: ${colors.black};
          margin-bottom: 5px;
        }

        .nights {
          font-size: 14px;
          color: ${colors.black};
        }
      }
    }

    .exclamation {
      width: 60px;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  @media only screen and (min-width: 767px) {
    display: none;
  }
`;