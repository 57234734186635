import { FC, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UNSUBSCRIBE, RESUBSCRIBE, CREATE_UNSUBSCRIPTION_REASON } from 'graphql/service';
import { UnsubscribeScreenContainer } from './style';
import Checkbox from 'components/Checkbox';
import FormTextField from 'components/FormTextField';
import StyledButton from 'components/Button';

const UnsubscribeSection: FC = () => {

  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const params = new URLSearchParams(search);
  const email = params.get('email');
  const oneTimeToken = params.get('oneTimeToken');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reasons, setReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState('');
  const labels = [
    'Too many emails',
    'Content is not relevant',
    'Prefer not to receive any emails',
    'Found the content elsewhere',
    'Emails are too long',
    'Too much promotional content',
    'No longer interested in the topic',
    'Subscribed by mistake',
    'Emails are not visually appealing',
    'Privacy concerns',
    'Prefer to follow on social media',
    'Technical issues with email format',
  ];

  const checkboxes = labels.map((label, index) => {
    return (
      <Checkbox
        key={index}
        className="checkbox"
        onChange={() => {
          if(reasons.includes(label)) {
            const newReasons = reasons.filter((reason) => reason !== label)
            setReasons(newReasons)
          } else {
            const newReasons = [...reasons]
            newReasons.push(label)
            setReasons(newReasons)
          }
        }}
        spaceBetween={'20px'}
        label={label}
        checkboxScale={1.4}
        checked={reasons.includes(label)}
      />
    )
  })

  useEffect(() => {
    setTimeout(()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 500)
  }, [isSubmitted])

  const [unsubscribe] = useMutation(UNSUBSCRIBE, {
    onCompleted: (data) => {
      if(data.emailUnsubscription?.id) {
        console.log('You have been unsubscribed successfully')
      }
    },
    onError: (error) => {
      const content = 'Unsubscribe failed!'
      console.log(content)
    }
  })

  const [unsubscribeReason] = useMutation(CREATE_UNSUBSCRIPTION_REASON, {
    onCompleted: (data) => {
      if(data.emailUnsubscriptionReason?.id) {
        setIsSubmitted(true);
      }
    },
    onError: (error) => {
      const content = 'Submit failed!'
      console.log(content)
    }
  })

  const [resubscribe] = useMutation(RESUBSCRIBE, {
    onCompleted: (data) => {
      console.log('You have been resubscribed successfully')
      history.push('/')
    },
    onError: (error) => {
      const content = 'resubscribe failed!'
      console.log(content)
    }
  })

  useEffect(() => {
    unsubscribe({
      variables: {
        unsubscribeId: oneTimeToken,
      }
    });
  }, [])

  const handleSubmit = () => {
    const newReasons = [...reasons]
    if(otherReason.length > 0) {
      newReasons.push(otherReason)
    }

    if(newReasons.length === 0) {
      setIsSubmitted(true)
    } else {
      unsubscribeReason({
        variables: {
          unsubscribeId: oneTimeToken,
          input: {
            reasons: newReasons
          }
        }
      });
    }
  }

  const handleResubscribe = () => {
    resubscribe({
      variables: {
        unsubscribeId: oneTimeToken,
      }
    });
  }
  
  return (
    <UnsubscribeScreenContainer>
      <div className="titleContainer">
        <div className="innerContainer">
          <div className='titleText'>
            {!isSubmitted? (
              <div className="title">
                <div>Don’t leave us all alone!</div>
                <div className='highLight'>Not like this!</div>
              </div>
            ):(
              <div className="title">
                <div>Your abandonment is</div>
                <div>complete.</div>
              </div>
            )}
          </div>
          <div className="unsubscribeImage" />
        </div>
      </div>
      <div className="inputRow">
        {!isSubmitted? (
          <div className="innerContainer">
            <div className='titleText'>
              Tell Us Why You’re Breaking Up with Us
            </div>
            <div className='checkboxes'>{checkboxes}</div>
            <FormTextField
              className="textField"
              label={'Other:'}
              width='41%'
              maxRows={5}
              minRows={5}
              multiline
              value={otherReason}
              onChange={(e) => {
                setOtherReason(e.target.value);
              }}
            />
            <div className='buttonContainer'>
              <StyledButton
                width="98px"
                height="48px"
                margin='16px 59px 16px 59px'
                onClick={handleSubmit}
              >
                Submit
              </StyledButton>
            </div>
          </div>
        ):(
          <div className="innerContainer">
            <div className='description'>
              Thank you for providing your feedback.
            </div>
            <div className='description'>
              You will no longer receive any emails from us.
            </div>
            <div className='description'>
              If you have any questions or need further assistance, 
              please contact our support team at <a href='mailto: help@rookhotel.com'>help@rookhotel.com</a>.
            </div>
            <div className='buttonContainer'>
              <StyledButton
                width="149px"
                height="48px"
                margin='16px 59px 16px 59px'
                onClick={handleResubscribe}
              >
                Resubscribe
              </StyledButton>
            </div>
          </div>
        )}
      </div>
    </UnsubscribeScreenContainer>
  )
}

export default UnsubscribeSection;