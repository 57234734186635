import styled from 'styled-components';
import { colors } from 'constants/colors';
import { DotProps } from './Dots';

type SliderContainerProps = {
  borderRadius?: string;
};

export const SliderContainer = styled.div<SliderContainerProps>`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 171px;
  overflow-x: hidden;
  border-radius: ${(props) => (props.borderRadius?? null)};
  padding: 50px 0;

  @media only screen and (max-width: 767px) {
    padding: 80px 0;
  }
`;

type SliderContentProps = {
  width: string;
  translateWidth: number;
  transition: number;
};

export const SliderContent = styled.div<SliderContentProps>`
  width: ${(props) => props.width};
  height: 100%;
  transform: translateX(-${(props) => props.translateWidth}%);
  transition: transform ease-out ${(props) => props.transition}s;
  display: flex;
`;

export const DotsContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 42.5%;
  width: 15%;
  z-index: 100;

  .dots {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }

  .dotsTitle {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    top: auto;
    left: 35%;
    width: 30%;
    min-width: 100px;
    bottom: 25px;

    .dotsTitle {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: ${colors.generalInfoTextColor3};
      margin: 10px 0;
    }
  }
`;

export const DotContainer = styled.span<DotProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({active}) => active? colors.hoverBlue: colors.signInBorderColor};

  @media only screen and (max-width: 767px) {
    width: 10px;
    height: 10px;
  }
`;

type ArrowContainerProps = {
  direction: string;
};

export const ArrowContainer = styled.div<ArrowContainerProps>`
  display: flex;
  position: absolute;
  top: 45%;
  ${(props) => (props.direction === 'right' ? `right: 14px` : `left: 14px`)};
  height: 38px;
  width: 38px;
  justify-content: center;
  background: ${colors.white};
  border-radius: 50%;
  opacity: 0.6;
  cursor: pointer;
  align-items: center;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  img {
    transform: translateX(
      ${(props) => (props.direction === 'left' ? '-2' : '2')}px
    );
    &:focus {
      outline: 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Swipe = styled.span`
  position: absolute;
  bottom: -30px;
  right: 10px;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  @media only screen and (min-width: 1201px) {
    display: none;
  }
`;
