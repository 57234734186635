import React from 'react';
import { useHistory } from 'react-router-dom';
import ScreenLayout from 'components/ScreenLayout';
import MobileFooter from 'components/MobileFooter';
import SearchResultOptionsContent from './components';
import { useRecoilValue } from 'recoil';
import { searchResultState } from 'states/SearchBarStates/atoms';
import { selectedRooms } from 'states/CheckOutStates/atoms';

const SearchResultOptionsScreen: React.FC = () => {

  const history = useHistory();
  const persistSelectedRooms = useRecoilValue(selectedRooms);
  const searchResult = useRecoilValue(searchResultState);
  const windowRoomType = searchResult.find((roomType) => 
    (persistSelectedRooms.name === "Rook Inside" && roomType.name === "Rook") ||
    (persistSelectedRooms.name === "Knight Inside" && roomType.name === "Knight")
  )

  const handleContinueBooking = () => {
    if (windowRoomType) {
      history.push('/roomOptions');
    } else {
      //history.push('/addOnOptions');
      history.push('/checkOut');
    }
  }

  return (
    <ScreenLayout>
      <SearchResultOptionsContent handleContinueBooking={handleContinueBooking} />
      <MobileFooter handleContinueBooking={handleContinueBooking}/>
    </ScreenLayout>
  );
}
export default SearchResultOptionsScreen;
