import { useState, useEffect, useRef } from 'react';
import { selectedRooms } from 'states/CheckOutStates/atoms';
import {
  dateRangeState,
  guestQuantityState,
  roomQuantityState,
  isWheelchairAccessibleState,
  searchResultState
} from "states/SearchBarStates/atoms";
import { useRecoilState, useRecoilValue } from 'recoil'
import { useLazyQuery } from '@apollo/client';
import { GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE } from 'graphql/service';
import { RoomType } from 'types/commonTypes';



export const useTimeOut = () => {

  const [sessionTimeOut, setSessionTimeOut] = useState(false)
  const checkInDateRef = useRef<string | null>(null)
  const checkOutDateRef = useRef<string | null>(null)
  const roomQuantityRef = useRef<number | null>(null)
  const guestQuantityRef = useRef<number | null>(null)
  const isWheelchairAccessibleRef = useRef<boolean | null>(null)
  const persistSelectedRooms = useRecoilValue(selectedRooms)
  const [searchResult, setSearchResult] = useRecoilState(searchResultState)
  const dateRange = useRecoilValue(dateRangeState);
  const roomQuantity = useRecoilValue(roomQuantityState);
  const guestQuantity = useRecoilValue(guestQuantityState);
  const isWheelchairAccessible = useRecoilValue(isWheelchairAccessibleState);
  const checkInDate = dateRange[0];
  const checkOutDate = dateRange[1];

  const searchState ={
    checkInDate,
    checkOutDate,
    isWheelchairAccessible,
    roomQuantity,
    guestQuantity
  }

  const [
    getAvailableRoomTypesByDateRange, 
    { data: availableRoomTypesData,
      error,
      startPolling,
      stopPolling,
    }
  ] = useLazyQuery(GET_AVAILABLE_ROOM_TYPES_BY_DATE_RANGE, {
    variables: {
      input: searchState
    },
    onError: (error) => {
      const content = error.message
      console.log(content)
    }
  })

  useEffect(() => {
    startPolling(120000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  useEffect(() => {
    const prevCheckInDate = checkInDateRef.current
    const prevCheckOutDate = checkOutDateRef.current
    const prevRoomQuantity = roomQuantityRef.current
    const prevGuestQuantity = guestQuantityRef.current
    const prevIsWheelchairAccessible = isWheelchairAccessibleRef.current
    if(prevCheckInDate === checkInDate
      && prevCheckOutDate === checkOutDate
      && prevRoomQuantity === roomQuantity
      && prevGuestQuantity === guestQuantity
      && prevIsWheelchairAccessible === isWheelchairAccessible
    ) {
      if(availableRoomTypesData) {
        let timeOut = false
        if(window.location.pathname!=='/search') {
          const updatedRoomType = availableRoomTypesData.availableRoomTypesByDateRange.find((roomType: RoomType) => roomType.roomTypeId === persistSelectedRooms.roomTypeId)
          if(updatedRoomType) {
            if(updatedRoomType.pricePerNight !== persistSelectedRooms.pricePerNight) {
              timeOut = true
            }
          } else {
            timeOut = true
          }
        } else {
          searchResult.forEach((roomType: RoomType) => {
            const updatedRoomType = availableRoomTypesData.availableRoomTypesByDateRange.find((availableRoomType: RoomType) => availableRoomType.roomTypeId === roomType.roomTypeId)
            if(updatedRoomType) {
              if(updatedRoomType.pricePerNight !== roomType.pricePerNight) {
                timeOut = true
              }
            } else {
              timeOut = true
            }
          })
        }

        if(timeOut) {
          setSearchResult(availableRoomTypesData.availableRoomTypesByDateRange)
          setSessionTimeOut(true)
        }
      }
    } else {
      getAvailableRoomTypesByDateRange({
        onCompleted: (availableRoomTypesData)=> {
          if(!JSON.parse(process.env.REACT_APP_NO_AVAILABILITY || 'false')) {
            setSearchResult(availableRoomTypesData.availableRoomTypesByDateRange)
            checkInDateRef.current = checkInDate
            checkOutDateRef.current = checkOutDate
            roomQuantityRef.current = roomQuantity
            guestQuantityRef.current = guestQuantity
            isWheelchairAccessibleRef.current = isWheelchairAccessible
          }
        }
      })
    }
  }, [
    availableRoomTypesData,
    checkInDate,
    checkOutDate,
    roomQuantity,
    guestQuantity,
    isWheelchairAccessible,
    searchResult,
    persistSelectedRooms,
    setSearchResult,
    setSessionTimeOut,
    getAvailableRoomTypesByDateRange
  ])

  if(error) {
    const content = 'Available ROOMs Request failed!'
    console.log(content)
  }

  return {
    sessionTimeOut,
    startPolling,
    stopPolling
  }
}
