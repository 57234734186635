import styled from 'styled-components';
import { colors } from 'constants/colors';
import DropDownArrowIcon from 'assets/icons/downArrow.svg'

export type DropdownContainerProps = {
  width: string,
  selectorHeight?: string,
  isActive: boolean
}

export const DropdownContainer = styled.div<DropdownContainerProps>`
  display:  ${(props) => (props.isActive? 'flex': 'none')};
  flex-direction: column;
  margin: 0;
  position: absolute;
  top: 48px;
  right: 0;
  width: ${(props) => (props.width?? '100%')};

  .options {
    max-height: 290px;
    overflow-y: scroll;
    letter-spacing: 0.5px;
    width: 100%;
    border: solid ${colors.dropDownBorder};
    border-width: 1px 0 0 0; 
    z-index: 2500;

    ::-webkit-scrollbar { 
      width: 0 !important; 
    }

    ::-moz-scrollbar {
      width: 0 !important; 
    }
  }

  .option {
    border: solid ${colors.dropDownBorder};
    border-width: 0px 1px 1px 1px; 
    cursor: pointer;
  }
`;

export const StyledItem = styled.div`
  width: 100%;
  height: 36px;
  line-height: 35px;
  font-size: 16px;
  font-weight: normal;
  padding-left: 10px;
  color: ${colors.userSelectionBlack};
  background-color: ${colors.white};
  overflow: hidden;

  :hover {
    color: ${colors.almostBlack};
    background-color: ${colors.inputDisabled};
    padding-left: 10px;
  }
`;