import styled from 'styled-components';
import { colors } from 'constants/colors';

export type InputFieldContainerProps = {
  labelWeight?: string;
  labelSize?: string;
  labelPosition?: 'left' | 'center';
  labelColor?: string;
  inputBackgroundColor?: string;
  borderColor?: string;
  inputFontSize?: string;
  inputFontWeight?: string;
  inputColor?: string;
  inputHeight?: string;
  inputWidth?: string;
  error?: boolean;
  success?: boolean;
  width?: string;
  margin?: string;
  iconUrl?: string;
};

export const VerificationInputFieldContainer = styled.div<InputFieldContainerProps>`
  position: relative;
  width: ${(props) => (props.width?? '100%')};
  margin: ${(props) => props.margin?? '0 0 20px 0'};
  color: ${colors.white};

  .icon {
    position: absolute;
    left: 200px;
    top: 45px;
    width: 20px;
    height: 20px;
    z-index: 100;
  }

  .blockContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    input {
      width: ${(props) => (props.inputWidth?? '48px')};
      height: ${(props) => (props.inputHeight?? '48px')};
      font-size: ${(props) => (props.inputFontSize?? '28px')};
      font-weight: ${(props) => (props.inputFontWeight?? 'bold')};
      box-sizing: border-box;
      border: 1px solid
        ${(props) => props.error? colors.red: (props.borderColor?? colors.signInBorderColor)};
      color: ${(props) => props.inputColor?? colors.black};
      text-align: center;
      background-color: ${(props) => props.inputBackgroundColor?? colors.white};
    }
  }

  label {
    width: 100%;
    display: block;
    font-size: ${(props) => (props.labelSize?? '14px')};
    font-weight: ${(props) => (props.labelWeight?? '400')};
    color: ${(props) => props.error? colors.red: (props.labelColor?? colors.labelGrey)};
    margin-bottom: 5px;
    letter-spacing: 0;
    text-align: ${(props) => props.labelPosition === 'center'? 'center': 'left'};

    span {
      font-size: 18px;
      color: ${colors.red};
    }
  }

  .warning {
    width: 100%;
    height: 20px;
    line-height: 20px;
    position: absolute;
    color: ${colors.red};
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
    text-align: left;
    margin-top: 5px;
  }

  .success {
    width: 100%;
    height: 20px;
    line-height: 20px;
    position: absolute;
    color: ${colors.questionLinkText};
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
  }

  @media only screen and (max-width: 767px) {
    .warning {
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
    }
  }
`;
