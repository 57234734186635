import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import optionBackground from 'assets/images/optionBackground.svg';

export const HelpScreenContainer = styled(BasicSectionContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .background {
    width: 100%;
    height: 56px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${optionBackground});
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    padding: 72px 0;
  }
  .contOne {
    display: flex;
    flex-direction: row;
    gap: 48px;
    @media all and (max-width: 768px) {
      flex-direction: column;
    }
    .contTwo {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      flex: 870;
      @media all and (max-width: 600px) {
        grid-template-columns: 1fr;
       }
      .contentBox {
        padding: 16px;
        border: 1px solid ${colors.helpBoxBorder};
        cursor: pointer;
        &:hover {
          border: 1px solid ${colors.helpBoxBorderHover};
        }
        .contentBoxTitle {
          display: flex;
          flex-direction: row;
          gap: 11px;
          font-size: 18px;
          font-weight: 600;
          align-items: center;
        }
        .contentBoxContent {
          font-size: 16px;
          line-height: 24px;
          margin-top: 8px;
        }
        .contentBoxLink {
          color: ${colors.hoverBlue};
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .contentBoxGreen {
      flex: 334;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid ${colors.optionBackground};
      background-color: ${colors.helpBoxBackground};
      padding: 26px 24px;
      .contentBoxGreenTitle {
        font-size: 27px;
        line-height: 27px;
        margin: 25px 0 16px;
        padding: 0;
        font-weight: 600;
        color: ${colors.almostBlack};
      }
      .contentBoxGreenTextOne {
          font-size: 16px;
          line-height: 27px;
          margin-bottom: 27px;
          text-align: center;
          color: ${colors.almostBlack};
      }

      .contentBoxGreenTextTwo {
        font-size: 13px;
        font-weight:600;
        line-height: 13px;
        margin-bottom: 24px;
      }
      .contentBoxGreenPhone {
        color: ${colors.optionBackground};
        font-weight: 600;
        font-size: 24px;
      }
    }
  }

`;