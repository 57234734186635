import styled, { keyframes } from 'styled-components';
import { colors } from 'constants/colors';
import { styleSizes } from "constants/styleSizes";

type AlertMessageContainerProps = {
    backgroundColor?: string; // Optional background color
    width?: string; // Width of the container
    positionRight?: string; // Bottom position
    positionTop?: string; // Left position
    // Optional properties for different screen sizes
    widthTablet?: string;
    positionRightTablet?: string;
    positionTopTablet?: string;

    widthMini?: string;
    positionRightMini?: string;
    positionTopMini?: string;

    positionTopMobile?: string;
};



// Define fade-in animation
const fadeIn = keyframes`
    from {
        opacity: 0; // Start fully transparent
        transform: translateY(-10px); // Move up slightly
    }
    to {
        opacity: 1; // End fully opaque
        transform: translateY(0); // Move back to original position
    }
`;

// Define fade-out animation
const fadeOut = keyframes`
    from {
        opacity: 1; // Start fully opaque
        transform: translateY(0); // Start at original position
    }
    to {
        opacity: 0; // End fully transparent
        transform: translateY(-10px); // Move up slightly
    }
`;



export const AlertMessageContainer = styled.div<AlertMessageContainerProps>`
    position: absolute; // Absolute positioning
    padding: 16px 44px 16px 38px; // Padding inside the container
    color: ${colors.white}; // Text color
    font-size: 16px; // Font size
    font-weight: 600; // Font weight
    line-height: 26px; // Line height
    z-index: 200;
    box-shadow: ${colors.helpBoxBorder} 0px 1px 6px 0px;

    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : colors.optionBackground)}; // Background color
    width: ${(props) => (props.width ? props.width : '400')}px; // Width of the container
    right: ${(props) => (props.positionRight ? props.positionRight : '30')}px; // Right position
    top: ${(props) => (props.positionTop ? props.positionTop : '-55')}px; // Top position

    // Apply fade-in animation on mount
    animation: ${fadeIn} 0.3s ease-in;

    // Apply fade-out animation when fade-out class is present
    &.fade-out {
        animation: ${fadeOut} 0.3s ease-in; // Match this duration with the timeout in the component
    }

    .messageCross {
        position: absolute; // Absolute positioning for close button
        width: 10px; // Width of the close icon
        height: 10px; // Height of the close icon
        right: 15px; // Position from the right
        top: 16px; // Position from the top
        cursor: pointer; // Change cursor to pointer
    }

    .messageDone {
        position: absolute; // Absolute positioning for done icon
        width: 14px; // Width of the done icon
        height: 14px; // Height of the done icon
        left: 16px; // Position from the left
        top: 21px; // Position from the top
    }

    // Media queries for responsive design
    @media (max-width: ${styleSizes.tabletBreakpoin}) {
        // Adjust width for tablet
        width: ${(props) => (props.widthTablet ? props.widthTablet : '400')}px; // Width of the container
        right: ${(props) => (props.positionRightTablet ? props.positionRightTablet : '30')}px; // Right position
        top: ${(props) => (props.positionTopTablet ? props.positionTopTablet : '-45')}px; // Top position
    }
    @media (max-width: ${styleSizes.miniBreakpoint}) {
        // Adjust width for mini screens
        width: ${(props) => (props.widthMini ? props.widthMini : '400')}px; // Width of the container
        right: ${(props) => (props.positionRightMini ? props.positionRightMini : '30')}px; // Right position
        top: ${(props) => (props.positionTopMini ? props.positionTopMini : '-45')}px; // Top position
    }
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
        // Adjust width for mobile
        width: auto; // Width of the container
        top: ${(props) => (props.positionTopMobile ? props.positionTopMobile : '-32')}px; // Top position
        left: 0; // stick message left
        right: 0; // stick message right
    }
`;