import React from 'react';
import { DotsContainer, DotContainer } from './style';

type DotsProps = {
  slides: JSX.Element[];
  activeIndex: number;
  setState: React.Dispatch<React.SetStateAction<{
    activeIndex: number;
    translate: number;
    transition: number;
  }>>;
};

export type DotProps = {
  active: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Dots: React.FC<DotsProps> = ({ slides, activeIndex, setState }) => {

  return (
    <DotsContainer>
      <div className='dotsTitle'>Swipe to view</div>
      <div className='dots'>
        {slides.map((element, index) => {
          if(index===slides.length-1) {
            return null
          } else {
            return (
              <DotContainer
                key={index}
                active={activeIndex % (slides.length-1)=== index}
                onClick={() => {
                  setState((state) => {
                    return {
                      ...state,
                      activeIndex: index,
                      translate: index,
                    }
                  });
                }}
              />
            )
          }
        })}
      </div>
    </DotsContainer>
  );
};

export default Dots;
