import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import forgotPassword from 'assets/icons/forgotPassword.svg';
import passwordRetrieved from 'assets/icons/passwordRetrieved.svg';

export const SetPasswordScreenContainer = styled(BasicSectionContainer)`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .innerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.forgotPasswordBackground};
    width: 100%;
    height: 414px;
    padding: 0 15px;

    .titleText {
      width: 100%;
      margin: 0 20px 0 0;

      .title {
        font-size: 48px;
        font-weight: bold;
        line-height: 72px;
        text-align: left;
        color: ${colors.white};

        .highLight {
          color: ${colors.checkOutCompleteScreenHighlight};
        }
      }
    }

    .forgotPasswordImage {
      width: 567px;
      min-width: 567px;
      height: 356px;
      background-image: url(${forgotPassword});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 40% top;
      margin: 58px 0 0 0;
    }

    .passwordRetrievedImage {
      width: 573px;
      min-width: 573px;
      height: 373px;
      background-image: url(${passwordRetrieved});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin: 41px 0 0 0;
    }
  }

  .inputRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 15px;

    .innerContainer {
      display: flex;
      flex-direction: column;
      width: 562px;
      min-width: 270px;
      padding: 44px 0;

      .description {
        font-size: 16px;
        font-weight: 600;
        color: ${colors.black};
        margin: 15px 0;

        .highLight {
          font-weight: 600;
        }
      }

      .contactText {
        font-size: 16px;
        color: ${colors.black};

        .link {
          color: ${colors.hoverBlue};
        }
      }

      .buttonContainer {
        width: 238px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .contactContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
      }

      .retrievedContainer {
        width: 448px;
        padding: 0 20px;

        .highLight {
          font-weight: 600;
          color: ${colors.hoverBlue};
        }

        .link {
          color: ${colors.hoverBlue};
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {

    .innerContainer {
      flex-direction: column-reverse;
      padding: 0;
      margin: 0;
    }

    .titleContainer {
      height: auto;

      .titleText {
        margin: 20px 0;

        .title {
          text-align: center;
          font-size: 36px;
        }

        .description {
          text-align: center;
        }
      }

      .forgotPasswordImage, .passwordRetrievedImage {
        max-width: 270px;
        min-width: 270px;
        height: 183px;
        margin-top: 20px;
      }
    }
  }
`;