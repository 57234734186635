import React from "react";
import { AddOnOptionsContentContainer } from "./style";
import AddOnOptions from "./AddOnOptions";
import BookingSummary from "./BookingSummary";
import TimeOut from "components/TimeOut";

const AddOnOptionsContent: React.FC = () => {
  return (
    <AddOnOptionsContentContainer>
      <div className="background" />
      <div className="innerContainer">
        <div className="content">
          <AddOnOptions />
          <BookingSummary />
        </div>
      </div>
      <TimeOut />
    </AddOnOptionsContentContainer>
  );
};

export default AddOnOptionsContent;
