import styled from 'styled-components';
import timeOutPicture from 'assets/images/timeOut.png';
import { colors } from 'constants/colors';

export const TimeOutContainer = styled.div`
  position: relative;
  width: 80%;
  max-width: 855px;
  height: 596px;
  z-index: 500;

  .timeOutImage {
    width: 100%;
    height: 100%;
    background-image: url(${timeOutPicture});
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: transparent;
    margin-top: 25px;
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    width: 80%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 0px;
  right: -25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${colors.white};
  cursor: pointer;

  svg {
    position: absolute;
    top: 17px;
    left: 17px;
  }
`