import { colors } from 'constants/colors';
import styled from 'styled-components';

export const DatePickerModalContainer = styled.div`
  position: absolute;
  top: 100px;
  width: 700px;
  height: 580px;
  background-color: ${colors.white};
  box-shadow: 0px 1px 3px 0px rgb(60 64 67 / 30%),
    0px 4px 8px 3px rgb(60 64 67 / 15%);
  border-radius: 4px;
  border: 1px solid transparent;
  padding: 16px;
  box-sizing: border-box;
  z-index: 3000;

  .datePickerTitle{
    display: none;
  }

  .closeButton {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 32px;
    cursor: pointer;
  }

  @media only screen and (max-width: 767px) {
    top: 56px;
    width: 100%;
    padding: 15px 0px;
    height: 100%;

    .datePickerTitle{
      display: block;
      font-weight: bold;
      font-size: 30px;
      margin: 0;
      margin-left: 25px;
    }
  }
`;

export const TopDatePickersContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;

  .dateInputContainer {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

type DateInputProps = {
  error: boolean;
};

export const DateInput = styled.div<DateInputProps>`
  width: 140px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  input {
    width: 100%;
    height: 90%;
    border: 1px solid ${(props) => (props.error ? colors.red : colors.black)};
    border-radius: 4px;
    text-align: center;
    font-size: 16px;

    :focus {
      border-color: ${colors.hoverBlue};
    }

    :focus-visible {
      outline: none;
    }
  }

  label {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const MiddleCalenderContainer = styled.div`
  display: flex;
  justify-content: center;

  .MuiPickersDesktopDateRangeCalendar-calendar {
    min-height: 240px;
  }

  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: ${colors.lightHoverBlue};
  }

  .Mui-selected {
    background-color: ${colors.hoverBlue};
  }

  .Mui-selected:hover {
    background-color: ${colors.hoverBlue};
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;

    .MuiPickersDesktopDateRangeCalendar-root {
      margin-bottom: 80px;
      flex-direction: column;
    }

    .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer {
      border: none;
    }

    .MuiPickersDesktopDateRangeCalendar-calendar {
      height: 60vw;
      min-height: 60vw;
    }

    .MuiPickersCalendar-daysHeader{
      display: none;
    }

    .MuiTypography-displayInline {
      font-size: 21px;
      font-weight: 600;
      font-family: 'Titillium Web', sans-serif !important;
    }

    .MuiPickersDay-root {
      font-size: 16px;
    }

    .MuiPickersDateRangeDay-root {
      width: 9vw;
      height: 9vw;
    }

    .MuiPickersDateRangeDay-day {
      width: 100%;
      height: 100%;
    }

    .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
      background-color: ${colors.lightHoverBlue};
    }

    .MuiPickersDateRangeDay-day > * {
      transform: scale(1);
    }

    .MuiPickersDay-root:focus {
      background-color: ${colors.hoverBlue};
    }

    .MuiPickersDateRangeDay-rangeIntervalPreview {
      border: 2px solid transparent;
      height: 9vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .MuiPickersCalendar-week {
      display: flex;
    }
  }
`;

export const BottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media only screen and (max-width: 767px) {
    padding: 23px 29px 23px 29px;
    position: fixed;
    bottom: 0;
    margin-top: 0;
    background-color: ${colors.white};
    box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.21);
  }
`;
