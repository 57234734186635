import React, { useState } from "react";
import useWindowSize from "customHooks/useWindowSize";
import DatePickerModal from "components/DatePickerModal";
import { DatePickerContainer, SearchBarDateInput } from "./style";
import { DateIcon } from "assets/icons";
import { Icon } from "components/Icon";
import moment from "moment";

type DatePicker = {
  label: string;
  dateRange: string[];
};

const StyledDatePicker: React.FC<DatePicker> = ({ label, dateRange }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <DatePickerContainer>
      <SearchBarDateInput
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <label>{label}</label>
        <input
          value={`${moment(dateRange[0]).format("ddd, MMM DD")} - ${moment(dateRange[1]).format("ddd, MMM DD")}`}
          readOnly
        />
      </SearchBarDateInput>
      <Icon width={30} height={30}>
        <DateIcon
          onClick={() => {
            setOpenModal(true);
          }}
        />
      </Icon>
      <DatePickerModal openModal={openModal} setOpenModal={setOpenModal} />
    </DatePickerContainer>
  );
};

export default StyledDatePicker;
