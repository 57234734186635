import styled from 'styled-components';
import { colors } from 'constants/colors';

export const BlogPostContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  .link {
    font-weight: 700;
    font-size: 15px;
    color: ${colors.hoverBlue};
    cursor: pointer;
  }

  .contentTitleContainer {
    text-align: left;
    width: 100%;
    font-size: 28px;
    height: 43px;
    line-height: 43px;
    font-weight: 600;
    margin: 5px 0;
  }

  .contentDescriptionContainer {
    width: 100%;

    p {
      padding: 0 25px 0 0;
      text-align: left;
      height: 115px;
      font-size: 15px;
      color: ${colors.homeDescription};
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 0px;
  }
`;

type ImageContainerProps = {
  imageHeight?: string;
  imageWidth?: string;
};

export const ImageContainer = styled.picture<ImageContainerProps>`
  display: block;

  img {
    width: ${(props) => (props.imageWidth? props.imageWidth: '100%')};
    height: ${(props) => (props.imageHeight? props.imageHeight: '221px')};
    object-fit: cover; 
  }
`;