import React from 'react';
import ScreenLayout from 'components/ScreenLayout';
import HelpSection from './components/HelpSection';

const HelpScreen: React.FC = () => {

  return (
    <ScreenLayout disableModal>
      <HelpSection />
    </ScreenLayout>
  )
}

export default HelpScreen
