import styled from "styled-components";
import { colors } from "constants/colors";
import { BasicSectionContainer } from "../../../style";
import { styleSizes } from "constants/styleSizes";

export const ProfileContainer = styled(BasicSectionContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: stretch;
  gap: 32px;

  .cardContent {
    /* Styles inside BasicSectionContainer */
    .topText {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 47px;
      line-height: 24px;
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        display: none;
      }
    }
    .subsectionLinks {
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media (max-width: ${styleSizes.tabletBreakpoin}) {
        gap: 0;
      }
    }
    .subsectionLink {
      display: flex;
      flex-direction: row;
      border: 1px solid ${colors.profileBorder}; /* Same border on all sides */
      padding: 19px 16px;
      background-color: ${colors.white};
      gap: 20px;
      cursor: pointer;
      z-index: 1;
      &:hover {
        z-index: 100;
        border-color: ${colors.hoverBlue}; /* Change border color on hover */
        .subsectionLinkArrow,
        .itemIcon {
          filter: brightness(0) saturate(100%) invert(20%) sepia(100%)
            saturate(5000%) hue-rotate(190deg);
        }
        .subsectionLinkBodyTitle span {
          color: ${colors.hoverBlue};
        }
      }

      @media (max-width: ${styleSizes.tabletBreakpoin}) {
        /* For smaller screens */
        
        padding: 0;
        height: 57px;
        align-items: center;

        border-top: 1px solid ${colors.profileBorder};
        border-bottom: 1px solid ${colors.profileBorder};
        border-left: 1px solid ${colors.white}; /* White borders on the left and right */
        border-right: 1px solid ${colors.white};
        margin-bottom: -1px;

        &:hover {
          /* Only top and bottom borders change on hover */
          border-left: 1px solid ${colors.white};
          border-right: 1px solid ${colors.white};
          border-top: 1px solid ${colors.hoverBlue};
          border-bottom: 1px solid ${colors.hoverBlue};
        }
      }

      .subsectionLinkBodyTitle {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        margin-bottom: 19px;
        @media (max-width: ${styleSizes.tabletBreakpoin}) {
          margin-bottom: 0;
        }
        span {
          font-size: 16px;
          font-weight: 700;
          @media (max-width: ${styleSizes.tabletBreakpoin}) {
            font-weight: 400;
        }
        }
      }
      .subsectionLinkBodyText {
        font-size: 16px;
        font-weight: 400;
        @media (max-width: ${styleSizes.tabletBreakpoin}) {
          display: none;
        }
      }

      .iconContactInformation {
        width: 24px;
        height: 18px;
        @media (max-width: ${styleSizes.tabletBreakpoin}) {
          width: 24px;
          height: auto;
        }
      }

      .iconPersonalEmailIcon {
        width: 24px;
        height: 18px;
        @media (max-width: ${styleSizes.tabletBreakpoin}) {
          width: 24px;
          height: auto;
        }
      }

      .iconPersonalPhoneIcon {
        width: 15px;
        height: 18px;
        @media (max-width: ${styleSizes.tabletBreakpoin}) {
          width: 24px;
          height: auto;
        }
      }

      .subsectionLinkBody {
        flex: 1;
      }
      .subsectionLinkArrow {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
`;
