import React, { useState, useEffect } from 'react';
import { AlertMessageContainer } from './styles';
import messageCross from 'assets/images/messageCross.svg';
import messageDone from 'assets/images/messageDone.svg';
import { colors } from 'constants/colors';

type AlertMessageProps = {
    children: React.ReactNode; // Content to be displayed inside the AlertMessage
    showMessage: boolean; // Indicates whether to show the message
    onClose: () => void; // Function to call when the message is closed
    backgroundColor?: string; // Optional background color
    messageType?: string;  // Optional meassage type - green message dafault
    width?: string; // Width of the AlertMessage
    positionRight?: string; // Bottom position
    positionTop?: string; // Left position

    // Optional properties for different screen sizes
    widthTablet?: string;
    positionRightTablet?: string;
    positionTopTablet?: string;

    widthMini?: string;
    positionRightMini?: string;
    positionTopMini?: string;

    widthMobile?: string;
    positionRightMobile?: string;
    positionTopMobile?: string;
};

const debug = false;


export const AlertMessage: React.FC<AlertMessageProps> = ({
    showMessage,
    onClose, // Add the onClose prop
    children,
    backgroundColor,
    messageType,
    width,
    positionRight,
    positionTop,

    widthTablet,
    positionRightTablet,
    positionTopTablet,
    
    widthMini,
    positionRightMini,
    positionTopMini,

    positionTopMobile,
}) => {
    const [isVisible, setIsVisible] = useState(showMessage); // Start visibility based on showMessage prop
    const [isFadingOut, setIsFadingOut] = useState(false);

    const messageTypeSelector = messageType ? messageType : 'message';

    let backgroundColorSelector = backgroundColor
    ? backgroundColor
    : messageTypeSelector === 'error'
    ? colors.errorRed
    : colors.optionBackground;


    if (debug) {
        console.log ('showMessage = ' + showMessage);
    }

    const handleClose = () => {
        setIsFadingOut(true); // Trigger the fade-out animation
        setTimeout(() => {
            setIsVisible(false); // Hide the alert message after fade-out completes
            onClose(); // Call onClose prop to notify parent component
        }, 200); 
    };



    useEffect(() => {
        if (showMessage) {
            setIsVisible(true); // Show alert if showMessage is true
            setIsFadingOut(false); // Reset fading out state
            const timer = setTimeout(() => {
                handleClose(); // Call close handler after 10 seconds
            }, 10000);
    
            return () => clearTimeout(timer);
        } else {
            setIsFadingOut(true); // Trigger fade-out when showMessage becomes false
            const fadeOutTimer = setTimeout(() => {
                setIsVisible(false); // Hide the alert after the fade-out completes
            }, 200); // Match the fade-out duration with animation timing
    
            return () => clearTimeout(fadeOutTimer); // Clear fade-out timeout when showMessage changes
        }
    }, [showMessage]);

    if (!isVisible) return null;

    return (
        <AlertMessageContainer
            className={`dateHint ${isFadingOut ? 'fade-out' : ''}`}
            width={width}
            backgroundColor={backgroundColorSelector}
            positionRight={positionRight}
            positionTop={positionTop}

            widthTablet={widthTablet}
            positionRightTablet={positionRightTablet}
            positionTopTablet={positionTopTablet}

            widthMini={widthMini}
            positionRightMini={positionRightMini}
            positionTopMini={positionTopMini}

            positionTopMobile={positionTopMobile}
        >
            <img
                src={messageCross}
                className="messageCross"
                alt="Close"
                onClick={handleClose} // Close the alert on click
            />
                {messageTypeSelector === 'message' && (
                    <img src={messageDone} alt="Done" className="messageDone" />
                )}

            {children} {/* Render the children content */}
        </AlertMessageContainer>
    );
};

export default AlertMessage;