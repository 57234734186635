import styled from 'styled-components'
import { colors } from 'constants/colors';

export const CompleteAlertContainer = styled.div`
  width: 100%;
  line-height: 49px;
  background-color: ${colors.green};
  display: flex;
  justify-content: center;
  align-items: center;

  .alertContent {
    display: flex;
    color: ${colors.white};
  }

  .reservationCode{
    font-weight: bold;
    color: ${colors.white};
  }

  svg {
    margin-right: 35px;
  }

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    padding: 16px 0;
    line-height: 28px;
    .alertContent {
      display: block;
    }
  }
`;