import { useState } from 'react';
import moment from 'moment';
import { GroupTenant, Trips } from 'types/commonTypes';
import { useQuery } from '@apollo/client';
import { GET_TENANT_INFO_BY_USER_ID } from 'graphql/service';



export const useLoadTrips = () => {

  const [trips, setTrips] = useState<Trips>({})
  const userId = localStorage.getItem('userId')

  const { loading, error, refetch } = useQuery(
    GET_TENANT_INFO_BY_USER_ID,
    {
      variables: { userId },
      onCompleted: (tenantInfoData) => {
        const groups = tenantInfoData.tenantByUserID.groups
        const trips: Trips = {}
        groups.forEach((groupTenant: GroupTenant)=>{
          if(groupTenant.group) {
            const group = groupTenant.group
            trips[group.groupId] = group
          }
        })
        setTrips(trips)
      },
      onError: (error) => {
        const content = error.message
        console.log(content)
      }
    }
  )

  return {
    trips,
    loading,
    error,
    refetch
  }
}
