import styled from 'styled-components';
import { colors } from 'constants/colors';
import { BasicSectionContainer } from 'screens/style';
import optionBackground from 'assets/images/optionBackground.svg';

export const FrequentlyAskedQuestionsScreenContainer = styled(BasicSectionContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .background {
    width: 100%;
    height: 69px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${optionBackground});
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    padding: 64px 0;

    .title {
      width: 100%;
      font-size: 48px;
      text-align: center;
      font-weight: bold;
      color: ${colors.black};
      margin: 0 0 16px 0;
    }

    .subtitle {
      width: 100%;
      font-size: 32px;
      text-align: left;
      font-weight: bold;
      color: ${colors.black};
      margin: 48px 0 0 0;
    }

    .questions {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 60px;
      margin: 16px 0 0 0;

      .category {
        width: 30%;

        .categoryTitle {
          width: 100%;
          font-size: 24px;
          text-align: left;
          font-weight: bold;
          color: ${colors.hoverBlue};
          margin: 32px 0;
        }

        p {
          width: 100%;
          font-size: 16px;
          text-align: left;
          font-weight: 400;
          color: ${colors.black};
          margin: 16px 0 0 0;

          .highLight {
            font-weight: bold;
            margin-bottom: 8px;
          }

          .link {
            margin-left: 5px;
            color: ${colors.hoverBlue};
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }




    p.ending {
      width: 100%;
      font-size: 16px;
      text-align: left;
      font-weight: 400;
      color: ${colors.black};
      margin: 48px 0 64px 0;
    }

    ul {
      margin: 0;
      padding-inline-start: 48px;

      li {
        width: 100%;
        font-size: 16px;
        text-align: left;
        font-weight: 400;
        color: ${colors.black};
        list-style-type: circle;
        margin: 16px 0 0 0;
        padding-right: 16px;


      }
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1200px) {

    .title {
      font-size: 30px;
    }

    .subtitle {
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 767px) {

    .title {
      font-size: 24px;
    }

    .subtitle {
      font-size: 16px;
    }

    .description {
      font-size: 14px;
    }
  }
`;