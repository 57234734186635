import styled from 'styled-components';
import { colors } from 'constants/colors';
import { styleSizes } from "constants/styleSizes";

export const AccountModalContainer = styled.section`
  max-width: 490px;
  width: 100%;
  //height: 273px;
  background-color: ${colors.white};
  z-index: 300;
  border-radius: 10px;
  position: relative;
  padding: 16px 32px 32px 32px;
  @media (max-width: ${styleSizes.mobileBreakpoint}) {
        margin: 24px;
        width: 90%;
        padding: 16px 32px 24px 32px;
    }
  .closeButton {
    position: absolute;
    right: 32px;
    top: 24px;
  }
  .accounModalHeader {
    margin: 0;
    padding: 6px 0 18px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    border-bottom: 1px solid ${colors.borderGrey3};
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
          font-size: 16px;
        }
  }
`;