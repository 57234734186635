import { FC } from 'react'
import { useHistory } from 'react-router-dom';
import { FrequentlyAskedQuestionsScreenContainer } from './style';

const FrequentlyAskedQuestionsSection: FC = () => {

  const history = useHistory();

  return (
    <FrequentlyAskedQuestionsScreenContainer>
      <div className="background"></div>
      <div className="innerContainer">
        <div className="content">
          <h1 className="title">Frequently Asked Questions</h1>
          <h2 className="subtitle">Questions about your trip:</h2>
          <div className="questions">
            <div className="category">
              <h3 className="categoryTitle">Room Amenities</h3>
              <p className='question'>
                <div className='highLight'>What amenities are included in the rooms?</div>
                <div>
                  All rooms at Rook Hotel are equipped with a 50" or larger flat-screen TV, with a screen cast device. Relax on our ultra comfy mattresses
                  <span
                    className='link'
                    onClick={() => {
                      history.push('/');
                    }}
                  > 
                    Read More...
                  </span>
                </div>
              </p>
              <p className='question'>
                <div className='highLight'>Does Rook Hotel offer free Wi-Fi?</div>
                <div>Yes, complimentary high-speed Wi-Fi is available throughout the hotel, including guest rooms, gym, and the lounge.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Do the rooms have a fridge?</div>
                <div>No, the rooms do not have a fridge.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Do the rooms include an ironing board and iron?</div>
                <div>These are available in the self-serve laundry room.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Are there coffee makers in the rooms?</div>
                <div>No, but there is self serve coffee and tea in the hotel lounge on the 8th floor.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Are hair dryers available in the bathrooms?</div>
                <div>Yes, each room is equipped with a hairdryer.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Can I request an extra bed or crib for my room?</div>
                <div>No, extra beds or cribs are not available.</div>
              </p>
            </div>
            <div className="category">
              <h3 className="categoryTitle">Hotel Amenities</h3>
              <p className='question'>
                <div className='highLight'>Is there free parking?</div>
                <div>No, parking is $10 per night per car.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Is there a sauna or steam room?</div>
                <div>Yes, a complimentary sauna and steam room are available for guests.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Do you offer laundry services?</div>
                <div>We provide a self-serve coin-operated laundry room.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Does Rook Hotel have a fitness center?</div>
                <div>Yes, our fitness center is open 24/7 and is equipped with state-of-the-art cardio machines, free weights, and other fitness equipment.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Does Rook Hotel have an on-site restaurant?</div>
                <div>Rook hotel will be launching Rook Cafe in Spring 2025.</div>
              </p>
              <p className='question'>
                <div className='highLight'>What measures are in place for health and safety at Rook Hotel?</div>
                <div>
                  Rook hotel will be launching Rook Hotel is committed to providing a safe environment for our guests and staff. We follow rigorous
                  <span
                    className='link'
                    onClick={() => {
                      history.push('/');
                    }}
                  > 
                    Read More...
                  </span>
                </div>
              </p>
            </div>
            <div className="category">
              <h3 className="categoryTitle">Check In / Check Out</h3>
              <p className='question'>
                <div className='highLight'>How does check-in work?</div>
                <div>The check-in process is completed via a kiosk. You will need a credit card and photo identification to complete the check-in.</div>
              </p>
              <p className='question'>
                <div className='highLight'>What are your check-in and check-out times?</div>
                <div>Check-in: 3:00 PM, Check-out: 11:00 AM.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Is early check-in available?</div>
                <div>Yes, early check-in is available for $25, starting as early as 4:00 AM, subject to availability.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Is late check-out available?</div>
                <div>Yes, late check-out is available for $25, until 6:00 PM, subject to availability.</div>
              </p>
              <p className='question'>
                <div className='highLight'>How much is the deposit for incidentals when booking in?</div>
                <div>The incidental deposit is $200 per room.</div>
              </p>
            </div>
          </div>
          <h2 className="subtitle">Questions about our Hotel:</h2>
          <div className="questions">
            <div className="category">
              <h3 className="categoryTitle">Hotel Policies</h3>
              <p className='question'>
                <div className='highLight'>Do you have smoking rooms?</div>
                <div>No, smoking rooms are not available.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Does this hotel offer extended stays for a week or two?</div>
                <div>Yes, extended stays are available.</div>
              </p>
              <p className='question'>
                <div className='highLight'>What are the monthly and yearly rates for long-term stays?</div>
                <div>Please speak to the hotel manager for pricing information.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Are small dogs allowed?</div>
                <div>No, small dogs are not allowed.</div>
              </p>
              <p className='question'>
                <div className='highLight'>What are the room rates for a week and one day?</div>
                <div>Please refer to our website for current rates.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Is there a shuttle service from Pearson Airport to the hotel?</div>
                <div>No, we recommend using Uber for transportation.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Are pets allowed?</div>
                <div>No, pets are not allowed.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Do I need a credit card to book a room or can I pay with cash?</div>
                <div>A credit card is required to book a room.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Are there any limitations on the number of guests per room?</div>
                <div>No, but the rooms are quite compact, so a reasonable limit is 2 guests per room.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Does this hotel offer extended stays for a week or two?</div>
                <div>Yes, please contact the manager for extended stay pricing.</div>
              </p>
            </div>
            <div className="category">
              <h3 className="categoryTitle">Nearby Amenities & Travel</h3>
              <p className='question'>
                <div className='highLight'>Is there a pharmacy near the hotel?</div>
                <div>Yes, there is a pharmacy directly beside the hotel.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Is the hotel near a bus station going to downtown Toronto?</div>
                <div>Yes, you can take the 21C bus from Square One to Union Station.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Is there any shopping around this area?</div>
                <div>Yes, Square One Mall is a short walk from the hotel.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Will the hotel arrange for airport pickup for guests?</div>
                <div>No, we do not offer airport pickup services.</div>
              </p>
              <p className='question'>
                <div className='highLight'>Are there any nearby parks or outdoor activities?</div>
                <div>
                  You can visit Mississauga Valley, it is a 10 minute walk from the hotel. There are tennis courts, a children’s splash pad, a playground, 
                  <span
                    className='link'
                    onClick={() => {
                      history.push('/');
                    }}
                  > 
                    Read More...
                  </span>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </FrequentlyAskedQuestionsScreenContainer>
  )
}

export default FrequentlyAskedQuestionsSection;