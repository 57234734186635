import { atom } from 'recoil';
import { RoomType } from 'types/commonTypes';
import { getPersistBoolean, getPersistNumber, getPersisDateRange } from 'utils/util';

export const dateRangeState = atom({
  key: 'dateRange',
  default: getPersisDateRange(),
});

export const guestQuantityState = atom({
  key: 'guestQuantity',
  default: getPersistNumber('guestQuantity'),
});

export const roomQuantityState = atom({
  key: 'roomQuantity',
  default: getPersistNumber('roomQuantity'),
});

export const isWheelchairAccessibleState = atom({
  key: 'isWheelchairAccessible',
  default: getPersistBoolean('isWheelchairAccessible'),
});

export const searchResultState = atom<RoomType[]>({
  key: 'searchResultState',
  default: [],
});